import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';
import { FireAuthState, initialAuthState } from 'akita-ng-fire';
import { UserData } from './auth.model';

export interface AuthState extends FireAuthState<UserData> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(initialAuthState);
  }
}
