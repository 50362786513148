import { Directive, HostListener, Input, ChangeDetectorRef } from '@angular/core';

type Draggable = Record<string, any> & { draggable: boolean } | any;

@Directive({
  selector: '[dragHandle]'
})
export class DragHandleDirective {
  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    // this.cdr.detach();
  }

  @Input('dragHandle') node: Draggable;

  @HostListener('mousedown') onMouseDown() {
    if (this.node) this.node.draggable = true;
  }

  @HostListener('mouseup') onMouseLeave() {
    if (this.node) this.node.draggable = false;
  }
}
