import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';

export enum FavoriteType {
  Projects = 'projects'
}
export type FavoritesState = Record<FavoriteType, Record<string, boolean>>;

export function createInitialState(): FavoritesState {
  return Object.values(FavoriteType).reduce((acc, cur) => {
    return { ...acc, [cur]: {} };
  }, {} as FavoritesState);
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'favorites', resettable: true })
export class FavoritesStore extends Store<FavoritesState> {

  constructor() {
    super(createInitialState());
  }

}

