import { Injectable } from '@angular/core';
import { CollectionConfig, CollectionService, WriteOptions } from 'akita-ng-fire';
import { switchMap } from 'rxjs/operators';
import { waitForChangeAndReset } from '@common/helpers';
import { AuthQuery } from '@app/auth/auth.query';
import { OrganizationService } from '@app/_state/organization/organization.service';
import { OrganizationStore } from '@app/_state/organization/organization.store';
import { UserOrganizationsStore, UserOrganizationsState } from './organizations.store';
import { UserOrganization } from './organization.model';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'user-organizations/:uid/organizations' })
export class UserOrganizationsService extends CollectionService<UserOrganizationsState> {
  constructor(
    store: UserOrganizationsStore,
    protected authQuery: AuthQuery,
    protected organizationStore: OrganizationStore,
    protected organizationService: OrganizationService
  ) {
    super(store);
  }

  sync() {
    return this.authQuery.select('uid').pipe(
      waitForChangeAndReset(this.store),
      switchMap(uid => this.syncCollection({ params: { uid } }))
    );
  }

  get currentPath() {
    const { uid } = this.authQuery.getValue();
    if (!uid) throw '[organizations.service] Path is not valid';
    return this.getPath({ params: { uid } });
  }

  select(id: string) {
    this.store.setActive(id);
    this.organizationStore.setActive(id);
  }

  protected onCreate(entity: UserOrganization, options: WriteOptions) {
    return this.organizationService.create(entity.name, entity.id, options);
  }

  protected onDelete(id: string, options: WriteOptions) {
    return this.organizationService.remove(id, options);
  }
}
