import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { TreeService, TreeQuery, TreeStore } from '../_state/tree';

import { TreeComponent } from '@app/tree/tree.component';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'project-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnInit {

  treeTypes = [
    {
      icon: 'account_tree',
      title: 'Outline'
    },
    {
      icon: 'local_parking',
      title: 'Parked'
    },
    {
      icon: 'delete',
      title: 'Trash'
    }
  ];

  activeTree = this.treeTypes[0];
  activeIndex: number;

  constructor(
    private routerQuery: RouterQuery,
    private router: Router,
    private treeService: TreeService,
    private treeQuery: TreeQuery,
    private treeStore: TreeStore
  ) { }

  @ViewChild(TreeComponent) tree: TreeComponent<any>;

  // selectedNode: TreeEntry;

  ngOnInit() {
    this.routerQuery.selectParams<string>('formId')
      .pipe(untilDestroyed(this))
      .subscribe(formId => {
        console.log('setting active id', formId);
        this.treeStore.setActive(formId);
      });

    this.treeQuery.selectActive()
      .pipe(
        untilDestroyed(this),
        tap(node => {
          // this.activeIndex = node.index;
        })
      )
      .subscribe();
  }

  onTreeChange({ value }) {
    this.activeTree = value;
  }

  addGroup() {
    this.treeService.createTreeEntry('group', '', 'root');
  }

}
