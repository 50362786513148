import Big, * as BigLib from 'big.js';

declare module 'big.js' {
  interface Big {
    num(): number;
  }
}

Big.prototype.num = function () { return Number(this as Big); };

export * from 'big.js';
export { Big };
export default Big;
