import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TreeEntry } from '@app/_state/organization';
import { TreeQuery, TreeStore, TreeService } from '@app/_state/tree';
import { FavoritesService, FavoriteType } from '@app/_state/favorites';

@UntilDestroy()
@Component({
  selector: 'organization-tree',
  templateUrl: './organization-tree.component.html',
  styleUrls: ['./organization-tree.component.scss']
})
export class OrganizationTreeComponent implements OnInit {

  constructor(
    private treeService: TreeService,
    private treeStore: TreeStore,
    private treeQuery: TreeQuery,
    private favoritesService: FavoritesService
  ) { }

  filterFavorites$ = this.treeQuery.ui.select('filterFavorites');
  orgTree$ = this.treeQuery.selectTree();

  ngOnInit() {
    this.treeService.sync()
      .pipe(untilDestroyed(this))
      .subscribe();

    this.favoritesService.sync(FavoriteType.Projects)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onSelect(node: TreeEntry) {
    this.treeStore.setActive(node['data'].id);
  }

  setExpanded($event: { id: string; value: boolean; }) {
    const { id, value } = $event;
    this.treeService.setNodeExpanded(id, value);
  }

  setFavorite($event: boolean, id: string) {
    this.favoritesService.setFavorite(FavoriteType.Projects, id, $event);
  }

  treeChanges($event) {
    console.log('treeChangeEvent', $event);
    this.treeService.applyChanges($event);
  }

}

