import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { OrganizationService, OrganizationQuery, TreeEntry } from '@app/_state/organization';
import { TreeQuery, TreeService } from '@app/_state/tree';
import { UserOrganizationsService } from '@app/organizations/_state/organizations.service';
import { CreateDialogService } from '../create-dialog/create-dialog.service';
import { TreeComponent } from '@app/tree/tree.component';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'organization-dashboard',
  templateUrl: './organization-dashboard.component.html',
  styleUrls: ['./organization-dashboard.component.scss']
})
export class OrganizationDashboardComponent implements OnInit {

  constructor(
    private orgQuery: OrganizationQuery,
    private orgService: OrganizationService,
    private routerQuery: RouterQuery,
    private userOrgService: UserOrganizationsService,
    private dialog: CreateDialogService,
    private treeService: TreeService,
    private treeQuery: TreeQuery,
    private router: Router
  ) { }

  @ViewChild(TreeComponent) tree: TreeComponent<any>;

  org$ = this.orgQuery.selectActive();
  orgList$ = this.orgQuery.selectAll();
  filterFavorites$ = this.treeQuery.ui.select('filterFavorites');

  clientSelected = false;
  matterSelected = false;
  projectSelected = false;

  selectedNode: TreeEntry;

  ngOnInit() {
    this.routerQuery.selectParams<string>('id')
      .pipe(untilDestroyed(this))
      .subscribe(id => {
        this.userOrgService.select(id);
      });

    this.orgService.sync()
      .pipe(untilDestroyed(this))
      .subscribe();

    this.treeService.sync()
      .pipe(untilDestroyed(this))
      .subscribe();

    this.treeQuery.selectActive()
      .pipe(
        untilDestroyed(this),
        tap(node => {
          console.log(node);
          this.selectedNode = node as TreeEntry;
          this.clientSelected = node && node.type === 'client';
          this.matterSelected = node && node.type === 'matter';
          this.projectSelected = node && node.type === 'project';
        })
      )
      .subscribe();
  }

  onOrganizationChange({ value }: { value: string }) {
    this.router.navigate(['/organizations', value]);
  }

  setFavoriteFilter($event: boolean) {
    this.treeService.setFilterFavorites($event);
  }

  openDialog(type: TreeEntry['type']) {
    this.dialog.open({
      title: `Create new ${type}`,
      description: `What's your ${type} name?`
    }).subscribe(name => {
      if (!name) return;
      const parentId = this.treeQuery.getActiveId();
      this.treeService.createTreeEntry(type, name, parentId);
      if (parentId) {
        this.treeService.setNodeExpanded(parentId, true);
      }
    });
  }

}
