import { Injectable } from '@angular/core';
import { QueryEntity, EntityUIQuery, combineQueries, HashMap } from '@datorama/akita';
import { ContentStore, ContentState, ContentUIState } from './content.store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ContentEntryType } from './content.model';

@Injectable({ providedIn: 'root' })
export class ContentQuery extends QueryEntity<ContentState> {

  ui: EntityUIQuery<ContentUIState>;

  constructor(
    protected store: ContentStore
  ) {
    super(store);
    this.createUIQuery();
  }

  selectContentForm() {
    return this.selectAll().pipe(
      map(entities => {
        const map: Partial<Record<ContentEntryType, string>> = {};
        entities.forEach(entity => {
          map[entity.type] = entity.metadata.text;
        });
        return map;
      })
    )
  }

}
