import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { switchMap } from 'rxjs/operators';
import { ProjectStore, ProjectState } from './project.store';
import { UserOrganizationsService } from '../../organizations/_state/organizations.service';

@Injectable({ providedIn: 'root' })
export class ProjectQuery extends QueryEntity<ProjectState> {

  constructor(
    protected store: ProjectStore
  ) {
    super(store);
  }

}
