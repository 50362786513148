type RecursivePartial<T> = {
  [P in keyof T]?:
    T[P] extends (infer U)[] ? RecursivePartial<U>[] :
    T[P] extends object ? RecursivePartial<T[P]> :
    T[P];
};

export function createOrganization(params: RecursivePartial<Organization>) {
  return {
    id: params.id,
    metadata: {
      name: params.metadata.name,
      description: '',
      image: ''
    },
    owner: params.owner,
    // tree: [],
    members: [{
      id: params.owner,
      role: 'owner'
    }]
  } as Organization;
}

export function createMembers() {

}

export interface Organization {
  id: string;
  metadata: {
    name: string;
    description: string;
    image: string;
  };
  owner: string;
  tree: TreeEntry[];
  members: Member[];
}

export type TreeEntry = Client | Matter | Project;

export interface Client {
  id: string;
  name: string;
  code: number;
  type: 'client';
}
export interface Matter {
  id: string;
  name: string;
  code: number;
  type: 'matter';
  parent: string;
}
export interface Project {
  id: string;
  name: string;
  type: 'project';
  parent: string;
  favorite: boolean;
}

export interface Member {
  id: string;
  role: OrganizationRole;
}

export type OrganizationRole = 'owner' | 'admin' | 'member';
