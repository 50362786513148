import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthFormComponent } from './auth-form/auth-form.component';
import { OrganizationsListComponent } from './organizations/organizations-list/organizations-list.component';
import { OrganizationDashboardComponent } from './organizations/dashboard/organization-dashboard.component';
import { ProjectDashboardComponent } from './project/dashboard/dashboard.component';
import { AuthGuard } from './auth/auth.guard';
import { NoAuthGuard } from './auth/noAuth.guard';
import { ContentComponent } from './project/content/content.component';
import { WebviewerComponent } from './webviewer/webviewer.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthFormComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'organizations',
        pathMatch: 'full'
      },
      {
        path: 'organizations',
        component: OrganizationsListComponent
      },
      {
        path: 'organizations/:id',
        component: OrganizationDashboardComponent
      },
      {
        path: 'project/:id',
        // component: ProjectDashboardComponent,
        children: [
          { path: '', component: ProjectDashboardComponent },
          { path: 'form/:formId', component: ProjectDashboardComponent }
        ]
      },
      { path: 'viewer', component: WebviewerComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
