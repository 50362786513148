<div *ngIf="user$ | async">
  <button (click)="logout()">Logout</button>
</div>

<mat-card *ngIf="!(user$ | async)">
  <mat-tab-group (selectedTabChange)="tabChange($event)">
    <mat-tab label="Login">
      <form [formGroup]="form" (ngSubmit)="login()">
        <mat-form-field [color]="email.valid ? 'accent' : null">
          <input matInput formControlName="email" type="email" placeholder="Email" autocomplete="off" />

          <mat-error *ngIf="email.invalid && email.dirty">
            You must enter a valid email address
          </mat-error>
        </mat-form-field>

        <mat-form-field [color]="email.valid ? 'accent' : null">
          <input matInput formControlName="password" type="password" placeholder="Password" autocomplete="off" />

          <mat-error *ngIf="password.invalid && password.dirty">
            Password must be at least 6 characters long
          </mat-error>
        </mat-form-field>

        <mat-error class="server-error">{{ errorMessage }}</mat-error>

        <button mat-raised-button color="accent" type="submit" [disabled]="form.invalid || (loading$ | async)">
          Login
        </button>
      </form>
    </mat-tab>

    <mat-tab label="Signup">
      <form [formGroup]="form" (ngSubmit)="signup()">
        <mat-form-field [color]="email.valid ? 'accent' : null">
          <input matInput formControlName="email" type="email" placeholder="Email" autocomplete="off" />

          <mat-error *ngIf="email.invalid && email.dirty">
            You must enter a valid email address
          </mat-error>
        </mat-form-field>

        <mat-form-field [color]="email.valid ? 'accent' : null">
          <input matInput formControlName="password" type="password" placeholder="Password" autocomplete="off" />

          <mat-error *ngIf="password.invalid && password.dirty">
            Password must be at least 6 characters long
          </mat-error>
        </mat-form-field>

        <mat-form-field [color]="passwordDoesMatch ? 'accent' : 'warn'">
          <input matInput formControlName="passwordConfirm" type="password" placeholder="Confirm password"
            autocomplete="off" />

          <mat-error *ngIf="passwordConfirm.dirty && !passwordDoesMatch">
            Password does not match
          </mat-error>
        </mat-form-field>

        <div class="name-group" formGroupName="name">
            <mat-form-field [color]="firstName.valid ? 'accent' : null">
              <input matInput formControlName="first" placeholder="First name" />
            </mat-form-field>
            <mat-form-field>
              <input matInput formControlName="middle" placeholder="MIddle name" />
            </mat-form-field>
            <mat-form-field [color]="lastName.valid ? 'accent' : null">
              <input matInput formControlName="last" placeholder="Last name" />
            </mat-form-field>
        </div>

        <mat-error class="server-error">{{ errorMessage }}</mat-error>

        <button mat-raised-button color="accent" type="submit"
          [disabled]="form.invalid || !passwordDoesMatch || (loading$ | async)">
          Submit
        </button>
      </form>
    </mat-tab>

    <mat-tab label="Recover password">
      <form [formGroup]="form" (ngSubmit)="recover()">
        <mat-form-field [color]="email.valid ? 'accent' : null">
          <input matInput formControlName="email" type="email" placeholder="Email" autocomplete="off" />

          <mat-error *ngIf="email.invalid && email.dirty">
            You must enter a valid email address
          </mat-error>
        </mat-form-field>

        <mat-error class="server-error">{{ errorMessage }}</mat-error>

        <button mat-raised-button color="accent" type="submit" [disabled]="this.email.invalid || (loading$ | async)">
          Send Reset Email
        </button>
      </form>
    </mat-tab>
  </mat-tab-group>
</mat-card>
