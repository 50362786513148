<mat-card class="node-tools">
  <button mat-icon-button matTooltip="Transform (GROUP > FORM > FOLDER)" matTooltipShowDelay="400">
    <mat-icon (click)="changeNodeType()" svgIcon="convert_type"></mat-icon>
  </button>
  <button mat-icon-button matTooltip="Exdent" matTooltipShowDelay="400">
    <mat-icon (click)="exdentNode()">format_indent_decrease</mat-icon>
  </button>
  <button mat-icon-button matTooltip="Indent" matTooltipShowDelay="400">
    <mat-icon (click)="indentNode()">format_indent_increase</mat-icon>
  </button>
  <button mat-icon-button matTooltip="Move To TRASH" matTooltipShowDelay="400">
    <mat-icon (click)="deleteNode()">delete</mat-icon>
  </button>
</mat-card>
