import { Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TreeService, TreeQuery, TreeStore } from '../_state/tree';
import { OverlayConfig, Overlay } from '@angular/cdk/overlay';
import { Popover } from '@app/popover/popover.service';
import { TreeNodeToolsComponent } from '../tree-node-tools/tree-node-tools.component';
import { Hotkeys } from '@app/_common/hotkeys.service';
import { Router, ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'project-tree',
  templateUrl: './project-tree.component.html',
  styleUrls: ['./project-tree.component.scss']
})
export class ProjectTreeComponent implements OnInit {

  constructor(
    private treeService: TreeService,
    private treeStore: TreeStore,
    private treeQuery: TreeQuery,
    private popover: Popover,
    private hotkeys: Hotkeys,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  projectTree$ = this.treeQuery.selectTree();
  activeNodeId: string;

  ngOnInit() {
    this.treeService.sync()
      .pipe(untilDestroyed(this))
      .subscribe();

    this.treeQuery.selectActiveId()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        console.log('activeNodeId', value);
        this.activeNodeId = value;
      });

    // this.hotkeys.addShortcut({ keys: 'arrowdown', description: '' }).pipe(
    //   untilDestroyed(this)
    // ).subscribe(() => {
    //   console.log('arrowdown pressed');
    //   (document.activeElement as HTMLInputElement).blur();
    // });

    // this.hotkeys.addShortcut({ keys: 'arrowup', description: '' }).pipe(
    //   untilDestroyed(this)
    // ).subscribe(() => {
    //   console.log('arrowup pressed');
    //   (document.activeElement as HTMLInputElement).blur();
    //   // this.treeQuery.selectActiveId();
    // });
  }

  onSelect(node) {
    console.log('node select', node);
    // this.treeStore.setActive(node.data.id);
    this.navigateToForm(node.data.id);
  }

  setExpanded($event: { id: string; value: boolean; }) {
    const { id, value } = $event;
    this.treeService.setNodeExpanded(id, value);
  }

  async createNode(node) {
    const parent = node.parent;
    const { type, id } = node.data;
    const nodeId = await this.treeService.createAfter(type, '', id, parent?.data.id);
    // this.treeStore.setActive(nodeId);
    this.navigateToForm(nodeId);
  }

  updateNodeValue(node, $event: string) {
    this.treeService.update(node.data.id, {
      name: $event
    });
  }

  treeChanges($event) {
    console.log('treeChangeEvent', $event);
    this.treeService.applyChanges($event);
  }

  openTools(node, $event: MouseEvent) {
    $event.stopPropagation();

    const ref = this.popover.open<{ node }>({
      content: TreeNodeToolsComponent,
      origin: $event.target as HTMLElement,
      data: { node }
    });

    // ref.afterClosed$.subscribe(res => {
    //   console.log(res);
    // });
  }

  navigateToForm(id: string) {
    this.router.navigate(['form', id], { relativeTo: this.route.parent });
  }

}

