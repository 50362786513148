import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthQuery } from './auth/auth.query';
import { AuthService } from './auth/auth.service';
import { Subscription } from 'rxjs';
import { filter, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'slideshare-app';
  user$ = this.authQuery.select('uid');
  private subscription: Subscription;

  private icons = {
    'convert_type_hover': '<rect y="4.6" width="7.32" height="7.32" fill="#9d9d9d"/><rect x="16.68" y="11.92" width="7.32" height="7.32" fill="#38a4dd"/><path fill="#000000" d="M12.08,16L12,16v2.5a6.75,6.75,0,0,1-6.89-6.57H2.22A9.57,9.57,0,0,0,12,21.24V24l0.07,0,4.21-4Z"/><path d="M12,2.6h0V0l0,0L7.78,4,12,8l0,0V5.35h0A6.75,6.75,0,0,1,19,11.92h2.89A9.58,9.58,0,0,0,12,2.6Z"/>',
    'convert_type': '<rect y="4.6" width="7.32" height="7.32"/><rect x="16.68" y="11.92" width="7.32" height="7.32" /><path d="M12.08,16L12,16v2.5a6.75,6.75,0,0,1-6.89-6.57H2.22A9.57,9.57,0,0,0,12,21.24V24l0.07,0,4.21-4Z"/><path d="M12,2.6h0V0l0,0L7.78,4,12,8l0,0V5.35h0A6.75,6.75,0,0,1,19,11.92h2.89A9.58,9.58,0,0,0,12,2.6Z"/>',
    'fire': '<path d="M11.71,19C9.93,19 8.5,17.59 8.5,15.86C8.5,14.24 9.53,13.1 11.3,12.74C13.07,12.38 14.9,11.53 15.92,10.16C16.31,11.45 16.5,12.81 16.5,14.2C16.5,16.84 14.36,19 11.71,19M13.5,0.67C13.5,0.67 14.24,3.32 14.24,5.47C14.24,7.53 12.89,9.2 10.83,9.2C8.76,9.2 7.2,7.53 7.2,5.47L7.23,5.1C5.21,7.5 4,10.61 4,14A8,8 0 0,0 12,22A8,8 0 0,0 20,14C20,8.6 17.41,3.8 13.5,0.67Z" />',
    'tags_multiple': '<path d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />',
    'facebook': '<path  d="M13.5,21.9H9.4V12h-2V8.6h2v-2c0-2.8,1.2-4.4,4.4-4.4h2.7v3.4h-1.7c-1.3,0-1.4,0.5-1.4,1.4l0,1.7	h3.1L16.3,12h-2.7V21.9z"/>',
    'google': '<path class="st0" d="M21.6,10.2h-9.4V14h5.4c-0.2,1.2-0.9,2.3-2,3c-0.9,0.6-2,1-3.4,1c-2.6,0-4.8-1.8-5.6-4.1h0l0,0	c-0.2-0.6-0.3-1.2-0.3-1.9s0.1-1.3,0.3-1.9C7.4,7.7,9.6,6,12.2,6C13.7,6,15,6.5,16,7.5l2.9-2.9C17.2,3,14.9,2,12.2,2 C8.3,2,4.9,4.2,3.3,7.5h0C2.6,8.9,2.2,10.4,2.2,12s0.4,3.1,1.1,4.5c1.6,3.3,5,5.5,8.9,5.5c2.7,0,5-0.9,6.6-2.4l0,0h0 c1.9-1.7,3-4.3,3-7.3C21.8,11.5,21.7,10.8,21.6,10.2z"/>',
    'add_here_icon': '<path  d="M18.5,1.9h-5.3H6.5C3,1.9,0.2,4.9,0.2,8.5v1.1c0,1.7,0.6,5,4.5,6.4l14.7,6c0-2.7,0-5.5-0.1-8.3l1.2-0.2 c1.9-0.8,3.2-2.8,3.2-5.1V7.5C23.8,4.4,21.4,1.9,18.5,1.9z M16.6,10.7h-3.1v3.1h-2.1v-3.1H8.2V8.6h3.1V5.7c0-0.1,0.1-0.2,0.2-0.2 h1.6c0.1,0,0.2,0.1,0.2,0.2v2.9h3.1V10.7z"/>',
    'add_style': '<g><path class="st0" d="M15.5,12.5H13V10c0-0.3-0.2-0.5-0.5-0.5h-1C11.2,9.5,11,9.7,11,10v2.5H8.5C8.2,12.5,8,12.7,8,13v1 c0,0.3,0.2,0.5,0.5,0.5H11V17c0,0.3,0.2,0.5,0.5,0.5h1c0.3,0,0.5-0.2,0.5-0.5v-2.5h2.5c0.3,0,0.5-0.2,0.5-0.5v-1 C16,12.7,15.8,12.5,15.5,12.5z"/><path class="st0" d="M23.5,22.5h-23C0.2,22.5,0,22.3,0,22V2c0-0.3,0.2-0.5,0.5-0.5h23C23.8,1.5,24,1.7,24,2v20 C24,22.3,23.8,22.5,23.5,22.5z M2.4,20h19l0-13.1h-19L2.4,20z"/></g>',
    'expand_custom': '<path class="st0" d="M16.8,5.8l-4.6,4.6L7.6,5.8L6.2,7.2l6,6l6-6L16.8,5.8z"/><path class="st0" d="M6.2,18.2v-2h12v2H6.2z"/>',
    'collapse_custom': '<path d="M7.6,18.2l4.6-4.6l4.6,4.6l1.4-1.4l-6-6l-6,6L7.6,18.2z"/><path d="M18.2,5.8v2h-12v-2H18.2z"/>',
    'powerpoint': '<path d="M24,3.6C24,3,23.5,2.5,22.9,2.5c-2.9,0-5.8,0-8.7,0c0-0.8,0-1.7,0-2.5h-1.5C8.4,0.7,4.2,1.5,0,2.2 c0,6.5,0,13,0,19.5c4.2,0.7,8.3,1.5,12.5,2.2h1.7c0-0.9,0-1.9,0-2.8c2.7,0,5.5,0,8.2,0c0.5,0,1,0,1.4-0.3c0.3-0.5,0.2-1.1,0.3-1.7 C24,14,24,8.8,24,3.6z M8,12.9c-0.6,0.3-1.3,0.3-2,0.3c0,1.1,0,2.2,0,3.3c-0.5,0-1.1-0.1-1.6-0.1c0-3,0-6,0-8.9 c1.5,0.1,3.3-0.6,4.5,0.5C10.1,9.4,9.7,12.1,8,12.9z M23.2,20.4c-3,0-6,0-9,0c0-0.7,0-1.5,0-2.2c2.2,0,4.4,0,6.5,0 c0-0.4,0-0.7,0-1.1c-2.2,0-4.4,0-6.5,0c0-0.5,0-0.9,0-1.4c2.2,0,4.4,0,6.5,0c0-0.4,0-0.7,0-1.1c-2.2,0-4.4,0-6.5,0 c0-0.5,0-1.1,0-1.6c1.1,0.3,2.3,0.3,3.3-0.3c1-0.6,1.6-1.8,1.7-3c-1.2,0-2.4,0-3.6,0c0-1.2,0-2.4,0-3.6c-0.4,0.1-0.9,0.2-1.3,0.3 c0-0.9,0-1.9,0-2.8c3,0,6,0,9,0C23.2,9,23.2,14.7,23.2,20.4z"/><path  d="M16.1,5.3C18,5.4,19.6,7,19.7,8.9c-1.2,0-2.4,0-3.6,0 C16.1,7.7,16.1,6.5,16.1,5.3z"/><path d="M6,8.9c0.5,0,1.2-0.1,1.6,0.4c0.3,0.5,0.3,1.3,0,1.8 c-0.3,0.6-1,0.5-1.6,0.6C6,10.8,6,9.8,6,8.9z"/>',
    'slide': '<rect x="2.4" y="6.9" class="icon-bg" width="19" height="13.1"/><path class="st1" d="M23.5,22.5h-23C0.2,22.5,0,22.3,0,22V2c0-0.3,0.2-0.5,0.5-0.5h23C23.8,1.5,24,1.7,24,2v20 C24,22.3,23.8,22.5,23.5,22.5z M2.4,20h19V6.9h-19L2.4,20z"/><g><rect x="7.3" y="12.2" class="inner-icon" width="2.2" height="2.2"/><rect x="7.3" y="14.9" class="inner-icon" width="2.2" height="2.2"/><rect x="7.3" y="9.4" class="inner-icon" width="2.2" height="2.2"/><rect x="10.1" y="12.2" class="inner-icon" width="6.6" height="2.2"/><rect x="10.1" y="14.9" class="inner-icon" width="6.6" height="2.2"/><rect x="10.1" y="9.4" class="inner-icon" width="6.6" height="2.2"/></g>',
    'slide_blank': '<rect x="2.4" y="6.9" class="icon-bg" width="19" height="13.1"/><path class="st1" d="M23.5,22.5h-23C0.2,22.5,0,22.3,0,22V2c0-0.3,0.2-0.5,0.5-0.5h23C23.8,1.5,24,1.7,24,2v20 C24,22.3,23.8,22.5,23.5,22.5z M2.4,20h19V6.9h-19L2.4,20z"/>',
    'design': '<path d="M23.5,22.5h-23C0.2,22.5,0,22.3,0,22V2c0-0.3,0.2-0.5,0.5-0.5h23C23.8,1.5,24,1.7,24,2v20 C24,22.3,23.8,22.5,23.5,22.5z M2.4,20h19V6.9h-19L2.4,20z"/>',
    'content': '<g><rect x="1.9" y="9.7" width="4.7" height="4.7"/><rect x="1.9" y="15.6" width="4.7" height="4.7"/><rect x="1.9" y="3.7" width="4.7" height="4.7"/><rect x="7.9" y="9.7" width="14.2" height="4.7"/><rect x="7.9" y="15.6" width="14.2" height="4.7"/><rect x="7.9" y="3.7" width="14.2" height="4.7"/></g>',
    'aspect4x3': '<g><rect x="3" y="5.2" width="18" height="13.5"/><rect x="3.8" y="8.2" class="icon-bg" width="16.5" height="9.8"/><g><rect x="8.5" y="12.3" width="1.6" height="1.6"/><rect x="8.5" y="14.4" width="1.6" height="1.7"/><rect x="8.5" y="10.2" width="1.6" height="1.6"/><rect x="10.6" y="12.3" width="5" height="1.6"/><rect x="10.6" y="14.4" width="5" height="1.7"/><rect x="10.6" y="10.2" width="5" height="1.6"/></g></g>',
    'aspect16x9': '<rect y="5.2" width="24" height="13.5"/><rect x="0.8" y="8.2" class="icon-bg" width="22.5" height="9.8"/><g><rect x="4.6" y="12.5" width="1.6" height="1.6"/><rect x="4.6" y="14.5" width="1.6" height="1.7"/><rect x="4.6" y="10.4" width="1.6" height="1.6"/><rect x="6.6" y="12.5" width="12.8" height="1.6"/><rect x="6.6" y="14.5" width="12.8" height="1.7"/><rect x="6.6" y="10.4" width="12.8" height="1.6"/></g>',
    'match_width': '<polygon points="24,16.9 17,11.9 17,15.9 7,15.9 7,11.9 0,16.9 7,21.9 7,17.9 17,17.9 17,21.9 "/><polygon points="22,2.1 22,8.1 2,8.1 2,2.1 0,2.1 0,8.1 0,10.1 0,10.1 24,10.1 24,10.1 24,8.1 24,2.1 "/>',
    'format_tool': '<path d="M19 5V3h2v2h-2zm2 16h-2v-2h2v2zm-4-2H7v-2H5V7h2V5h10v2h2v10h-2v2zM5 21H3v-2h2v2zM3 3h2v2H3V3zm20 4V1h-6v2H7V1H1v6h2v10H1v6h6v-2h10v2h6v-6h-2V7h2z"></path>',
    'add_text_tool': '<path  d="M7.3,1.2h4.4l6.5,21.7H14l-1.2-4.5H6l-1.3,4.5h-4L7.3,1.2z M7.1,14.6h4.7L9.5,6.1L7.1,14.6z"/><polygon points="23.5,22.4 21.9,22.4 21.9,1.5 23.5,1.5 23.5,0.5 19.4,0.5 19.4,1.5 20.9,1.5 20.9,22.4 19.4,22.4 19.4,23.4	23.5,23.4 "/>',
    'circle': '<circle cx="12" cy="12" r="12"></circle>',
    'rectangle': '<rect y="3" width="22" height="18"></circle>',
    'edit_annotations': '<path d="M4,16.7V20h3.3l9.8-9.8l-3.3-3.3L4,16.7L4,16.7L4,16.7z M19.7,7.6c0.4-0.4,0.4-0.9,0-1.3l-2.1-2.1 c-0.4-0.4-0.9-0.4-1.2,0l-1.6,1.6l3.3,3.3L19.7,7.6L19.7,7.6L19.7,7.6z"/><path d="M22.5,20.6V3.4c0.6-0.2,1-0.8,1-1.4c0-0.8-0.7-1.5-1.5-1.5c-0.7,0-1.2,0.4-1.4,1H3.4c-0.2-0.6-0.8-1-1.4-1 C1.2,0.5,0.5,1.2,0.5,2c0,0.7,0.4,1.2,1,1.4v17.2c-0.6,0.2-1,0.8-1,1.4c0,0.8,0.7,1.5,1.5,1.5c0.7,0,1.2-0.4,1.4-1h17.2 c0.2,0.6,0.8,1,1.4,1c0.8,0,1.5-0.7,1.5-1.5C23.5,21.3,23.1,20.8,22.5,20.6z M2.5,20.6V3.4c0.4-0.2,0.8-0.5,0.9-0.9h17.2 c0.2,0.4,0.5,0.8,0.9,0.9v17.2c-0.4,0.2-0.8,0.5-0.9,0.9H3.4C3.3,21.1,2.9,20.7,2.5,20.6z"/>',
    'edit_artwork': '<circle class="st0" cx="16.8" cy="7.7" r="2"/><polygon class="st0" points="13.6,11.8 20,15.7 20,20 4,20 4,14.7 6.3,13.1 8.5,14.5 6,16.4 "/><path class="st0" d="M22.5,20.6V3.4c0.6-0.2,1-0.8,1-1.4c0-0.8-0.7-1.5-1.5-1.5c-0.7,0-1.2,0.4-1.4,1H3.4c-0.2-0.6-0.8-1-1.4-1 C1.2,0.5,0.5,1.2,0.5,2c0,0.7,0.4,1.2,1,1.4v17.2c-0.6,0.2-1,0.8-1,1.4c0,0.8,0.7,1.5,1.5,1.5c0.7,0,1.2-0.4,1.4-1h17.2 c0.2,0.6,0.8,1,1.4,1c0.8,0,1.5-0.7,1.5-1.5C23.5,21.3,23.1,20.8,22.5,20.6z M2.5,20.6V3.4c0.4-0.2,0.8-0.5,0.9-0.9h17.2 c0.2,0.4,0.5,0.8,0.9,0.9v17.2c-0.4,0.2-0.8,0.5-0.9,0.9H3.4C3.3,21.1,2.9,20.7,2.5,20.6z"/>',
    'edit_layout': '<path class="st0" d="M22.5,20.6V3.4c0.6-0.2,1-0.8,1-1.4c0-0.8-0.7-1.5-1.5-1.5c-0.7,0-1.2,0.4-1.4,1H3.4c-0.2-0.6-0.8-1-1.4-1 C1.2,0.5,0.5,1.2,0.5,2c0,0.7,0.4,1.2,1,1.4v17.2c-0.6,0.2-1,0.8-1,1.4c0,0.8,0.7,1.5,1.5,1.5c0.7,0,1.2-0.4,1.4-1h17.2 c0.2,0.6,0.8,1,1.4,1c0.8,0,1.5-0.7,1.5-1.5C23.5,21.3,23.1,20.8,22.5,20.6z M2.5,20.6V3.4c0.4-0.2,0.8-0.5,0.9-0.9h17.2 c0.2,0.4,0.5,0.8,0.9,0.9v17.2c-0.4,0.2-0.8,0.5-0.9,0.9H3.4C3.3,21.1,2.9,20.7,2.5,20.6z"/><polygon class="st0" points="9.4,4 20,4 20,11.1 9.4,11.1 "/><polygon class="st0" points="4,17 9.1,17 9.1,20 4,20 "/><polygon class="st0" points="8.4,6.7 8.4,9.4 5.7,16.1 4.6,16.1 "/><polygon class="st0" points="17.1,11.7 18.8,11.7 9.6,19.1 9.6,17.7 "/>',
    'move_layout': '<polygon points="10.2,19.2 15.1,14.4 13.4,12.8 7.7,18.4 7.7,19.2 "/><path d="M20.7,13.6c-0.5,0-0.9,0.1-1.3,0.3l-2-1.9l2-1.9c0.4,0.2,0.9,0.3,1.3,0.3c1.8,0,3.3-1.4,3.3-3.2S22.5,4,20.7,4c-1.8,0-3.3,1.4-3.3,3.2c0,0.5,0.1,0.9,0.3,1.3l-2,1.9l-5.7-5.6H7.7v0.8l10.1,9.9c-0.2,0.4-0.3,0.9-0.3,1.3c0,1.8,1.5,3.2,3.3,3.2c1.8,0,3.3-1.4,3.3-3.2S22.5,13.6,20.7,13.6z M20.7,5.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C19.1,6.3,19.9,5.6,20.7,5.6z M15.9,12.4c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4S16.1,12.4,15.9,12.4zM20.7,18.4c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6C22.4,17.7,21.6,18.4,20.7,18.4z"/><polygon points="8.9,11 11.5,11 12.5,12.1 11.6,13 8.9,13 8.9,11 "/><polygon points="4.4,11 8.1,11 8.1,13 4.4,13 "/><polygon points="0,11 3.5,11 3.5,13 0,13 "/>',
    'callout': '<path class="st0" d="M4.2,0.2l-4,13v10.5h7.5l16-13V0.2H4.2z M7.7,21.8v-8.6H1.8l2.4-7.9v5.4h17.1L7.7,21.8z"/><rect x="5.8" y="1.8" class="icon-bg" width="16.5" height="7.5"/>',
    'tree': '<path class="st0" d="M14.4,14.5h6.7c0.4,0,0.9-0.4,0.9-0.9v-3.4c0-0.4-0.4-0.9-0.9-0.9h-6.7c-0.4,0-0.9,0.4-0.9,0.9c0,0,0,0.7,0,1.3H6.6V6.2h3c0.4,0,0.9-0.4,0.9-0.9V1.9c0-0.4-0.4-0.9-0.9-0.9H2.9C2.4,1,2,1.4,2,1.9v3.4c0,0.4,0.4,0.9,0.9,0.9h2.7v14.2c0,0.3,0.2,0.5,0.5,0.5h7.3c0,0.6,0,1.3,0,1.3c0,0.4,0.4,0.9,0.9,0.9h6.7c0.4,0,0.9-0.4,0.9-0.9v-3.4c0-0.4-0.4-0.9-0.9-0.9h-6.7c-0.4,0-0.9,0.4-0.9,0.9c0,0,0,0.6,0,1.1H6.6v-7.3h6.8c0,0.5,0,1.1,0,1.1C13.5,14,13.9,14.5,14.4,14.5z"/>',
    // circle_empty path is doubled for better morphing into other circles
    'circle_empty': '<path d="M12,23.5C5.7,23.5,0.5,18.3,0.5,12C0.5,5.7,5.7,0.5,12,0.5c6.3,0,11.5,5.2,11.5,11.5C23.5,18.3,18.3,23.5,12,23.5z M12,2.5c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5S17.2,2.5,12,2.5z"/><path d="M12,23.5C5.7,23.5,0.5,18.3,0.5,12C0.5,5.7,5.7,0.5,12,0.5c6.3,0,11.5,5.2,11.5,11.5C23.5,18.3,18.3,23.5,12,23.5z M12,2.5c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5S17.2,2.5,12,2.5z"/>',
    'circle_check': '<path d="M9.9,17.8 4.6,12.5 6,11 9.9,15 18,6.9 19.4,8.3 z"/><path d="M12,23.5C5.7,23.5,0.5,18.3,0.5,12C0.5,5.7,5.7,0.5,12,0.5c6.3,0,11.5,5.2,11.5,11.5C23.5,18.3,18.3,23.5,12,23.5z M12,2.5c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5S17.2,2.5,12,2.5z"/>',
    'circle_play': '<path d="M9.6,17.3l7.1-5.3L9.6,6.7V17.3z"/><path d="M12,23.5C5.7,23.5,0.5,18.3,0.5,12C0.5,5.7,5.7,0.5,12,0.5c6.3,0,11.5,5.2,11.5,11.5C23.5,18.3,18.3,23.5,12,23.5z M12,2.5c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5S17.2,2.5,12,2.5z"/>',
    'triangle_empty': '<path d="M23.8,21H0.2L12,2.1L23.8,21z M3.8,19h16.4L12,5.9L3.8,19z"/>',
    'triangle_fill': '<path d="M23.8,21H0.2L12,2.1L23.8,21z"/>',
    'tree_node': '<path class="bg" d="M19.2,22H4.8C3.2,22,2,20.8,2,19.2V4.8C2,3.2,3.2,2,4.8,2h14.4C20.8,2,22,3.2,22,4.8v14.4C22,20.8,20.8,22,19.2,22z"/><path class="border" d="M19.2,22.5H4.8c-1.8,0-3.3-1.5-3.3-3.3V4.8C1.5,3,3,1.5,4.8,1.5h14.4c1.8,0,3.3,1.5,3.3,3.3v14.4C22.5,21,21,22.5,19.2,22.5z M4.8,2.5c-1.3,0-2.3,1-2.3,2.3v14.4c0,1.3,1,2.3,2.3,2.3h14.4c1.3,0,2.3-1,2.3-2.3V4.8c0-1.3-1-2.3-2.3-2.3H4.8z"/><circle class="badge badge-bg" cx="19.5" cy="4.5" r="4"/><path class="badge badge-border" d="M19.5,9C17,9,15,7,15,4.5S17,0,19.5,0S24,2,24,4.5S22,9,19.5,9z M19.5,1C17.6,1,16,2.6,16,4.5S17.6,8,19.5,8S23,6.4,23,4.5S21.4,1,19.5,1z"/><path class="spinner" d="M12,19.5v-2c3,0,5.5-2.5,5.5-5.5c0-3-2.5-5.5-5.5-5.5C9,6.5,6.5,9,6.5,12h-2c0-4.1,3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5S16.1,19.5,12,19.5z"/>',
    'window_maximize': '<path d="M4,4H20V20H4V4M6,8V18H18V8H6Z" />',
    'window_restore': '<path d="M4,8H8V4H20V16H16V20H4V8M16,8V14H18V6H10V8H16M6,12V18H14V12H6Z" />',
    'find_artwork': '<path fill="none" d="M0 0h24v24H0V0z"/><path d="M18 13v7H4V6h5.02c.05-.71.22-1.38.48-2H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-5l-2-2zm-1.5 5h-11l2.75-3.53 1.96 2.36 2.75-3.54zm2.8-9.11c.44-.7.7-1.51.7-2.39C20 4.01 17.99 2 15.5 2S11 4.01 11 6.5s2.01 4.5 4.49 4.5c.88 0 1.7-.26 2.39-.7L21 13.42 22.42 12 19.3 8.89zM15.5 9C14.12 9 13 7.88 13 6.5S14.12 4 15.5 4 18 5.12 18 6.5 16.88 9 15.5 9z"/><path fill="none" d="M0 0h24v24H0z"/>',
    'add_artwork': '<path d="M19 7v2.99s-1.99.01-2 0V7h-3s.01-1.99 0-2h3V2h2v3h3v2h-3zm-3 4V8h-3V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-8h-3zM5 19l3-4 2 3 3-4 4 5H5z"/><path d="M0 0h24v24H0z" fill="none"/>'
  };

  constructor(
    private authQuery: AuthQuery,
    private auth: AuthService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    Object.keys(this.icons).forEach(name => {
      const value = sanitizer.bypassSecurityTrustHtml(`<svg>${this.icons[name]}</svg>`);
      iconRegistry.addSvgIconLiteral(name, value);
    });
  }

  ngOnInit() {
    this.subscription = this.auth.sync().pipe(
      switchMap(() => this.authQuery.select('uid')),
      filter(uid => !!uid),
      distinctUntilChanged((prev, curr) => {
        if (prev !== curr) location.reload();
        return prev === curr;
      })
    ).subscribe();
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  logout() {
    this.auth.signOut();
  }
}
