import { Injectable } from '@angular/core';
import { QueryEntity, Query } from '@datorama/akita';
import { FavoritesStore, FavoritesState } from './favorites.store';

@Injectable({ providedIn: 'root' })
export class FavoritesQuery extends Query<FavoritesState> {

  constructor(protected store: FavoritesStore) {
    super(store);
  }

}
