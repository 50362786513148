import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { OrganizationStore, OrganizationState } from './organization.store';
import { createOrganization, TreeEntry, Organization } from './organization.model';
import { CollectionConfig, CollectionService, WriteOptions, syncWithRouter } from 'akita-ng-fire';
import { UserOrganizationsQuery } from 'src/app/organizations/_state/organizations.query';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AuthQuery } from '../../auth/auth.query';
import { TreeService } from '../tree/tree.service';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'organizations' })
export class OrganizationService extends CollectionService<OrganizationState> {

  constructor(
    store: OrganizationStore,
    protected authQuery: AuthQuery,
    protected routerQuery: RouterQuery,
    protected treeService: TreeService
  ) {
    super(store);
  }

  sync = syncWithRouter.bind(this, this.routerQuery);

  create(name: string, id: string, options?: WriteOptions) {
    const { uid } = this.authQuery.getValue();

    const org = createOrganization({
      id,
      metadata: { name },
      owner: uid
    });

    return this.add(org, options);
  }

  protected onDelete(id: string, options: WriteOptions) {
    const removeOptions = { ...options, params: { id } };
    return this.treeService.removeAll(removeOptions);
  }

  protected onCreate(entity: Organization, options: WriteOptions) {
    const createOptions = { ...options, params: { id: entity.id } };
    return this.treeService.createRootTreeEntry(createOptions);
  }

  // sync() {
  //   return this.userOrganizationsQuery.selectActiveId().pipe(
  //     distinctUntilChanged((prev, curr) => {
  //       if (prev !== curr) this.store.reset();
  //       return prev === curr;
  //     }),
  //     switchMap(id => this.syncDoc({ id }))
  //   );
  // }
}
