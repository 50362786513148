import { Injectable } from '@angular/core';
import { EntityState, ActiveState, EntityStore, StoreConfig, EntityUIStore } from '@datorama/akita';
import { TreeEntry } from './tree.model';

export type TreeNodeUIState = { expanded: boolean; };

export interface TreeUIState extends EntityUIStore<TreeNodeUIState> {}

export interface TreeState extends EntityState<TreeEntry, string>, ActiveState<string> {
  ui: TreeUIState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'projectTree', resettable: true })
export class TreeStore extends EntityStore<TreeState> {

  ui: EntityUIStore<TreeUIState>;

  constructor() {
    super();
    this.createUIStore().setInitialEntityState({ expanded: false });
  }

}

