import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationsListComponent } from './organizations-list/organizations-list.component';
import { CreateDialogComponent } from './create-dialog/create-dialog.component';
import { OrganizationTreeComponent } from './organization-tree/organization-tree.component';
import { OrganizationDashboardComponent } from './dashboard/organization-dashboard.component';
import { ProjectDashboardComponent } from './project-dashboard/project-dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { FavIcon } from './favIcon.component';


@NgModule({
  declarations: [
    OrganizationDashboardComponent,
    ProjectDashboardComponent,
    OrganizationsListComponent,
    CreateDialogComponent,
    OrganizationTreeComponent,
    FavIcon
  ],
  imports: [
    SharedModule,
    CommonModule
  ]
})
export class OrganizationsModule { }
