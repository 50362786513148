export type SlideEntry = {
  id: string;
  formId: string;
  aspectRatio: string;
  selected: boolean;
  upload: boolean;
};

export function createDefaultSlide(id: string, formId: string): SlideEntry {
  return {
    id,
    formId,
    aspectRatio: '16x9',
    selected: true,
    upload: false
  };
}
