import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { CdkTreeModule } from '@angular/cdk/tree';

import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { TreeModule } from '../tree/primetree/tree';
import { TreeComponent } from '../tree/tree.component';
import { DragHandleDirective } from '../tree/dragHandle.directive';

import { PopoverComponent } from '@app/popover/popover.component';
import { RichInputComponent } from '@app/rich-input/rich-input.component';
import { SpeechDialogComponent } from '@app/speech-dialog/speech-dialog.component';
import { DropzoneComponent } from '@app/_common/components/dropzone.component';

const components = [
  TreeComponent,
  DragHandleDirective,
  PopoverComponent,
  SpeechDialogComponent,
  RichInputComponent,
  DropzoneComponent
];

const modules = [
  MatTreeModule,
  MatIconModule,
  MatButtonModule,
  MatTabsModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatListModule,
  MatToolbarModule,
  CdkTreeModule,
  MatSelectModule,
  MatTooltipModule,
  MatSnackBarModule,

  TreeModule,

  OverlayModule,
  PortalModule,
  MatMenuModule,
  DragDropModule,
  FormsModule,
  RouterModule,
  ReactiveFormsModule
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    ...modules
  ],
  exports: [
    ...components,
    ...modules
  ]
})
export class SharedModule { }
