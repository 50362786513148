import { Injectable } from '@angular/core';
import { WriteOptions, SyncOptions, pathWithParams, PathParams, DocOptions, getIdAndPath } from 'akita-ng-fire';
import { switchMap, map } from 'rxjs/operators';
import firebase from 'firebase/app';
import { waitForChangeAndReset } from '@common/helpers';
import { AuthQuery } from '@app/auth/auth.query';
import { UserOrganizationsQuery } from '@app/organizations/_state/organizations.query';
import { FavoritesState, FavoritesStore, FavoriteType } from './favorites.store';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({ providedIn: 'root' })
export class FavoritesService {

  private path = 'user-organizations/:uid/organizations/:orgId/favorites';

  constructor(
    protected store: FavoritesStore,
    protected db: AngularFirestore,
    protected userOrganizationsQuery: UserOrganizationsQuery,
    protected authQuery: AuthQuery
  ) {
  }

  sync(type: FavoriteType) {
    return this.userOrganizationsQuery.selectActiveId().pipe(
      waitForChangeAndReset(this.store),
      switchMap(() => this.syncDoc({ id: type }))
    );
  }

  setFavorite(type: FavoriteType, projectId: string, value: boolean) {
    if (value) {
      return this.upsert(type, { [projectId]: true });
    }
    return this.update(type, { [projectId]: firebase.firestore.FieldValue.delete() });
  }

  async upsert(key: string, value: Partial<FavoritesState>, options: WriteOptions = {}) {
    const { write } = options;
    const { ref } = this.db.doc<FavoritesState>(`${this.currentPath}/${key}`);

    if (write) {
      return (write as firebase.firestore.WriteBatch).set(ref, value, { merge: true });
    }
    return ref.set(value, { merge: true });
  }

  async update(key: string, value: Partial<FavoritesState> | Record<string, firebase.firestore.FieldValue>, options: WriteOptions = {}) {
    const { write } = options;
    const { ref } = this.db.doc<FavoritesState>(`${this.currentPath}/${key}`);

    if (write) {
      return (write as firebase.firestore.WriteBatch).update(ref, value);
    }
    return ref.update(value);
  }

  private getPath(options: PathParams) {
    return (options && options.params) ? pathWithParams(this.path, options.params) : this.currentPath;
  }

  private get currentPath() {
    const { uid } = this.authQuery.getValue();
    const orgId = this.userOrganizationsQuery.getActiveId();
    if (!uid || !orgId) throw '[favorties.service] Path is not valid';
    return this.getPath({ params: { uid, orgId } });
  }

  private syncDoc(docOptions: DocOptions) {
    const { id, path } = getIdAndPath(docOptions, this.currentPath)

    this.store.setLoading(true);
    return this.db.doc<FavoritesState>(path).valueChanges().pipe(
      map(value => {
        this.store.update({ [id]: value || {} });
        this.store.setLoading(false);
        return value;
      })
    );
  }
}

