<ng-container *ngIf="project$ | async as project">
  <button mat-stroked-button class="join" *ngIf="!project.members[(uid$ | async)]" (click)="joinProject(project.id)">
    <mat-icon>account_tree</mat-icon>
    Join Project
  </button>
  <button mat-stroked-button class="open" *ngIf="project.members[(uid$ | async)]" [routerLink]="['/project', project.id]">
    <mat-icon>account_tree</mat-icon>
    Open Project
  </button>
  <h1>{{ project.metadata.name }}</h1>
</ng-container>
