import { Injectable } from '@angular/core';
import { UserOrganization } from './organization.model';
import { EntityState, ActiveState, EntityStore, StoreConfig } from '@datorama/akita';

export interface UserOrganizationsState extends EntityState<UserOrganization, string>, ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-organizations', resettable: true })
export class UserOrganizationsStore extends EntityStore<UserOrganizationsState> {

  constructor() {
    super();
  }

}

