<li
  *ngIf="tree.droppableNodes"
  class="ui-treenode-droppoint"
  [ngClass]="{'ui-treenode-droppoint-active ui-state-highlight': draghoverPrev}"
  (drop)="onDropPoint($event,-1)"
  (dragover)="onDropPointDragOver($event)"
  (dragenter)="onDropPointDragEnter($event,-1)"
  (dragleave)="onDropPointDragLeave($event)">
</li>
<li class="ui-treenode" [ngClass]="{'ui-treenode-leaf': isLeaf(), 'is-dragged': node.draggable}">
  <div class="ui-treenode-content"
    (click)="onNodeClick($event)"
    (contextmenu)="onNodeRightClick($event)"
    (keydown)="onKeyDown($event)"
    (touchend)="onNodeTouchEnd()"
    (drop)="onDropNode($event)"
    (dragover)="onDropNodeDragOver($event)"
    (dragenter)="onDropNodeDragEnter($event)"
    (dragleave)="onDropNodeDragLeave($event)"
    [draggable]="tree.draggableNodes"
    (dragstart)="onDragStart($event)"
    (dragend)="onDragStop($event)"
    tabIndex="0"
    [ngClass]="{
      'ui-treenode-selectable': tree.selectionMode && node.selectable !== false,
      'ui-treenode-dragover': draghoverNode,
      'ui-treenode-content-selected': isSelected()
    }"
  >
    <span class="ui-tree-toggler pi pi-fw ui-unselectable-text"
      [ngClass]="{
        'pi-caret-right': !node.expanded,
        'pi-caret-down': node.expanded
      }"
      (click)="toggle($event)"
    ></span>
    <span class="ui-treenode-label ui-corner-all" [ngClass]="{'ui-state-highlight':isSelected()}">
      <span *ngIf="!tree.getTemplateForNode(node)">{{node.label}}</span>
      <span *ngIf="tree.getTemplateForNode(node)" class="node-template">
        <ng-container *ngTemplateOutlet="tree.getTemplateForNode(node); context: {$implicit: node}"></ng-container>
      </span>
    </span>
  </div>
  <ul
    class="ui-treenode-children"
    style="display: none;"
    *ngIf="node.children && node.expanded"
    [style.display]="node.expanded ? 'block' : 'none'"
  >
    <p-treeNode
      *ngFor="
        let childNode of node.children;
        let firstChild=first;
        let lastChild=last;
        let index=index;
        trackBy: tree.nodeTrackBy
      "
      [node]="childNode"
      [parentNode]="node"
      [firstChild]="firstChild"
      [lastChild]="lastChild"
      [index]="index"
    ></p-treeNode>
  </ul>
</li>
<li
  *ngIf="tree.droppableNodes&&lastChild"
  class="ui-treenode-droppoint"
  [ngClass]="{'ui-treenode-droppoint-active ui-state-highlight': draghoverNext}"
  (drop)="onDropPoint($event,1)"
  (dragover)="onDropPointDragOver($event)"
  (dragenter)="onDropPointDragEnter($event,1)"
  (dragleave)="onDropPointDragLeave($event)"
></li>
