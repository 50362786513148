import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private fireStorage: AngularFireStorage
  ) {
  }

  bulk() {
    return new BulkUpload(this);
  }

  /**
   * Upload file Blob to Firebase Storage.  Returns download url.
   */
  async upload(path: string, file: Blob, metadata: firebase.storage.UploadMetadata = {}, progressCallback?: (snapshot: firebase.storage.UploadTaskSnapshot) => void): Promise<string> {
    const storageRef = await this.ref(path);
    const uploadTask = storageRef.put(file, metadata);

    const snapshot: firebase.storage.UploadTaskSnapshot = await new Promise((resolve, reject) => {
      uploadTask.on('state_changed', (snapshot: firebase.storage.UploadTaskSnapshot) => {
        // Observe state change events such as progress, pause, and resume
        if (progressCallback) progressCallback(snapshot);
      }, error => {
        reject(error);
      }, () => {
        // Handle successful uploads on complete
        resolve(uploadTask.snapshot);
      });
    });

    return snapshot.ref.getDownloadURL();
  }

  async delete(path: string): Promise<any> {
    // const storage = await this.Firebase.storage(path);
    const storage = this.fireStorage.storage;
    const deleteRef = storage.ref().child(path);
    return deleteRef.delete();
      // .catch(err => console.log('Storage.delete error', err.message));
  }

  /**
   * Get metadata associated with item in Firebase Storage
   */
  async getMetadata(storagePath: string) {
    const ref = await this.ref(storagePath);
    return ref.getMetadata();
  }

  /**
   * Set custom metadata on item in Firebase Storage.  Returns updated metadata.
   */
  // todo: make sure you set this when uploading documents as well
  async setMetadataProperty(storagePath: string, property: string, value: any) {
    const ref = await this.ref(storagePath);
    const update = { customMetadata: { [property]: value } };
    return ref.updateMetadata(update);
  }

  // /**
  //  * Upload file from URL to Firebase Storage
  //  * Returns download url
  //  */
  // async uploadFromUrl(url: string, storagePath: string, metadata: firebase.storage.UploadMetadata = {}): Promise<string> {
  //   const fileBlob = await this.helpers.getBlob(url);
  //   if (!fileBlob) return Promise.resolve(null);
  //   return this.upload(storagePath, fileBlob, metadata);
  // }

  private async ref(path: string) {
    // const storage = await this.Firebase.storage(path);
    const storage = this.fireStorage.storage;
    return storage.ref().child(path);
  }
}

type UploadQueue = {
  group: string;
  id: string;
  params: Parameters<StorageService['upload']>;
};

type UploadList = Record<string, string | Record<string, string>>;

class BulkUpload {

  constructor(private storage: StorageService) {

  }

  private queue: Array<UploadQueue> = [];

  add(group: string, id: string, ...params: UploadQueue['params']) {
    this.queue.push({ group, id, params });
  }

  // add(item: UploadQueue) {
  //   this.queue.push(item);
  // }

  async upload(): Promise<UploadList> {
    let promises = this.queue.map(value => this.storage.upload(...value.params));
    let result = await Promise.all(promises);
    let list: UploadList = {};

    result.forEach((value, index) => {
      const { id, group } = this.queue[index];
      if (group) {
        list[group] = list[group] || {};
        list[group][id] = value;
      } else {
        list[id] = value;
      }
    });

    return list;
  }
}
