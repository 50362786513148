import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserOrganizationsService } from '../_state/organizations.service';
import { CreateDialogService } from '../create-dialog/create-dialog.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserOrganizationsQuery } from '../_state/organizations.query';
import { AuthQuery } from 'src/app/auth/auth.query';
import { AuthStore } from 'src/app/auth/auth.store';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-organizations-page',
  templateUrl: './organizations-list.component.html',
  styleUrls: ['./organizations-list.component.scss']
})
export class OrganizationsListComponent implements OnInit {

  constructor(
    private orgQuery: UserOrganizationsQuery,
    private orgService: UserOrganizationsService,
    private dialog: CreateDialogService,
    private authQuery: AuthQuery,
    private authStore: AuthStore,
    private router: Router
  ) { }

  @ViewChild('currentUser', { static: true }) currentUser: ElementRef;

  user$ = this.authQuery.select('uid');
  organizations$ = this.orgQuery.selectAll();

  ngOnInit() {
    this.orgService.sync()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        console.log('org val', value);
      });

    // fromEvent(this.currentUser.nativeElement, 'keyup').pipe(
    //   map((event: KeyboardEvent) => (event.target as HTMLInputElement).value),
    //   filter(res => res.length > 2),
    //   debounceTime(1000),
    //   distinctUntilChanged()
    // )
    // .subscribe(value => {
    //   console.log('value');
    //   this.authStore.update(store => ({
    //     ...store,
    //     uid: value
    //   }))
    // });
  }

  create() {
    this.dialog.open({
      title: 'New organization wizard',
      description: `What's your organization name?`
    }).subscribe(name => {
      name && this.orgService.add({ name }).catch(err => {
        console.log(err);
      });
    });
  }

  delete($event: MouseEvent, id: string) {
    $event.stopPropagation();
    this.orgService.remove(id);
  }

  select($event: MouseEvent, id: string) {
    this.router.navigate(['/organizations', id]);
  }

}
