import { map, switchMap, finalize } from 'rxjs/operators';
import { AuthQuery } from './auth.query';

import { Injectable } from '@angular/core';
import { CollectionGuard, CollectionGuardConfig } from 'akita-ng-fire';
import { AuthService } from './auth.service';
import { AuthState } from './auth.store';

@CollectionGuardConfig({ awaitSync: true })
@Injectable({ providedIn: 'root' })
export class NoAuthGuard extends CollectionGuard<AuthState> {
  constructor(
    service: AuthService,
    private query: AuthQuery
  ) {
    super(service);
  }

  sync() {
    console.log('noAuth guard: sync');
    return this.service.sync().pipe(
      finalize(() => console.log('noAuth guard: finalize')),
      switchMap(_ => this.query.isLoggedIn$),
      map(isAuth => !isAuth || this.router.navigateByUrl('/organizations') && '')
    );
  }
}
