import { Input, Component, ChangeDetectionStrategy, ViewChild, ElementRef, Optional, Self, HostBinding, Renderer2 } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl } from '@angular/forms';
import { SpeechNotification, SpeechError } from '@app/_common/speech-recognizer.service';
import { SpeechDialogComponent } from '@app/speech-dialog/speech-dialog.component';
import { Popover } from '@app/popover/popover.service';
import { CustomInputField } from './customInputField.class';

@Component({
  selector: 'rich-input',
  templateUrl: './rich-input.component.html',
  styleUrls: ['./rich-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: RichInputComponent }
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichInputComponent extends CustomInputField<string> {
  controlType = 'rich-input';
  @HostBinding() id = `rich-input-${RichInputComponent.nextId++}`;
  @ViewChild('input') element: ElementRef;

  editor;
  voicePopupActive = false;
  @Input() multiline = false;
  @Input() bold = false;

  get empty() {
    return !this.value || this.isEmpty();
  }

  @Input()
  get value(): string {
    return this.editor ? this.editor.getContent() : null;
  }
  set value(value: string) {
    if (this.value !== value) {
      this.setContent(value);
    }
  }

  constructor(
    private popover: Popover,
    private renderer: Renderer2,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(ngControl);
  }

  ngAfterViewInit() {
    let options = {
      disableReturn: this.multiline ? false : true,
      disableExtraSpaces: true,
      imageDragging: false,
      paste: {
        forcePlainText: true
      },
      toolbar: {
        buttons: ['bold', 'italic', 'underline', 'strikethrough']
      }
    };

    if (this.placeholder) {
      options['placeholder'] = {
        text: this.placeholder,
        hideOnClick: false
      };
    } else {
      options['placeholder'] = false;
    }

    this.editor = new window['MediumEditor'](this.element.nativeElement, options);

    const placeholder = this.editor.getExtensionByName('placeholder');
    if (placeholder) {
      placeholder.updatePlaceholder(this.element.nativeElement);
    }

    this.editor.subscribe('editableInput', this.onInput.bind(this));
    this.editor.subscribe('focus', this.onFocus.bind(this));
    this.editor.subscribe('blur', this.onBlur.bind(this));
    this.editor.subscribe('editablePaste', this.onPaste.bind(this));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.editor && this.editor.destroy();
  }

  onInput() {
    this.onChange(this.value);
  }
  onFocus() {
    this.focused = true;
    this.stateChanges.next();
  }
  onBlur() {
    this.onTouched();
    this.focused = false;
    this.stateChanges.next();
  }
  onPaste() {
    if (this.bold && this.isEmpty()) {
      this.editor.execAction('bold');
    }
  }

  isEmpty() {
    const value = this.editor ? this.editor.elements[0].textContent.replace(/^\s+|\s+$/g, '') : '';
    return value === '';
  }

  setContent(value) {
    if (this.element) {
      const normalizedValue = value == null ? '' : value;
      this.renderer.setProperty(this.element.nativeElement, 'innerHTML', normalizedValue);
      this.stateChanges.next();
    }
  }

  disable() {}
  enable() {}

  onContainerClick(event: MouseEvent): void {
    if (!this.focused) {
      this.element.nativeElement.focus();

      // https://stackoverflow.com/questions/1125292/how-to-move-cursor-to-end-of-contenteditable-entity/3866442#3866442
      const range = document.createRange();
      range.selectNodeContents(this.element.nativeElement);
      range.collapse(false);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  startRecognition($event) {
    $event.stopPropagation();
    this.voicePopupActive = true;

    const ref = this.popover.open<string>({
      content: SpeechDialogComponent,
      width: 400,
      height: 200
    });

    ref.afterClosed$.subscribe(res => {
      if (res.data && res.data.trim().length > 0) {
        this.value = res.data;
        this.onInput();
      }
      this.voicePopupActive = false;
      this.onContainerClick(null);
    });
  }
}
