<form class="form" [formGroup]="contentForm">
  <mat-form-field class="full-width">
    <mat-label>Title</mat-label>
    <rich-input formControlName="title"></rich-input>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Subtitle</mat-label>
    <rich-input formControlName="subtitle"></rich-input>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Text heading</mat-label>
    <rich-input formControlName="heading"></rich-input>
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label>Text</mat-label>
    <!-- <textarea formControlName="text" matInput></textarea> -->
    <rich-input [multiline]=true formControlName="text"></rich-input>
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label>Text cite</mat-label>
    <rich-input formControlName="cite"></rich-input>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Notes section</mat-label>
    <textarea formControlName="notes" matInput></textarea>
  </mat-form-field>
</form>

<div *ngFor="let url of pageUrls; index as number;">
  <button (click)="openPage(url, 'page' +  (number +1))">Page {{ number + 1 }}</button>
</div>

<div class="pages-container">
  <dropzone (onDrop)="openFile($event)">DROP PDF HERE</dropzone>
</div>

<!-- <div class="pages-block">
  <div
    class="pages-container"
    layout="row" layout-align="start center"
  >
    <div
      class="add-page-container"
      aria-label="Add new page"
      layout="row" layout-align="center center"
      matTooltip="Click Here or Drop PDF to Add a Page"
      matTooltipShowDelay="1000"
      (click)="openPage()"
    >
      <mat-icon svgIcon="add_here_icon"></mat-icon>
    </div>

    <div
      class="page-thumb"
    >
      <img>
      <button
        class="md-icon-button"
        aria-label="Remove this page from the form"
        matTooltip="Click Here or Drop PDF to Add a Page"
        matTooltipShowDelay="1000"
      >
        <mat-icon>remove_circle</mat-icon>
      </button>
    </div>
  </div>
</div> -->

