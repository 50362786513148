import { Injectable, Inject } from '@angular/core';
import { Observable, Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BroadcastChannel } from 'broadcast-channel';

type ViewerMessage = {
  type: 'open';
  url: string;
  name: string;
};

@Injectable({
  providedIn: 'root'
})
export class ViewerWindow {

  instance: Window;
  channel: BroadcastChannel<ViewerMessage>;
  readyChannel: BroadcastChannel<boolean>;

  private _messages$ = new Subject<ViewerMessage>();
  messages$ = this._messages$.asObservable();

  private sender$ = new Subject<ViewerMessage>();
  private viewerReady$ = new BehaviorSubject(false);

  constructor(
  ) {
    this.channel = new BroadcastChannel('viewer');
    this.readyChannel = new BroadcastChannel('ready');

    this.channel.addEventListener('message', this.messageHandler.bind(this));
    this.readyChannel.addEventListener('message', message => {
      this.viewerReady$.next(message);
    });

    combineLatest([this.sender$, this.viewerReady$]).pipe(
      filter(([message, ready]) => ready),
      map(([message]) => message)
    )
    .subscribe(message => {
      this.channel.postMessage(message);
    })
  }

  messageHandler(message: ViewerMessage) {
    this._messages$.next(message);
  }

  open(url: string, name: string) {
    if (!this.instance) {
      this.instance = window.open('/viewer');
      this.instance.addEventListener('beforeunload', () => {
        // this.instance.removeEventListener('beforeunload');
        this.instance = null;
        this.viewerReady$.next(false);
      });
    } else {
      this.instance.focus();
    }

    this.sender$.next({
      type: 'open',
      url,
      name
    });
  }

  ready() {
    this.readyChannel.postMessage(true);
  }

}

