import { Injectable } from '@angular/core';
import { CollectionConfig, FireAuthService } from 'akita-ng-fire';
import { AuthState, AuthStore } from './auth.store';
import { UserData } from './auth.model';
import { User } from '@firebase/auth-types';
import { Observable, of, from } from 'rxjs';
import { publishReplay, refCount, tap, finalize } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'users' })
export class AuthService extends FireAuthService<AuthState> {

  private _sync$;

  constructor(store: AuthStore) {
    super(store);
    this._sync$ = super.sync().pipe(
      finalize(() => console.log('_sync$: finalizing')),
      tap(() => console.log('_sync$: tapping')),
      publishReplay(1),
      refCount(),
    );
  }

  sync() {
    return this._sync$;
  }

  // protected formatFromFirestore<User>(user: User): any {
  //   console.log('user', user);
  //   return { id: user.uid };
  // }

  // protected selectProfile(user: User): Observable<any> {
  //   console.log('selectProfile');
  //   // return this.db.collection(this.path).doc(user.uid).valueChanges();
  // }

  sendResetEmail(email: string) {
    return this.auth.sendPasswordResetEmail(email, {
      url: `${window.location.origin}/auth/resetsuccess`
    });
  }

  protected createProfile(user: User, { name }: Partial<UserData>): UserData {
    return {
      id: user.uid,
      email: user.email,
      name,
      access: 'free'
    };
  }

}
