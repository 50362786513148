<div class="sidebar">
  <div class="tree-list">
    <button mat-icon-button>
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <mat-form-field>
      <mat-select [value]="activeTree" (selectionChange)="onTreeChange($event)">
        <mat-select-trigger class="active-tree">
          <mat-icon>{{ activeTree.icon }}</mat-icon>{{ activeTree.title }}
        </mat-select-trigger>
        <mat-option *ngFor="let type of treeTypes" [value]="type">
          <mat-icon>{{ type.icon }}</mat-icon>{{ type.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Open Tree Header Tools">
      <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <span>Import Outline</span>
      </button>
      <button mat-menu-item>
        <span>Expand All</span>
      </button>
      <button mat-menu-item>
        <span>Collapse All</span>
      </button>
      <button mat-menu-item>
        <mat-icon>history</mat-icon>
        <span>Show Form History</span>
      </button>
    </mat-menu>
  </div>

  <project-tree></project-tree>

  <div class="tree-footer">

    <button
      mat-icon-button
      (click)="addGroup()"
      aria-label="Add new group"
    >
      <mat-icon matTooltip="Add new form" matTooltipShowDelay="300">add_box</mat-icon>
    </button>

    <div class="node-index">
      <span>{{ 0 }}</span>
      of {{ 10 }}
    </div>

    <!-- <div class="node-index-collapsed" ng-click="$ctrl.openDirectNav()">
      <span>{{ $ctrl.selectedNodeNumber || '&nbsp;' }}</span>
    </div> -->

    <button
      class="md-icon-button tree-control"
      ng-click="$ctrl.showCheatSheet()"
      aria-label="Show hotkeys help"
    >
      <mat-icon matTooltip="Keyboard shortcuts" matTooltipShowDelay="300">keyboard</mat-icon>
    </button>

  </div>
</div>

<div class="main">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon svgIcon="content"></mat-icon>Content
      </ng-template>
      <content-component></content-component>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon svgIcon="design"></mat-icon>Design
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
