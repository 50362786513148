import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AuthStore, AuthState } from './auth.store';
import { UserStamp } from '@app/_state/pages';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {

  isLoggedIn$ = this.select(state => !!state.uid);

  /**
   * Returns current user and time stamp to be used when saving assets
   */
  getStamp(action: string = null): UserStamp {
    const { profile } = this.store.getValue();
    return {
      user: profile.id,
      email: profile.email,
      time: Math.floor(Date.now() / 1000),
      action
    };
  }

  constructor(protected store: AuthStore) {
    super(store);
  }
}
