<!-- <div class="change-user">
  <input #currentUser [value]="user$ | async">
</div> -->

<button mat-flat-button color="primary" (click)="create()">Create organization</button>

<mat-nav-list>
  <mat-list-item (click)="select($event, org.id)" *ngFor="let org of organizations$ | async">
    <span>{{ org.name }}</span>
    <button mat-icon-button>
      <mat-icon (click)="delete($event, org.id)">delete</mat-icon>
    </button>
  </mat-list-item>
</mat-nav-list>
