import { Annotations, WebViewerInstance } from '@pdftron/webviewer';

export interface AnnotationGroup extends Array<Annotations.RectangleAnnotation> {
  0: Annotations.TextHighlightAnnotation;
  [key: number]: Annotations.RectangleAnnotation;
}

export function fixHighlight(annot: Annotations.TextHighlightAnnotation, instance: WebViewerInstance) {

  const { annotManager } = instance;

  const padWidth = 2;
  const padHeight = 1;
  const snap = 5;

  //stretch height
  let tolerance = (annot.Quads[0].y1 - annot.Quads[0].y4) /2;
  let lineCount = 1;
  let lastOneTop = annot.Y;
  annot.Quads.forEach(quad => {
    if (quad.y4 > lastOneTop + tolerance)
    {
      lineCount += 1;
      lastOneTop = quad.y4;
    }
  });
  let lineHeight = annot.Height / lineCount + padHeight;
  annot.Quads.forEach(quad => {
    quad.y1 = quad.y4 + lineHeight;
    quad.y2 = quad.y4 + lineHeight;
  });

  //stretch width and delete extra's on same line
  let myLineNumber = 1;
  let lastLineTop = annot.Y;
  for (let i = 0; i < annot.Quads.length; i++) {
    if (annot.Quads[i].y4 > lastLineTop + tolerance)
    {
      myLineNumber += 1;
      lastLineTop = annot.Quads[i].y4;
    }
    else if (i > 0){
      annot.Quads[i - 1].x2 = annot.Quads[i].x2;
      annot.Quads[i - 1].x3 = annot.Quads[i].x3;
      annot.Quads.splice(i,1);
    }
  }

  //align lefts and rights
  annot.Quads.forEach(quad => {
    if (quad.x1 < annot.getLeft() + snap && quad.x1 > annot.getLeft() - snap)
    {
      quad.x1 = annot.getLeft();
      quad.x4 = annot.getLeft();
    }
    if (quad.x2 < annot.getRight() + snap && quad.x2 > annot.getRight() - snap)
    {
      quad.x2 = annot.getRight();
      quad.x3 = annot.getRight();
    }
  });

  //pad the widths a little
  annot.Quads.forEach(quad => {
    quad.x1 -= padWidth;
    quad.x2 += padWidth;
    quad.x3 += padWidth;
    quad.x4 -= padWidth;
  });

  //make rect match selection
  annot.adjustRect();

  annotManager.updateAnnotation(annot);
}

export function convertToRectangles(annot: Annotations.TextHighlightAnnotation, instance: WebViewerInstance) {

  const { Annotations, annotManager } = instance;

  //add rectangle for each quad
  const myGroup: AnnotationGroup = [annot];

  annot.Quads.forEach(quad => {
    const rectangleAnnot = new Annotations.RectangleAnnotation();

    rectangleAnnot.setCustomData('highlight', true);

    rectangleAnnot.X = quad.x1;
    rectangleAnnot.Y = quad.y4;
    rectangleAnnot.Width = quad.x2 - quad.x1;
    rectangleAnnot.Height = quad.y1 - quad.y4;

    rectangleAnnot.FillColor = annot.StrokeColor;
    rectangleAnnot.FillColor.A = .5;

    rectangleAnnot.StrokeColor = annot.StrokeColor;
    rectangleAnnot.StrokeColor.A = .5;

    rectangleAnnot.PageNumber = annot.PageNumber;
    rectangleAnnot.Author = annotManager.getCurrentUser();

    annotManager.addAnnotation(rectangleAnnot);
    myGroup.push(rectangleAnnot);
  });

  annotManager.hideAnnotation(annot);
  annotManager.groupAnnotations(annot as any, myGroup);
}

export function convertToHighlight(annot: Annotations.RectangleAnnotation, instance: WebViewerInstance) {
  const { annotManager } = instance;

  const group: AnnotationGroup = (annotManager.getGroupAnnotations as any)(annot);
  const [highlight, ...rectangles] = group;

  annotManager.ungroupAnnotations(group);

  rectangles.forEach((annot, index) => {
    const quad = highlight.Quads[index];

    quad.y1 = annot.Y + annot.Height;
    quad.y2 = quad.y1;
    quad.y3 = annot.Y;
    quad.y4 = annot.Y;

    quad.x1 = annot.X;
    quad.x2 = annot.X + annot.Width;
    quad.x3 = quad.x2;
    quad.x4 = quad.x1;
  });

  annotManager.showAnnotation(highlight);
  annotManager.deleteAnnotations(rectangles);
}
