<div class="tabs">
  <mat-tab-group
    [selectedIndex]="openTabs.indexOf(activeTab)"
    (selectedTabChange)="selectTab($event.index)"
    (click)="onTabGroupClick($event)"
  >
    <mat-tab *ngFor="let tab of openTabs; index as tabIndex">
      <ng-template mat-tab-label>
        <mat-icon>description</mat-icon>{{ tab.name }}
        <button mat-icon-button (click)="closeTab($event, tab)" (mousedown)="stopEvent($event)">
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="viewer" #viewer></div>

<div class="bottom-bar">
  <button mat-raised-button (click)="savePage($event)">Save current page</button>
  <button mat-raised-button (click)="ocrPage($event)">OCR</button>
  <button mat-raised-button (click)="downloadPage()">Download page</button>
</div>
