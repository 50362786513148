import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, AfterViewInit, AfterContentInit, ViewChild, ContentChild, TemplateRef, QueryList, ContentChildren, ChangeDetectorRef } from '@angular/core';
import { TreeDragDropService, TreeNode as PrimeTreeNode, PrimeTemplate } from 'primeng/api';
import { cloneDeep } from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ChangeManager, traverseTree } from 'src/app/_common/helpers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Tree } from './primetree/tree';

export interface TreeNode<S> extends PrimeTreeNode {
  data: S;
  children?: TreeNode<S>[];
};

type TreeNodeDropBaseEvent<S> = {
  originalEvent: DragEvent;
  dragNode: TreeNode<S>;
  dropNode: TreeNode<S>;
  accept?: () => void;
};
type TreeOnDropPointEvent<S> = TreeNodeDropBaseEvent<S> & { dropIndex: number; };
type TreeOnDropNodeEvent<S> = TreeNodeDropBaseEvent<S> & { index: number; };

export type TreeNodeDropEvent<S> = TreeOnDropPointEvent<S> | TreeOnDropNodeEvent<S>;
function isDropPointEvent<S>(event: TreeNodeDropEvent<S>): event is TreeOnDropPointEvent<S> {
  return typeof event['dropIndex'] !== 'undefined';
}

export type TreeNodeExpandEvent<S> = {
  originalEvent: MouseEvent;
  node: TreeNode<S>;
};

export interface TreeNodeData {
  id: string;
  name: string;
  children: string[];
  [key: string]: any;
}

type TreeStructureNode<S> = {
  [K in keyof S]: S[K];
} & {
  children?: TreeStructureNode<S>[];
}

@UntilDestroy()
@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  providers: [TreeDragDropService],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeComponent<S extends TreeNodeData> implements OnInit, AfterViewInit {

  private _filtered: boolean = false;

  get filtered() { return this._filtered; }

  @Input() set filtered(value: boolean) {
    if (this._filtered === value) return;
    this._filtered = value;
    if (value === true) {
      this.expandedMap = new Map();
    }
  }
  @Input() set selectedNodeId(id: string) {
    if (!this.selectedNode || this.selectedNode.data.id !== id) {
      this.selectedNode = this.tree.find(node => node.data.id === id);
      console.log('this.selectedNode', this.selectedNode, this.tree);
    }
  };
  @Input() items: Observable<S[]>;
  @Output() itemChange = new EventEmitter<ChangeManager<S>>();
  @Output() nodeSelect = new EventEmitter<S>();
  @Output() nodeToggle = new EventEmitter<{ id: string; value: boolean; }>();

  @ViewChild(Tree) treeComponent: Tree;
  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;

  tree$: Observable<TreeNode<S>[]>;
  tree: TreeNode<S>[] = [];
  selectedNode: TreeNode<S>;
  private expandedMap = new Map<string, boolean>();

  constructor(private cdr: ChangeDetectorRef) {}

  update() {
    this.cdr.markForCheck();
  }

  onDrop($event: TreeNodeDropEvent<S>) {
    const changes = new ChangeManager<S>();

    let dragList = $event.dragNode.parent ? $event.dragNode.parent.children : this.tree;
    let dragListId = $event.dragNode.parent && $event.dragNode.parent.key || 'root';
    let dragNodeIndex = dragList.indexOf($event.dragNode);

    let dropList: PrimeTreeNode[];
    let dropListId: string;

    let insertIndex: number;

    if (isDropPointEvent($event)) {
      let position = $event.originalEvent.target['previousElementSibling'] ? 1 : -1;
      let dropNodeIndex = $event.dropIndex;

      dropList = $event.dropNode.parent ? $event.dropNode.parent.children : this.tree;
      dropListId = $event.dropNode.parent && $event.dropNode.parent.key || 'root';

      if (dragList === dropList) {
        insertIndex = (position < 0  && dragNodeIndex < dropNodeIndex) ? dropNodeIndex - 1 : dropNodeIndex;
      } else {
        insertIndex = position < 0 ? dropNodeIndex : dropNodeIndex + 1;
      }

    } else {
      dropList = $event.dropNode.children || [];
      dropListId = $event.dropNode.key;
      insertIndex = dropList.length;
    }

    const dragKeyArr = dragList.map(el => el.key);
    const dropKeyArr = dragList === dropList ? dragKeyArr : dropList.map(el => el.key);

    dragKeyArr.splice(dragNodeIndex, 1);
    dropKeyArr.splice(insertIndex, 0, $event.dragNode.key);

    changes.update(dragListId, { children: dragKeyArr } as S);
    if (dragKeyArr !== dropKeyArr) changes.update(dropListId, { children: dropKeyArr } as S);

    this.itemChange.emit(changes);
  }

  hasChild = (node: TreeStructureNode<any>) => !!node.children && node.children.length > 0;
  trackByFn = (index: number, item: TreeNode<S>) => {
    if (!item) console.log('trackByFn problem?', index, item);
    return item ? item.key : undefined;
  };

  onSelect({ node }: { node: TreeNode<S> }) {
    this.nodeSelect.emit(node as any);
  }
  onExpand({ node }: TreeNodeExpandEvent<S>) {
    // todo: move to org tree comp?
    if (this.filtered) this.expandedMap.set(node.key, true);
    this.nodeToggle.emit({ id: node.key, value: true });
  }
  onCollapse({ node }: TreeNodeExpandEvent<S>) {
    // todo: move to org tree comp?
    if (this.filtered) this.expandedMap.set(node.key, false);
    this.nodeToggle.emit({ id: node.key, value: false });
  }

  ngOnInit() {
    this.tree$ = this.items.pipe(
      map(items => {
        const itemMap: Record<string, TreeNode<S>> = {};

        items.forEach(node => {
          const treeNode: TreeNode<S> = {
            key: node.id,
            data: cloneDeep(node),
            type: (node.type === 'project') ? 'file' : 'folder', // todo: remove or replace
            droppable: node.type !== 'project',
            draggable: false,
            children: []
          };

          if (!this.filtered) {
            treeNode.expanded = treeNode.data._meta?.expanded;
          } else if (this.expandedMap.has(treeNode.key)) {
            treeNode.expanded = this.expandedMap.get(treeNode.key);
          } else {
            treeNode.expanded = true;
          }

          itemMap[node.id] = treeNode;
        });

        items.forEach(item => {
          // const parentId = itemMap[item.id].data._meta.parent;
          // itemMap[item.id].parent = itemMap[parentId];
          if (item.children) {
            const children = item.children.map(id => itemMap[id]).filter(el => !!el);
            children.forEach(el => {
              itemMap[el.data.id].parent = itemMap[item.id];
            });
            itemMap[item.id].children = children;
          }
        });

        return itemMap['root'] && itemMap['root'].children || [];
      }),
      tap(tree => this.tree = tree)
    );
  }

  ngAfterViewInit() {
    this.treeComponent.dragDropService.dragStop$
      .pipe(untilDestroyed(this))
      .subscribe(event => {
        event.node.styleClass = null;
        event.node.draggable = false;
      });

    this.treeComponent.allowDrop = (...params) => {
      const [dragNode, dropNode] = params;
      const event = params[3] as DragEvent;

      if (!dropNode || !dragNode || dragNode === dropNode) return false;
      // if (event) return dragNode.data.type === dropNode.data.type;
      if (event) return true;

      return (
        dragNode.parent
        && dragNode.parent !== dropNode
        // && dragNode.parent.data.type === dropNode.data.type
      );
    };
  }

}
