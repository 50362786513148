import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, ValidatorFn } from '@angular/forms';

import { AuthService } from '../auth/auth.service';
import { AuthQuery } from '../auth/auth.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { MatTabChangeEvent } from '@angular/material/tabs';

@UntilDestroy()
@Component({
  selector: 'auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.scss']
})
export class AuthFormComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private authQuery: AuthQuery
  ) { }

  errorMessage = '';
  user$ = this.authQuery.select('uid');
  loading$ = this.authQuery.select('loading');

  form = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.minLength(6), Validators.required]],
    passwordConfirm: [''],

    name: this.formBuilder.group({
      first: [''],
      middle: [''],
      last: ['']
    })
  });

  ngOnInit() {
    this.auth.sync()
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  get email() { return this.form.get('email'); }
  get password() { return this.form.get('password'); }
  get passwordConfirm() { return this.form.get('passwordConfirm'); }
  get firstName() { return this.form.get('name.first'); }
  get lastName() { return this.form.get('name.last'); }

  get passwordDoesMatch() {
    return this.password.value === this.passwordConfirm.value;
  }

  logout() {
    this.auth.signOut();
  }

  signup() {
    const { email, password, name } = this.form.value;

    this.auth.signup(email, password, {
      ctx: { name }
    })
      .then(() => this.reset())
      .catch(err => {
        this.errorMessage = err.message;
      });
  }

  login() {
    const { email, password } = this.form.value;
    this.auth.signin(email, password)
      .then(() => this.reset())
      .catch(err => {
        this.errorMessage = err.message;
      });
  }

  recover() {
    this.auth.sendResetEmail(this.email.value);
  }

  reset() {
    this.form.reset();
    this.errorMessage = '';
  }

  tabChange(event: MatTabChangeEvent) {
    this.errorMessage = '';

    if (event.index === 1) {
      this.setValidators('name.first', [Validators.required]);
      this.setValidators('name.last', [Validators.required]);
    } else {
      this.clearValidators('name');
    }
  }

  private setValidators(path: string | string[], validator: ValidatorFn | ValidatorFn[]) {
    const control = this.form.get(path);
    control.setValidators(validator);
    control.updateValueAndValidity();
  }

  private clearValidators(path: string | string[]) {
    const control = this.form.get(path);
    control.clearValidators();
    control.updateValueAndValidity();
  }

}
