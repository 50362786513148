<div class="sidebar">
  <div class="organization-list">
    <mat-form-field>
      <mat-select [value]="(org$ | async)?.id" (selectionChange)="onOrganizationChange($event)">
        <mat-option *ngFor="let org of (orgList$ | async)" [value]="org.id">
          {{ org.metadata.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <fav-icon (change)="setFavoriteFilter($event)"></fav-icon>
  </div>

  <div class="favorites-bar" *ngIf="filterFavorites$ | async">Favorites</div>

  <organization-tree></organization-tree>

  <div class="action-buttons" *ngIf="!(filterFavorites$ | async)">
    <button mat-stroked-button (click)="openDialog('client')">New Client</button>
    <button mat-stroked-button (click)="openDialog('matter')" *ngIf="clientSelected">New Matter</button>
    <button mat-stroked-button (click)="openDialog('project')" *ngIf="matterSelected">New Project</button>
  </div>
</div>

<div class="main">
  <project-dashboard *ngIf="projectSelected"></project-dashboard>
</div>
