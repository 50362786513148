import { Input, OnDestroy, HostBinding, Directive } from '@angular/core';
import { Subject } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl, ControlValueAccessor } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

// https://angular.io/api/forms/ControlValueAccessor
// https://material.angular.io/guide/creating-a-custom-form-field-control#-code-setdescribedbyids-ids-string-code-
@Directive()
export abstract class CustomInputField<T> implements MatFormFieldControl<T>, ControlValueAccessor, OnDestroy {
  static nextId = 0;

  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  abstract controlType: string;
  onChange = (_: any) => {};
  onTouched = () => {};

  @HostBinding() abstract id: string;
  @HostBinding('attr.aria-describedby') describedBy = '';

  abstract get empty(): boolean;
  get shouldLabelFloat() { return this.focused || !this.empty; }

  @Input() abstract value: T;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.disable() : this.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  constructor(
    public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  abstract disable(): void;
  abstract enable(): void;

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  abstract onContainerClick(event: MouseEvent): void;

  writeValue(value: T) {
    this.value = value;
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
