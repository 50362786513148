import WebViewer, { WebViewerInstance, PDFNet, Annotations, CoreControls } from '@pdftron/webviewer';
import { cropAnnotations, hiresDpi, removePageAnnotations, generatePageDimensions, rotateImage, createThumb, calculatePerformance, blobToImage, canvasToBlob, scaleToFit } from './viewerHelpers';
import { Export, MaskMode, PageDimensions, PageObject, RasterizePageOptions, Rotation } from './types';
import { AutoCrop } from './autoCrop.class';
import { Big } from './big.extended';
import { convertToHighlight, convertToRectangles, fixHighlight } from './annotationHelpers';
import { CanvasFill } from './fill';

const LICENSE_KEY = 'Intellectual Property Demonstratives Inc.(ipdemons.com):OEM:Slide Request Forms::B+:AMS(20211030):CFA52B7D0477380AB360B13AC9A2737860614F99CF786A25ED1C19DA3A3400F624DABEF5C7';

export class Viewer {
  constructor(public instance: WebViewerInstance) {
    const { annotManager, docViewer, Tools, Annotations } = instance;


    docViewer.on('pageComplete', (pageNumber, canvas) => {
      if (pageNumber === active.page && active.redraw) {
        active.redraw();
      }
    });

    // docViewer.on('pageNumberUpdated', pageNumber => {
    //   console.log(pageNumber);
    // });

    const multiplier = 4;

    const active = {
      interval: null as number,
      redraw: null as () => void,
      page: null as number,
      fill: null as CanvasFill,
      canvas: null as HTMLCanvasElement,
      borderCanvas: null as HTMLCanvasElement
    };

    const customTool = new Tools.Tool(docViewer);

    customTool.cursor = 'crosshair';

    customTool.switchOut = () => {
      if (active.interval) clearInterval(active.interval);
      active.interval = null;
      active.redraw = null;
      active.page = null;
      active.fill = null;
      active.canvas = null;
      active.borderCanvas = null;
    };

    customTool.keyDown = (e) => {
      if (e.keyCode === 13) {
        const cs = active.fill.trace();
        active.redraw();

        const polygonArr = [];



        let startPoint: { x: number, y: number } = null;
        let currentPolygon: Annotations.PolygonAnnotation = null;

        const addPathPoint = (x, y) => {
          if (!startPoint) {
            startPoint = { x, y };

            currentPolygon = new Annotations.PolygonAnnotation();
            currentPolygon.PageNumber = active.page;
            currentPolygon.Author = annotManager.getCurrentUser();
            currentPolygon.StrokeColor = new Annotations.Color(255, 0, 0);

            polygonArr.push(currentPolygon);
          } else if (startPoint.x === x && startPoint.y === y) {
            startPoint = null;
          }

          currentPolygon.addPathPoint(x, y);
        };

        for (var i = 0; i < cs.length; i++) {
            if (cs[i].inner) continue;
            var ps = cs[i].points;

            addPathPoint(ps[0].x / multiplier, ps[0].y / multiplier);
            for (var j = 1; j < ps.length; j++) {
              addPathPoint(ps[j].x / multiplier, ps[j].y / multiplier);
            }
        }

        polygonArr.forEach(polygon => {
          annotManager.addAnnotation(polygon);
          // need to draw the annotation otherwise it won't show up until the page is refreshed
          annotManager.redrawAnnotation(polygon);
        });

        instance.setToolMode('AnnotationEdit');
      }

      else if (e.keyCode === 16) {
        customTool.cursor = 'copy';
      }
    };

    docViewer.on('keyUp', (e: KeyboardEvent) => {
      if (e.shiftKey === false) {
        customTool.cursor = 'crosshair';
      }
    });

    const getCanvas = (pageNumber: number, multiplier: number) => {
      return new Promise(resolve => {
        docViewer.getDocument().loadCanvasAsync({
          pageNumber,
          multiplier,
          drawComplete: async (canvas: HTMLCanvasElement) => resolve(canvas)
        });
      }) as Promise<HTMLCanvasElement>;
    };

    customTool.mouseLeftDown = async (e: MouseEvent) => {
      let clickMode = MaskMode.reselect;
      if (e.shiftKey === true) {
        clickMode = MaskMode.add;
      } else if (e.altKey === true) {
        clickMode = MaskMode.substract;
      }

      const windowCoordinates = customTool.getMouseLocation(e);
      const displayMode: CoreControls.DisplayMode = docViewer.getDisplayModeManager().getDisplayMode();
      const page = displayMode.getSelectedPages(windowCoordinates, windowCoordinates);
      const clickedPage: number = (page.first !== null) ? page.first : docViewer.getCurrentPage();
      const pageCoordinates = displayMode.windowToPage(windowCoordinates, clickedPage);
      const viewerElement = docViewer.getViewerElement();

      // let isPageChange = false;

      if (active.interval) window.clearInterval(active.interval);

      if (active.page !== clickedPage) {
        active.page = clickedPage;
        active.canvas = await getCanvas(clickedPage, multiplier);

        active.borderCanvas = document.createElement('canvas');
        active.borderCanvas.width = active.canvas.width;
        active.borderCanvas.height = active.canvas.height;

        active.fill = new CanvasFill(active.canvas, active.borderCanvas);
      }

      if (clickMode === MaskMode.add) {
        active.fill.addMode = true;
      }

      active.fill.drawMask(pageCoordinates.x * multiplier, pageCoordinates.y * multiplier);
      active.fill.addMode = false;

      active.redraw = () => {
        const zoom = docViewer.getPageZoom(clickedPage);

        // when the page is zoomed in, i.e. zoom > 1, rect contains offsets of rendered area
        // rect.x1 - left margin
        // rect.y1 - top margin
        const rect = docViewer.getViewportRegionRect(clickedPage);

        const auxCanvas = viewerElement.querySelectorAll('.pageSection .auxiliary').item(clickedPage - 1) as HTMLCanvasElement;
        if (!auxCanvas) return console.log('no aux canvas found');
        const auxContext = auxCanvas.getContext('2d');

        auxContext.setTransform(1, 0, 0, 1, 0, 0);
        auxContext.clearRect(0, 0, auxCanvas.width, auxCanvas.height);
        if (zoom >= 1 && rect) {
          auxContext.scale(zoom * (window.devicePixelRatio / multiplier), zoom * (window.devicePixelRatio / multiplier));
          auxContext.drawImage(active.borderCanvas, -rect.x1 * multiplier, -rect.y1 * multiplier);
        } else {
          auxContext.drawImage(active.borderCanvas, 0, 0, auxCanvas.width, auxCanvas.height);
        }
      };

      active.redraw();

      active.interval = window.setInterval(() => {
        active.fill.hatchTick();
        active.redraw();
      }, 300);

      // const blob = await canvasToBlob(tempCanvas);
      // window.open(URL.createObjectURL(blob));



    }

    const myTool = {
      toolName: 'MyTool',
      toolObject: customTool,
      buttonImage: 'ic_annotation_apply_redact_black_24px',
      buttonName: 'myToolButton',
      buttonGroup: 'miscTools',
      tooltip: 'MyTooltip'
    };

    instance.registerTool(myTool);

    instance.setHeaderItems((header) => {
      header.push({
        type: 'toolButton',
        toolName: 'MyTool'
      })
      // header.getHeader('toolbarGroup-Shapes').get('freeHandToolGroupButton').insertBefore({

      // });
    });

    this.instance.annotationPopup.add([{
      title: 'Fix highlight',
      type: 'actionButton',
      img: 'ic_annotation_apply_redact_black_24px',
      dataElement: 'fixHighlight',
      onClick: async () => {
        const annot = annotManager.getSelectedAnnotations()[0] as Annotations.TextHighlightAnnotation;
        convertToRectangles(annot, this.instance);
      }
    }]);

    this.instance.annotationPopup.add([{
      title: 'Auto Crop',
      type: 'actionButton',
      img: 'ic_crop_black_24px',
      dataElement: 'annotationAutoCrop',
      onClick: async () => {
        const annot = annotManager.getSelectedAnnotations()[0];
        await this.shrinkCallout(annot);
        annotManager.updateAnnotation(annot);
      }
    }]);

    this.instance.annotationPopup.add([{
      title: 'Move down',
      type: 'actionButton',
      img: 'ic_arrow_down_black_24px',
      dataElement: 'highlightMoveDown',
      onClick: async () => {
        const annot = annotManager.getSelectedAnnotations()[0] as Annotations.TextHighlightAnnotation;

        annot.Quads.forEach(quad => {
          quad.y1 += 1;
          quad.y2 += 1;
          quad.y3 += 1;
          quad.y4 += 1;
        });

        annotManager.updateAnnotation(annot);
      }
    }]);

    this.instance.annotationPopup.add([{
      title: 'Move up',
      type: 'actionButton',
      img: 'ic_arrow_up_black_24px',
      dataElement: 'highlightMoveUp',
      onClick: async () => {
        const annot = annotManager.getSelectedAnnotations()[0] as Annotations.TextHighlightAnnotation;

        annot.Quads.forEach(quad => {
          quad.y1 -= 1;
          quad.y2 -= 1;
          quad.y3 -= 1;
          quad.y4 -= 1;
        });

        annotManager.updateAnnotation(annot);
      }
    }]);

    const state = {
      annotationAutoCrop: false,
      highlightMoveDown: false,
      fixHighlight: false
    };

    const disableElements = (elements = Object.keys(state)) => {
        elements.forEach(el => {
          state[el] = false;
        });
        this.instance.disableElements(elements);
    };

    const enableElements = (elements: string[]) => {
      disableElements();
      elements.forEach(el => {
        state[el] = true;
      });
      this.instance.enableElements(elements);
    };

    annotManager.on('annotationSelected', async (annotations, action) => {
      // console.log({ action, annotations });

      if (action === 'deselected' || annotations.length !== 1) {
        disableElements();
      } else if (action === 'selected') {

        switch (annotations[0]?.Subject) {
          case 'Rectangle':
            enableElements(['annotationAutoCrop']);
            break;
          case 'Highlight':
            enableElements(['highlightMoveDown', 'highlightMoveUp', 'fixHighlight']);
            break;
          default:
            disableElements();
        }
      }
    });

    annotManager.on('annotationChanged', async (annotations, action, info) => {
      if (!annotations || !annotations.length || info.imported) return;

      const annot = annotations[0];

      switch (annot.Subject) {
        case 'Highlight':
          if (action === 'add' || action === 'modify') {
            fixHighlight(annot as Annotations.TextHighlightAnnotation, instance);
          }
          break;
        case 'Rectangle':
          if (action === 'add' && !info.isUndoRedo && !annot.getCustomData('highlight')) {
            await this.shrinkCallout(annot);
            annotManager.updateAnnotation(annot);
          }
          break;
      }
    });

    annotManager.on('annotationDoubleClicked', async (annot: Annotations.Annotation) => {
      console.log('annotationDoubleClicked', annot);
      switch (annot.Subject) {
        case 'Highlight':
          convertToRectangles(annot as Annotations.TextHighlightAnnotation, instance);
          break;
        case 'Rectangle':
          if (annot.isGrouped() && annot.getCustomData('highlight')) {
            convertToHighlight(annot as Annotations.RectangleAnnotation, instance);
          }
          break;
      }
    });
  }

  async adjustOCR(doc: CoreControls.Document) {

    const { runWithCleanup, Font, ElementReader, ElementWriter, Element, ColorSpace, ColorPt } = this.instance.PDFNet;

    // const doc = this.instance.docViewer.getDocument();
    const pdfDoc = await doc.getPDFDoc();
    const page = await pdfDoc.getPage(1);
    const reader = await ElementReader.create();
    const writer = await ElementWriter.create();

    const colorspace = await ColorSpace.createDeviceRGB();
    const redColor = await ColorPt.init(1, 0, 0, 0);


    const iterateText = async (reader: PDFNet.ElementReader, writer: PDFNet.ElementWriter) => {

      while (await reader.next()) {
        const element = await reader.current();
        const gs = await element.getGState();

        switch (await element.getType()) {
          case Element.Type.e_text:
            const str = await element.getTextString();
            if (str.trim().length > 0) {
              // await gs.setTextRenderMode(0);
              // await gs.setFillOpacity(0.3);
              // await gs.setFillColorSpace(colorspace);
              // await gs.setFillColorWithColorPt(redColor);

              await gs.setTextRise(-1);
              await element.updateTextMetrics();
              await writer.writeElement(element);
            }
            break;
          // case Element.Type.e_image:
          //   gs.setBlendMode(2);
          //   await writer.writeElement(element);
          //   break;
          case Element.Type.e_form:
            await reader.formBegin();
            await iterateText(reader, writer);
            await reader.end();
            break;
          default:
            await writer.writeElement(element);
        }
      }
    };

    await runWithCleanup(async () => {
      await pdfDoc.lock();

      await reader.beginOnPage(page);
      await writer.beginOnPage(page, ElementWriter.WriteMode.e_replacement);
      await iterateText(reader, writer);
      await writer.end();
      await reader.end();
    }, LICENSE_KEY);

    // this.instance.docViewer.refreshPage(1);
    // this.instance.docViewer.getDocument().refreshTextData();
    // (this.instance.docViewer as any).updateView();
  }

  async shrinkCallout(annotation: Annotations.Annotation) {
    const maxDim = 1000;

    const { PDFDraw, Rect } = this.instance.PDFNet;
    const pdfdraw = await PDFDraw.create();
    const pdfDoc = await this.instance.docViewer.getDocument().getPDFDoc();
    const page = await pdfDoc.getPage(annotation.getPageNumber());

    const pageSettings = {
      rotation: await page.getRotation(),
      width: await page.getPageWidth(),
      height: await page.getPageHeight()
    };

    const annotRect = annotation.getRect();
    annotRect.normalize();

    let stroke = Number(annotation['StrokeThickness']);

    const annotWidth = Big(annotRect.x2).minus(annotRect.x1).num();
    const annotHeight = Big(annotRect.y2).minus(annotRect.y1).num();

    let { x1, x2, y1, y2 } = this.annotationRectToCoordinates(annotRect, pageSettings);

    const clipRect = new Rect(x1, y1, x2, y2);
    const { scaledW, scaledH, scaleMultiplier } = scaleToFit({
      height: annotHeight,
      width: annotWidth,
      rotation: pageSettings.rotation
    }, maxDim);

    pdfdraw.setAntiAliasing(true);
    pdfdraw.setImageSmoothing(true, true);
    pdfdraw.setDrawAnnotations(true);
    pdfdraw.setClipRect(clipRect);
    pdfdraw.setImageSize(scaledW, scaledH, false);

    // (await this.getPageObject(annotation.PageNumber)).pageReference
    const imgTypedArray: Uint8Array = await (pdfdraw as any).exportStream(page, 'PNG');
    const blob = new Blob([imgTypedArray.buffer], { type: 'image/png' });
    // window.open(URL.createObjectURL(blob));

    const image = await blobToImage(blob);
    const crop = new AutoCrop(image, 0, 99.9);
    const calc = crop.calculate();

    // const canvas = document.createElement('canvas');
    // const ctx = canvas.getContext('2d');
    // canvas.width = calc.width;
    // canvas.height = calc.height;
    // ctx.drawImage(
    //   image,
    //   calc.offset.left, calc.offset.top,
    //   calc.width, calc.height,
    //   0, 0, calc.width, calc.height
    // );

    // const croppedBlob = await canvasToBlob(canvas);
    // window.open(URL.createObjectURL(croppedBlob));

    // scaled offset
    let os = {
      l: Big(calc.offset.left).div(scaleMultiplier).round(4).num(),
      t: Big(calc.offset.top).div(scaleMultiplier).round(4).num(),
      r: Big(calc.offset.right).div(scaleMultiplier).round(4).num(),
      b: Big(calc.offset.bottom).div(scaleMultiplier).round(4).num(),
    };

    let x1d, x2d, y1d, y2d;

    if (pageSettings.rotation === 0) {
      [x1d, x2d, y1d, y2d] = [os.l, -os.r, -os.t, os.b];
    } else if (pageSettings.rotation === 1) {
      [x1d, x2d, y1d, y2d] = [os.t, -os.b, os.l, -os.r];
    } else if (pageSettings.rotation === 2) {
      [x1d, x2d, y1d, y2d] = [-os.l, os.r, -os.b, os.t];
    } else if (pageSettings.rotation === 3) {
      [x1d, x2d, y1d, y2d] = [-os.t, os.b, os.r, -os.l];
    }

    // console.log(os, { x1d, x2d, y1d, y2d });

    x1 = x1 + x1d;
    x2 = x2 + x2d;
    y1 = y1 + y1d;
    y2 = y2 + y2d;

    const newRect = this.coordinatesToAnnnotationRect({ x1, y1, x2, y2 }, pageSettings, stroke + 1);

    // force new rectangle to fit into the old one
    this.fitInto(newRect, annotRect);
    annotation.setRect(newRect);

    console.log({
      stroke,
      calc,
      scaleMultiplier,
      pageSettings,
      annotWidth,
      annotHeight,
    });
  }

  convertCoords([x1, x2, y1, y2]: [number, number, number, number], rotation: Rotation): [number, number, number, number] {
    if (rotation === 0) {
      return [x1, x2, y1, y2];
    } else if (rotation === 1) {
      return [x1, x2, y2, y1];
    } else if (rotation === 2) {
      return [x2, x1, y1, y2];
    } else if (rotation === 3) {
      return [x2, x1, y2, y1];
    }
  }

  annotationRectToCoordinates({ x1, x2, y1, y2 }: CoreControls.Math.Rect, { rotation, width, height }: { rotation: Rotation, width: number, height: number }) {
    [x1, x2, y1, y2] = this.convertCoords([x1, x2, y1, y2], rotation);

    const subDimension = (rotation === 0 || rotation === 2) ? height : width;
    y1 = Big(subDimension).sub(y1).num();
    y2 = Big(subDimension).sub(y2).num();

    // if (rotation === 0) {
    //   [x1, x2, y1, y2] = [x1, x2, Big(height).sub(y1).num(), Big(height).sub(y2).num()];
    // } else if (rotation === 1) {
    //   [x1, x2, y2, y1] = [x1, x2, Big(width).sub(y1).num(), Big(width).sub(y2).num()];
    // } else if (rotation === 2) {
    //   [x2, x1, y1, y2] = [x1, x2, Big(height).sub(y1).num(), Big(height).sub(y2).num()];
    // } else if (rotation === 3) {
    //   [x2, x1, y2, y1] = [x1, x2, Big(width).sub(y1).num(), Big(width).sub(y2).num()];
    // }

    return { x1, y1, x2, y2 };
  }

  fitInto(newRect: CoreControls.Math.Rect, fitRect: CoreControls.Math.Rect) {
    newRect.x1 = Math.max(newRect.x1, fitRect.x1);
    newRect.y1 = Math.max(newRect.y1, fitRect.y1);
    newRect.x2 = Math.min(newRect.x2, fitRect.x2);
    newRect.y2 = Math.min(newRect.y2, fitRect.y2);
  }

  coordinatesToAnnnotationRect(coords: { x1: number, x2: number, y1: number, y2: number }, pageSettings: { rotation: Rotation, width: number, height: number }, padding = 0): CoreControls.Math.Rect {
    let { x1, y1, x2, y2 } = this.annotationRectToCoordinates(coords as CoreControls.Math.Rect, pageSettings);
    return new this.instance.CoreControls.Math.Rect(x1 - padding, y1 - padding, x2 + padding, y2 + padding);
  }

  // // https://en.wikipedia.org/wiki/Rotation_matrix
  // rotateCoordinates(x: number, y: number, rotation: Rotation) {
  //   let arr = [0, 1, 0, -1];
  //   let sinA = arr[rotation % 4];
  //   let cosA = arr[(rotation + 1) % 4];

  //   const xRot = Big(x * cosA).add(- y * sinA).num();
  //   const yRot = Big(x * sinA).add(y * cosA).num();
  //   return [xRot, yRot];
  // }

  round(num: number, decimalPlaces: number): number {
    if (typeof num !== 'number') return num;
    return Math.round(num * (10 ** decimalPlaces)) / (10 ** decimalPlaces);
  }

  static async create(element: HTMLElement) {
    const instance = await WebViewer({
      path: '../lib',
      preloadWorker: 'pdf',
      licenseKey: LICENSE_KEY,
      disableLogs: true,
      backendType: 'wasm-threads',
      fullAPI: true
    }, element);

    await instance.PDFNet.initialize();

    return new Viewer(instance);
  }


  async export(): Promise<Export> {
    const fileName = this.instance.docViewer.getDocument().getFilename();
    const pageObject =  await this.getPageObject();
    const imageSize = 1000;
    const { pageReference, pageDimensions } = pageObject;


    const pdfdraw = await this.createPdfDraw();
    const { scaledW, scaledH, scaleMultiplier } = scaleToFit(pageDimensions, imageSize);

    // PAGE IMAGES
    const pageOptions: RasterizePageOptions = {
      annotations: true,
      transparent: false,
      scaledW,
      scaledH,
      pageObject,
      pdfdraw
    };
    const cleanPageOptions: RasterizePageOptions = {
      ...pageOptions,
      annotations: false,
      transparent: true
    };

    const image = await this.getRasterizedPage(pageOptions);
    const imageClean = await this.getRasterizedPage(cleanPageOptions);


    // const image = await this.getPageBitmapAsBuffer({ pageRef, pageDims });
    // const hires = await calculatePerformance(() => this.getPageBitmapAsBuffer({ pageRef, pageDims, dpi, annotations: false }));


    const thumb = await createThumb(image, 120);

    console.log({
      image: window.URL.createObjectURL(image),
      imageClean: window.URL.createObjectURL(imageClean),
      thumb: window.URL.createObjectURL(thumb)
    });

    return;

    return {
      image,
      hires: imageClean,
      thumb,
      pdf: pageObject.blob,
      xfdf: new Blob([pageObject.xfdf], { type: 'application/vnd.adobe.xfdf' }),
      page: pageObject.page,
      fileName,
      // isOverwrite: this.WVPanel.isOverwrite,
      isOverwrite: false,
      annotationArray: [],
      hiresAnnotations: [],
      rotation: pageDimensions.rotation,
      scaleRatio: scaleMultiplier
    };
  }


  async getCoverPageImages(): Promise<{ image: Blob; thumb: Blob; }> {
    const pageObject =  await this.getPageObject();
    const imageSize = 1000;
    const { scaledW, scaledH } = scaleToFit(pageObject.pageDimensions, imageSize);
    const image = await this.getRasterizedPage({ pageObject, scaledW, scaledH, annotations: false });
    const thumb = await createThumb(image, 200);

    return { image, thumb };
  }

  async createPdfDraw({ cache = true, smooth = true, antiAlias = true } = {}): Promise<PDFNet.PDFDraw> {
    const pdfdraw = await this.instance.PDFNet.PDFDraw.create();
    pdfdraw.setCaching(cache);
    pdfdraw.setImageSmoothing(smooth, smooth);
    pdfdraw.setAntiAliasing(antiAlias);
    return pdfdraw;
  }

  async getRasterizedPage({
    pageObject,
    scaledW,
    scaledH,
    pdfdraw,
    transparent = false,
    annotations = true,
  }: RasterizePageOptions): Promise<Blob> {
    pdfdraw = pdfdraw || await this.createPdfDraw();

    pdfdraw.setImageSize(scaledW, scaledH);
    pdfdraw.setDrawAnnotations(annotations);
    pdfdraw.setPageTransparent(transparent);

    const typedArray: Uint8Array = await (pdfdraw as any).exportStream(pageObject.pageReference, 'PNG');
    return new Blob([typedArray.buffer], { type: 'image/png' });
  }

  // experimental: don't use
  // todo: try running multiple pdfnet instances, runing in web workers
  // todo: add rotation support
  // todo: concat chunks together
  async getPageBitmapAsBufferChunked({
    pageRef,
    pageDims,
    annotations = true,
    dpi = 92,
    parts = 2
  }: {
    pageRef: PDFNet.Page;
    pageDims: PageDimensions;
    rotate?: boolean;
    annotations?: boolean;
    dpi?: number;
    parts?: number
  }): Promise<Uint8Array> {
    const { width, height, rotation } = pageDims;
    const { PDFDraw, Rect, PDFDoc } = this.instance.PDFNet;
    const size = height / parts;

    // const sourceDoc = await this.instance.docViewer.getDocument().getPDFDoc();

    // const newPages = await Promise.all(Array.from(Array(num).keys()).map(async () => {
    //   const newDoc = await PDFDoc.create();
    //   await newDoc.insertPages(1, sourceDoc, 1, 1, 0);
    //   return newDoc.getPage(1);
    // }));

    // exports stream faster by sacrificing file size
    // this.instance.PDFNet.setDefaultFlateCompressionLevel(0);

    const exportRectangle = async (page: PDFNet.Page, x1: number, y1: number, x2: number, y2: number) => {
      const pdfdraw = await PDFDraw.create(dpi);
      const clipRect = new Rect(x1, y1, x2, y2);

      pdfdraw.setClipRect(clipRect);

      pdfdraw.setCaching(true);
      pdfdraw.setDrawAnnotations(annotations);
      pdfdraw.setPageTransparent(true);
      pdfdraw.setImageSmoothing(true, true);
      pdfdraw.setAntiAliasing(true);
      return (pdfdraw as any).exportStream(page, 'PNG') as Promise<Uint8Array>;
    };

    const chunkPromises = Array.from(Array(parts).keys()).map(i => {
      return exportRectangle(pageRef, 0, size * i, width, size * (i + 1));
    });
    const chunks = await Promise.all(chunkPromises);
    return chunks[0];
  }

  async insertPage(page: Blob, index: number) {
    const doc = this.instance.docViewer.getDocument();
    const docToInsert = await this.instance.CoreControls.createDocument(page);
    await this.adjustOCR(docToInsert);
    await doc.insertPages(docToInsert, [1], index);
    await doc.removePages([index + 1]);
    doc.refreshTextData();
    this.instance.docViewer.setCurrentPage(index);
  }

  async createPageCopy({ pageNumber = null, includeAnnotations = true } = <{ pageNumber?: number; includeAnnotations?: boolean; }>{}) {
    const docViewer = this.instance.docViewer;
    const doc = docViewer.getDocument();
    pageNumber = pageNumber || docViewer.getCurrentPage();

    const pdfDoc = await doc.getPDFDoc();
    const newPDFDoc = await this.instance.PDFNet.PDFDoc.create();
    const fullPage = await pdfDoc.getPage(pageNumber);

    newPDFDoc.pagePushBack(fullPage);

    const cleanPage = await removePageAnnotations(await newPDFDoc.getPage(1));
    const annotManager = docViewer.getAnnotationManager();
    const annotationsList = annotManager.getAnnotationsList();

    // export xfdf for all current page annotations
    const xfdf = await annotManager.exportAnnotations({
      annotList: annotationsList.filter(annot => annot.getPageNumber() === pageNumber),
      widgets: false,
      links: false,
      fields: false
    });

    // put back annotations
    if (includeAnnotations) {
      const fdfDoc = await this.instance.PDFNet.FDFDoc.createFromXFDF(xfdf)
      newPDFDoc.fdfMerge(fdfDoc);
    }

    const typedArray = await newPDFDoc.saveMemoryBuffer(this.instance.PDFNet.SDFDoc.SaveOptions.e_incremental);
    return {
      number: pageNumber,
      page: cleanPage,
      xfdf,
      blob: new Blob([typedArray.buffer], { type: 'application/pdf' })
    };
  }

  // todo: don't include tiny annotations in a proper way
  getPageAnnotations({
    callout = true,
    nonCallout = true,
    page
  } = <{ callout?: boolean; nonCallout?: boolean; page: number }>{}): Annotations.Annotation[] {
    const docViewer = this.instance.docViewer;
    const annotManager = docViewer.getAnnotationManager();

    return annotManager.getAnnotationsList().filter(annotation => {
      const { pageNumber, isCallout } = this.getAnnotationDetails(annotation);
      const isOnCurrentPage = pageNumber === page;
      const isNonCallout = !isCallout;
      return annotation.Listable && isOnCurrentPage && ((callout && isCallout) || (nonCallout && isNonCallout));
    });
  }

  getAnnotationDetails(annotation: Annotations.Annotation): {
    isCallout: boolean,
    pageNumber: number
  } {
    const stroke = (annotation as any).StrokeColor?.toHexString();
    const hasRedBorder = ['#FF0000', '#E44234', '#E52237'].includes(stroke);
    const isAllowedShape = ['Rectangle', 'Ellipse', 'Polygon'].indexOf(annotation.Subject) !== -1;

    return { isCallout: hasRedBorder && isAllowedShape, pageNumber: annotation.getPageNumber() };
  }

  async getPageObject(pageNumber?: number): Promise<PageObject> {
    const docViewer = this.instance.docViewer;
    const doc = docViewer.getDocument();
    const annotManager = docViewer.getAnnotationManager();
    pageNumber = pageNumber || docViewer.getCurrentPage();

    const callouts = this.getPageAnnotations({ nonCallout: false, page: pageNumber }) as Annotations.MarkupAnnotation[];

    const pageMatrix = doc.getPageMatrix(pageNumber);
    const { blob, page, xfdf } = await this.createPageCopy({ pageNumber });

    return {
      blob,
      page: pageNumber,
      pageReference: page,
      pageMatrix,
      xfdf,
      pageDimensions: await generatePageDimensions(page),
      callouts
    };
  }

}
