<p-tree
  [value]="tree$ | async"
  [draggableNodes]="!filtered"
  [droppableNodes]="!filtered"
  selectionMode="single"
  [(selection)]="selectedNode"
  [validateDrop]="true"
  (onNodeSelect)="onSelect($event)"
  (onNodeDrop)="onDrop($event)"
  (onNodeExpand)="onExpand($event)"
  (onNodeCollapse)="onCollapse($event)"
  [nodeTrackBy]="trackByFn"
  [templates]="this.templates"
></p-tree>
