type RecursivePartial<T> = {
  [P in keyof T]?:
    T[P] extends (infer U)[] ? RecursivePartial<U>[] :
    T[P] extends object ? RecursivePartial<T[P]> :
    T[P];
};

export function createProject(params: RecursivePartial<Project>) {
  return {
    id: params.id,
    metadata: {
      name: params.metadata.name,
      description: '',
      image: ''
    },
    organization: params.organization,
    members: {}
  } as Project;
}

export interface TreeEntry {
  id: string;
  name: string;
  children: string[];
}

export interface Project {
  id: string;
  metadata: {
    name: string;
    description: string;
    image: string;
  };
  organization: string;
  members: Record<string, Member>;
}

export interface Member {
  id: string;
  role: string;
}
