import { Injectable } from '@angular/core';
import { TreeEntry } from './tree.firestore';
import { EntityState, ActiveState, EntityStore, StoreConfig, EntityUIStore } from '@datorama/akita';

export type TreeNodeUIState = { expanded: boolean; };

export interface TreeUIState extends EntityUIStore<TreeNodeUIState> {
  filterFavorites: boolean;
}

export interface TreeState extends EntityState<TreeEntry, string>, ActiveState<string> {
  ui: TreeUIState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tree', resettable: true })
export class TreeStore extends EntityStore<TreeState> {

  ui: EntityUIStore<TreeUIState>;

  constructor() {
    super();
    this.createUIStore({
      filterFavorites: false
    }).setInitialEntityState({ expanded: false });
  }

}

