import { cloneDeep } from 'lodash-es';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Store } from '@datorama/akita';
import { FormGroup } from '@angular/forms';
import SparkMD5 from 'spark-md5';

export type TreeStructureNode<S> = {
  [K in keyof S]: S[K];
} & {
  children?: TreeStructureNode<S>[];
}

export function traverseTree<K extends TreeStructureNode<any>>(nodes: K[], callback: (node: K, key: number, parent: K) => void, parent: K = null): void {
    nodes && nodes.length && nodes.forEach((node, key) => {
        callback(node, key, parent);
        if (hasChild(node)) traverseTree(node.children, callback, node);
    });
}
export const hasChild = (node: TreeStructureNode<any>) => !!node.children && node.children.length > 0;

export function recursiveMap<S extends { children?: string[] }>(id: string, elMap: Record<string, S>) {
  let el = elMap[id];
  if (!el.children || !el.children.length) return el;

  return {
    ...el,
    children: el.children.map(childId => recursiveMap(childId, elMap))
  };
}

export function waitForChangeAndReset<T>(store: Store): MonoTypeOperatorFunction<T> {
  return pipe(
    filter(value => !!value),
    distinctUntilChanged((prev: T, curr: T) => {
      if (prev !== curr) store.reset();
      return prev === curr;
    })
  );
}

type CreateChangeEntry<S> = {
  action: 'create';
  value: Partial<S>;
  id?: string;
};
type UpdateChangeEntry<S> = {
  action: 'update';
  value: Partial<S>;
  id: string;
};
type DeleteChangeEntry<S> = {
  action: 'delete';
  id: string;
};

export type ChangeEntry<S> = CreateChangeEntry<S> | UpdateChangeEntry<S> | DeleteChangeEntry<S>;
type ActionType = ChangeEntry<any>['action'];

export class ChangeManager<S extends { id: string; [key: string]: any; }> {
  private changes: ChangeEntry<S>[] = [];

  create(value: CreateChangeEntry<S>['value']): this {
    let change: CreateChangeEntry<S> = {
      action: 'create',
      value: cloneDeep(value)
    };
    if (value.id) change.id = value.id;
    this.changes.push(change);
    return this;
  }
  update(id: string, value: UpdateChangeEntry<S>['value']): this {
    let change: UpdateChangeEntry<S> = {
      action: 'update',
      value: cloneDeep(value),
      id
    };
    this.changes.push(change);
    return this;
  }
  delete(id: string): this {
    let change: DeleteChangeEntry<S> = { action: 'delete', id };
    this.changes.push(change);
    return this;
  }
  get<T extends Extract<ChangeEntry<S>, { action: K }>[], K extends ActionType = any>(action?: K): T {
    if (action) return [...this.changes].filter(change => change.action === action) as T;
    return [...this.changes] as T;
  }
}

export function getDirtyValues(form: FormGroup) {
  const dirtyValues = {};
  Object.keys(form.controls).forEach(key => {
    const currentControl = form.controls[key];
    if (currentControl.dirty) {
      dirtyValues[key] = currentControl['controls'] ? getDirtyValues(currentControl as FormGroup) : currentControl.value;
    }
  });
  return dirtyValues;
}

export function getMD5Hash(file: Blob): Promise<string> {
  let spark = new SparkMD5.ArrayBuffer();
  let fileReader = new FileReader();

  // todo: read file in chunks for faster md5 calculation
  let chunkSize = 2097152; // Read in chunks of 2MB
  let chunks = Math.ceil(file.size / chunkSize);
  let currentChunk = 0;

  return new Promise((resolve, reject) => {
    fileReader.onload = (e) => {
      let arrayBuffer = e.target['result'];
      spark.append(arrayBuffer);
      resolve(spark.end());
    };

    fileReader.onerror = (e) => {
      reject(e.target['error']);
    };

    fileReader.readAsArrayBuffer(file.slice());
  });
}

export async function getBase64Hash(file: Blob): Promise<string> {
  let hash = await getMD5Hash(file);
  return window.btoa(hash);
}
