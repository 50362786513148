import { Injectable } from '@angular/core';
import { EntityState, ActiveState, EntityStore, StoreConfig, EntityUIStore } from '@datorama/akita';
import { ContentEntry } from './content.model';

export type ContentEntryUIState = { expanded: boolean; };

export interface ContentUIState extends EntityUIStore<ContentEntryUIState> {}

export interface ContentState extends EntityState<ContentEntry, string>, ActiveState<string> {
  ui: ContentUIState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'formContent', resettable: true })
export class ContentStore extends EntityStore<ContentState> {

  ui: EntityUIStore<ContentUIState>;

  constructor() {
    super();
    this.createUIStore().setInitialEntityState({});
  }

}

