<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p>{{ data.description }}</p>
  <mat-form-field>
    <input matInput [(ngModel)]="name">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Cancel</button>
  <button mat-button (click)="close()" [disabled]="!name">Create</button>
</div>
