import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { ContentStore, ContentState } from './content.store';
import { ContentQuery } from './content.query';
import { ContentEntry } from './content.model';
import { CollectionConfig, CollectionService, WriteOptions, syncWithRouter } from 'akita-ng-fire';
import { ChangeManager } from '@common/helpers';
import { ProjectQuery } from '@app/_state/project';
import { SlideService, SlideQuery } from '../slide';
import { TreeService } from '../tree';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'projects/:id/slides/:slideId/contentData' })
export class ContentService extends CollectionService<ContentState> {

  constructor(
    store: ContentStore,
    protected query: ContentQuery,
    protected routerQuery: RouterQuery,
    protected projectQuery: ProjectQuery,
    protected slideQuery: SlideQuery,
    protected projectTreeService: TreeService
  ) {
    super(store);
  }

  // sync = this.slideService.valueChanges(this.slideService.getRef().where({ selected}))
  // sync = syncWithRouter.bind(this, this.routerQuery);

  get currentPath() {
    const { id } = this.routerQuery.getParams<string>();
    const slideId = this.slideQuery.getActiveId();

    if (!id) throw '[content.service] Path is not valid';
    if (!slideId) throw '[content.service] No selected slide found';
    return this.getPath({ params: { id, slideId } });
  }

}
