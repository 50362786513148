import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import firebase from 'firebase/app';
import { TreeStore, TreeState } from './tree.store';
import { TreeQuery } from './tree.query';
import { createTreeEntry, createRootTreeEntry, TreeEntry } from './tree.firestore';
import { CollectionConfig, CollectionService, WriteOptions, syncWithRouter } from 'akita-ng-fire';
import { TreeEntry as OrgTreeEntry } from '../organization/organization.model';
import { ChangeManager } from '@common/helpers';
import { ProjectService  } from '@app/_state/project';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'organizations/:id/tree' })
export class TreeService extends CollectionService<TreeState> {

  constructor(
    store: TreeStore,
    protected query: TreeQuery,
    protected routerQuery: RouterQuery,
    protected projectService: ProjectService
  ) {
    super(store);
  }

  sync = syncWithRouter.bind(this, this.routerQuery);

  get currentPath() {
    const { id } = this.routerQuery.getParams<string>();
    if (!id) throw '[tree.service] Path is not valid';
    return this.getPath({ params: { id } });
  }

  async createTreeEntry(type: OrgTreeEntry['type'], name: string, parentId: string) {
    const parentNode = this.query.getEntity(parentId);
    if (type !== 'client' && !parentNode) {
      throw 'Parent node not found.';
    }

    const id = this.db.createId();
    const entry = createTreeEntry({ id, name, type });
    const changes = new ChangeManager<TreeEntry>();
    changes.create(entry);

    if (type === 'client') {
      const rootChildren = this.store.getValue().entities['root'].children;
      changes.update('root', { children: [...rootChildren, id] });
    } else {
      changes.update(parentNode.id, { children: [...parentNode.children, id] });
    }

    return this.applyChanges(changes).then(() => id);
  }

  async applyChanges(changes: ChangeManager<TreeEntry>) {
    const options: WriteOptions = { write: this.db.firestore.batch() };
    const addDocs = changes.get('create').map(change => {
      if (change.value.type === 'root') return createRootTreeEntry(change.value);
      return createTreeEntry(change.value);
    });
    const updateDocs = changes.get('update').map(change => ({ id: change.id, ...change.value }));
    const removeDocs = changes.get('delete').map(change => change.id);

    await Promise.all([
      this.add(addDocs, options),
      this.update(updateDocs, options),
      this.remove(removeDocs, options)
    ])
    return (options.write as firebase.firestore.WriteBatch).commit();
  }

  setFilterFavorites(value: boolean) {
    this.store.ui.update({ filterFavorites: value });
  }

  setNodeExpanded(id: string, value: boolean) {
    this.store.ui.update(id, { expanded: value });
  }

  createRootTreeEntry(options?: WriteOptions) {
    const entry = createRootTreeEntry({ id: 'root' });
    return this.add(entry, options);
  }

  protected onCreate(entity: OrgTreeEntry, options: WriteOptions) {
    const { id } = this.routerQuery.getParams<string>();
    if (entity.type === 'project') return this.projectService.create(entity.name, entity.id, id, options);
  }

  protected onDelete(id: string, options: WriteOptions) {
    // todo: move project delete to server
    const entity = this.query.getEntity(id) || options.ctx;
    if (entity.type === 'project') {
      return this.projectService.remove(id, options);
    }
  }

  async removeAll(options: WriteOptions = {}) {
    const { write = this.db.firestore.batch() } = options;
    const path = this.getPath(options);
    const snapshot = await this.db.collection(path).ref.get();
    const promises = snapshot.docs.map(doc => this.remove(doc.id, { ...options, write, ctx: doc.data() }));
    await Promise.all(promises);
    if (!options.write) {
      return (write as firebase.firestore.WriteBatch).commit();
    }
  }

}
