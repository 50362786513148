import { Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TreeService, TreeQuery, TreeStore } from '../_state/tree';
import { PopoverRef } from '@app/popover/popover-ref';
import { ChangeManager } from '@app/_common/helpers';

@UntilDestroy()
@Component({
  selector: 'tree-node-tools',
  templateUrl: './tree-node-tools.component.html',
  styleUrls: ['./tree-node-tools.component.scss']
})
export class TreeNodeToolsComponent {
  node;

  constructor(
    private popoverRef: PopoverRef,
    private treeService: TreeService,
    private treeStore: TreeStore,
    private treeQuery: TreeQuery
  ) {
    this.node = this.popoverRef.data.node;
  }

  close() {
    this.popoverRef.close();
  }

  deleteNode() {
    this.treeService.deleteNode(this.node.data.id, this.node.parent.data.id);
    this.close();
  }

  exdentNode() {
    const node = this.node.data;
    const parent = this.node.parent.data;
    const grandParent = this.node.parent.parent?.data;

    if (!grandParent) return;

    const grandParentChildren = [...grandParent.children];
    const parentIndex = grandParentChildren.indexOf(parent.id);
    grandParentChildren.splice(parentIndex + 1, 0, node.id);

    const parentChildren = [...parent.children];
    const index = parentChildren.indexOf(node.id);
    parentChildren.splice(index, 1);

    const changes = new ChangeManager();
    changes.update(grandParent.id, { children: grandParentChildren });
    changes.update(parent.id, { children: parentChildren });

    this.treeService.applyChanges(changes);
    this.close();
  }

  indentNode() {
    // let node = this.get(nodeId);
    // if (node.order === 0) return;
    // let parent = this.get(node.parent);
    // let parentList = parent ? parent.nodes : this.Tree.getActiveTree();
    // let previousNode = parentList[node.order - 1];
    // this.moveInTree(nodeId, previousNode.id, previousNode.nodes.length);

    const node = this.node.data;

    const parent = this.node.parent.data;

    const index = parent.children.indexOf(node.id);
    if (index === 0) return;

    const parentChildren = [...parent.children];
    parentChildren.splice(index, 1);

    const previousNode = this.node.parent.children[index - 1].data;
    const previousNodeChildren = [...previousNode.children];
    previousNodeChildren.push(node.id);

    const changes = new ChangeManager();
    changes.update(parent.id, { children: parentChildren });
    changes.update(previousNode.id, { children: previousNodeChildren });

    this.treeService.applyChanges(changes);
    this.close();
  }

  changeNodeType() {
    let { id, type } = this.node.data;

    switch (type) {
      case 'group':
        type = 'form';
        break;
      case 'form':
        type = 'folder';
        break;
      case 'folder':
        type = 'group';
        break;
      default:
        type = 'form';
        break;
    }

    console.log('changeNodeType', id, type);

    this.treeService.update(id, { type });
    this.close();
  }

}

