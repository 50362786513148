import { Injectable } from '@angular/core';
import { QueryEntity, EntityUIQuery, combineQueries, HashMap } from '@datorama/akita';
import { TreeStore, TreeState, TreeUIState, TreeNodeUIState } from './tree.store';
import { TreeEntry, ExtendedTreeEntry } from './tree.firestore';
import { FavoriteType, FavoritesQuery } from '@app/_state/favorites';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type EntryMap = Record<string, { value: TreeEntry, parent: any }>;

@Injectable({ providedIn: 'root' })
export class TreeQuery extends QueryEntity<TreeState> {

  ui: EntityUIQuery<TreeUIState>;

  constructor(
    protected store: TreeStore,
    protected favoritesQuery: FavoritesQuery
  ) {
    super(store);
    this.createUIQuery();
  }

  selectTree() {
    return combineQueries([
      this.selectAll(),
      this.ui.select('filterFavorites'),
      this.ui.selectAll({ asObject: true }) as Observable<HashMap<TreeNodeUIState>>,
      this.favoritesQuery.select(FavoriteType.Projects)
    ]).pipe(
      map(([tree, filter, metadata, favorites]) => {
        if (!filter) return tree.map(entry => {
          return {
            ...entry,
            _meta: { expanded: Boolean(metadata[entry.id]?.expanded), favorite: Boolean(favorites[entry.id]) }
          }
        });

        const entryMap: EntryMap = {};
        const filteredProjects: ExtendedTreeEntry[] = [];

        tree.filter(entry => {
          entryMap[entry.id] = { ...entryMap[entry.id], value: entry };

          entry.children?.forEach(childId => {
            entryMap[childId] = { ...entryMap[childId], parent: entryMap[entry.id] };
          });

          return (entry.type === 'project' && favorites[entry.id]) || entry.type === 'root';
        }).forEach(entry => {
          let el = entryMap[entry.id];

          while (el) {
            filteredProjects.push({
              ...el.value,
              _meta: { expanded: metadata[el.value.id].expanded, favorite: true }
            });
            el = el.parent;
          }
        });

        return filteredProjects;
      })
    );
  }

  getRoot() {
    return this.store.getValue().entities['root'];
  }

}
