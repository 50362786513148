export type EntryType = 'form' | 'group' | 'folder';


// export type TreeEntry = {
//   id: string;
//   name: string;
//   type: EntryType;
//   children: string[];
// };

// export type ExtendedTreeEntry = TreeEntry & { _meta: Record<string, any> };


type Entry = {
  id: string;
  name: string;
  type: EntryType;
  metadata?: any;
  children?: string[];
};

type RootEntry = {
  id: string;
  type: 'root';
  children: string[];
};

type EntryMap = {
  id: string;
  name: string;
  metadata?: any;
  children: EntryMap[];
};

export type TreeEntry = Entry | RootEntry;

export type ExtendedTreeEntry = TreeEntry & { _meta: Record<string, any> };

export function createTreeEntry(params: Partial<Entry>) {
  return {
    ...params,
    children: params.children || [],
    metadata: params.metadata || {}
  } as Omit<Entry, 'id'> & { id?: string };
}

export function createRootTreeEntry(params: Partial<RootEntry>) {
  return {
    ...params,
    type: 'root',
    children: params.children || []
  } as Omit<RootEntry, 'id'> & { id?: string };
}

export function isRootEntry(entry: Omit<TreeEntry, 'id'> & { id?: string }): entry is RootEntry {
  return entry.type === 'root';
}
