import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { SlideStore, SlideState } from './slide.store';
import { SlideQuery } from './slide.query';
import { SlideEntry, createDefaultSlide } from './slide.model';
import { CollectionConfig, CollectionService, WriteOptions, syncWithRouter } from 'akita-ng-fire';
import { ChangeManager } from '@common/helpers';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'projects/:id/slides' })
export class SlideService extends CollectionService<SlideState> {

  constructor(
    store: SlideStore,
    protected query: SlideQuery,
    protected routerQuery: RouterQuery
  ) {
    super(store);
  }

  // sync = syncWithRouter.bind(this, this.routerQuery);

  get currentPath() {
    const { id } = this.routerQuery.getParams<string>();
    if (!id) throw '[tree.service] Path is not valid';
    return this.getPath({ params: { id } });
  }

  createFormSlide(formId: string, options?: WriteOptions) {
    const id = this.db.createId();
    const entry = createDefaultSlide(id, formId);
    return this.add(entry, options);
  }

}
