import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CreateDialogData {
  title: string;
  description: string;
}

@Component({
  selector: 'app-create-org-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss']
})
export class CreateDialogComponent {
  private _name: string;
  set name(value) {
    this._name = (value && value.trim()) || '';
  }
  get name() { return this._name; }

  constructor(
    public dialogRef: MatDialogRef<CreateDialogComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: CreateDialogData
  ) { }

  close() {
    this.dialogRef.close(this.name);
  }

}
