<app-tree
  [items]="$any(orgTree$)"
  (itemChange)="treeChanges($event)"
  (nodeSelect)="onSelect($event)"
  (nodeToggle)="setExpanded($event)"
  [filtered]="filterFavorites$ | async"
>
  <ng-template let-node pTemplate="folder">
    <mat-icon class="type-icon" [dragHandle]="node">folder</mat-icon>
    <span>{{ node.data.name }}</span>
  </ng-template>
  <ng-template let-node pTemplate="file">
    <mat-icon class="type-icon" [dragHandle]="node">description</mat-icon>
    <input value="{{ node.data.name }}">
    <fav-icon [favorite]="node.data._meta.favorite" (change)="setFavorite($event, node.data.id)"></fav-icon>
  </ng-template>
</app-tree>
