export type Point = {
  r: number;
  g: number;
  b: number;
  a: number;
  hex: string;
};
export type Color = {
  point: Point;
  count: number;
  pos: number[];
  usage?: number;
  distance?: number;
};

export class AutoCrop {
  private width: number;
  private height: number;
  private pixelArr: Uint8ClampedArray;
  private offset: { top: number, right: number, bottom: number, left: number };

  constructor(
    image: HTMLImageElement,
    private PADDING = 0,
    private USAGE_THRESHOLD = 99,
    private DISTANCE_THRESHOLD = 200
  ) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    [this.width, this.height] = [image.width, image.height];
    [canvas.width, canvas.height] = [image.width, image.height];

    ctx.drawImage(image, 0, 0);
    this.pixelArr = ctx.getImageData(0, 0, this.width, this.height).data;
  }

  calculate(cleanup = true) {
    if (this.pixelArr.length) {
      this.offset = this.getOffset();
    }

    const offset = this.offset;
    const width = this.width - offset.left - offset.right;
    const height = this.height - offset.top - offset.bottom;

    if (cleanup) {
      this.pixelArr = new Uint8ClampedArray();
    }

    return { offset, width, height };
  }

  private getOffset() {
    const topRow = this.iterateSide(0, this.height - 1, 'row');
    const bottomRow = this.iterateSide(this.height - 1, 0, 'row');
    const leftColumn = this.iterateSide(0, this.width - 1, 'column');
    const rightColumn = this.iterateSide(this.width - 1, 0, 'column');

    return {
      top: topRow - this.PADDING,
      bottom: this.height - bottomRow + this.PADDING,
      left: leftColumn - this.PADDING,
      right: this.width - rightColumn + this.PADDING
    };
  }

  private getPos(row: number, column: number): number {
    return (row * this.width + column) * 4;
  }

  private getColors(row: number, column: number, { logging = false, mergeNonVisible = false, mergeCloseColors = false } = {}) {
    const colors: Record<string, Color> = {};
    const limit = column === null ? this.width : this.height;
    for (let i = 0; i < limit; i ++) {
      let pos = column === null ? this.getPos(row, i) : this.getPos(i, column);
      let point = this.toPoint(pos);

      colors[point.hex] = colors[point.hex] || { point, count: 0, pos: [] } as Color;
      colors[point.hex].count++;
      colors[point.hex].pos.push([i, pos] as any);
    }

    const nonVisibleTotal = { count: 0, usage: 0 };

    const values = Object.values(colors);
    let maxColor: Color;
    values.forEach(color => {
      color.usage = 100 * color.count / limit;

      if (!maxColor) maxColor = color;
      else if (color.count > maxColor.count) maxColor = color;

      if (color.point.a === 0) {
        nonVisibleTotal.count += color.count;
        nonVisibleTotal.usage += color.usage;
      }
    });

    if (mergeNonVisible) {
      maxColor.count += nonVisibleTotal.count;
      maxColor.usage += nonVisibleTotal.usage;
    }

    if (mergeCloseColors) {
      values.forEach(color => {
        if (color === maxColor) return;
        color.distance = this.colorDistance(color.point, maxColor.point);
        if (color.distance < this.DISTANCE_THRESHOLD) {
          maxColor.count += color.count;
          maxColor.usage += color.usage;
        }
      });
    }

    logging && console.log(colors);

    return maxColor;
  }

  private iterateSide(from: number, to: number, type: 'row' | 'column', log = false) {
    let settings = { logging: log, mergeNonVisible: true, mergeCloseColors: true };
    let incr = from < to ? 1 : -1;

    for (let i = from; incr * i <= incr * to; i = i + incr) {
      let color = type === 'row' ? this.getColors(i, null, settings) : this.getColors(null, i, settings);
      // log && console.log(`${from} -> ${to}`,color.usage);
      if (color.usage <= this.USAGE_THRESHOLD) {
        return i - incr;
      }
    }

    return from;
  }

  private toPoint(pos: number): Point {
    let [r, g, b, a] = this.pixelArr.subarray(pos, pos + 4);
    return { r, g, b, a, hex: this.toHex(pos) }
  }

  // https://stackoverflow.com/questions/2103368/color-logic-algorithm
  // https://www.compuphase.com/cmetric.htm
  private colorDistance(e1, e2) {
    let rmean = (e1.r + e2.r) / 2;
    let r = e1.r - e2.r;
    let g = e1.g - e2.g;
    let b = e1.b - e2.b;
    return Math.sqrt((((512+rmean)*r*r)>>8) + 4*g*g + (((767-rmean)*b*b)>>8));
  }

  private toHex(pos: number) {
    let hex = '';
    for (let i = pos; i < pos + 4; i++) {
      hex += (this.pixelArr[i] | 1 << 8).toString(16).slice(1);
    }
    return hex;
  }
}
