import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { switchMap } from 'rxjs/operators';
import { OrganizationStore, OrganizationState } from './organization.store';
import { UserOrganizationsService } from '../../organizations/_state/organizations.service';

@Injectable({ providedIn: 'root' })
export class OrganizationQuery extends QueryEntity<OrganizationState> {

//   select$ = this.routerQuery.selectParams<string>('id').pipe(
//   switchMap(id => this.userOrganizationsService.select(id))
// );

  constructor(
    protected store: OrganizationStore,
    private routerQuery: RouterQuery,
    private userOrganizationsService: UserOrganizationsService
  ) {
    super(store);
  }

}
