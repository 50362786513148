import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { CreateDialogData, CreateDialogComponent } from './create-dialog.component';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CreateDialogService {
  constructor(
    private dialog: MatDialog
  ) {
  }

  open(data: CreateDialogData): Observable<string> {
    const dialogRef = this.dialog.open(CreateDialogComponent, { data });
    return dialogRef.afterClosed();
  }
}
