import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { ProjectStore, ProjectState } from './project.store';
import { createProject, TreeEntry, Project } from './project.model';
import { CollectionConfig, CollectionService, WriteOptions, syncWithRouter } from 'akita-ng-fire';
import { UserOrganizationsQuery } from 'src/app/organizations/_state/organizations.query';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AuthQuery } from '../../auth/auth.query';
import { TreeService as ProjectTreeService, createTreeEntry as createProjectTreeEntry, createRootTreeEntry as createProjectRootTreeEntry } from '@app/project/_state/tree';
import { ChangeManager } from '@common/helpers';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'projects' })
export class ProjectService extends CollectionService<ProjectState> {

  constructor(
    store: ProjectStore,
    protected authQuery: AuthQuery,
    protected routerQuery: RouterQuery,
    protected projectTreeService: ProjectTreeService
  ) {
    super(store);
  }

  // sync = syncWithRouter.bind(this, this.routerQuery);

  create(name: string, id: string, organization: string, options?: WriteOptions) {
    const project = createProject({
      id,
      metadata: { name },
      organization
    });

    return this.add(project, options);
  }

  addMember(projectId: string, userId: string, role: string = 'admin') {
    return this.update(projectId, {
      members: {
        [userId]: { id: userId, role }
      }
    });
  }

  // protected onDelete(id: string, options: WriteOptions) {
  //   const removeOptions = { ...options, params: { id } };
  //   return this.treeService.removeAll(removeOptions);
  // }

  protected onCreate(entity: Project, options: WriteOptions) {
    const creaeteOptions = { ...options, params: { id: entity.id } };

    const changes = new ChangeManager<TreeEntry>();
    const id = this.db.createId();
    const rootEntry = createProjectRootTreeEntry({ id: 'root', children: [id] });
    const entry = createProjectTreeEntry({ id, name: '', type: 'group' });

    changes.create(rootEntry);
    changes.create(entry);

    return this.projectTreeService.applyChanges(changes, creaeteOptions);
  }
}
