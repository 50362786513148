import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DropzoneService } from './dropzone.service';

export type DropzoneEvent = { error: Error, files: FileList };

@UntilDestroy()
@Component({
  selector: 'dropzone',
  template: `
			<mat-icon svgIcon="add_here_icon"></mat-icon>
			<ng-content></ng-content>
  `,
  styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent implements OnInit {

  @Output() onDrop = new EventEmitter<DropzoneEvent>();

  constructor(
    private el: ElementRef,
    private dropzone: DropzoneService
  ) { }

  ngOnInit() {
    this.dropzone.add({
      dropzone: this.el,
      fileType: ['application/pdf'],
      callback: (error, files) => this.onDrop.next({ error, files })
    });
  }

}
