import { Injectable } from '@angular/core';
import { EntityState, ActiveState, EntityStore, StoreConfig } from '@datorama/akita';
import { SlideEntry } from './slide.model';

export interface SlideState extends EntityState<SlideEntry, string>, ActiveState<string> {
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'slides', resettable: true })
export class SlideStore extends EntityStore<SlideState> {
  constructor() {
    super();
  }
}

