import { TreeEntry as OrgTreeEntry } from '../organization/organization.model';

type Entry = {
  id: string;
  name: string;
  type: OrgTreeEntry['type'];
  metadata?: any;
  children?: string[];
};

type RootEntry = {
  id: string;
  type: 'root';
  children: string[];
};

type EntryMap = {
  id: string;
  name: string;
  metadata?: any;
  children: EntryMap[];
};

export type TreeEntry = Entry | RootEntry;

export type ExtendedTreeEntry = TreeEntry & { _meta: Record<string, any> };

export function createTreeEntry(params: Partial<Entry>) {
  return {
    ...params,
    children: params.children || [],
    metadata: params.metadata || {}
  } as Omit<Entry, 'id'> & { id?: string };
}

export function createRootTreeEntry(params: Partial<RootEntry>) {
  return {
    ...params,
    type: 'root',
    children: params.children || []
  } as Omit<RootEntry, 'id'> & { id?: string };
}

export function isRootEntry(entry: Omit<TreeEntry, 'id'> & { id?: string }): entry is RootEntry {
  return entry.type === 'root';
}
