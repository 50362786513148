import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ProjectService, ProjectQuery } from '@app/_state/project';

import { TreeEntry } from '@app/_state/organization';
import { TreeQuery, TreeStore, TreeService } from '@app/_state/tree';
import { FavoritesService, FavoriteType } from '@app/_state/favorites';
import { filter, switchMap } from 'rxjs/operators';
import { AuthQuery } from '@app/auth/auth.query';

@UntilDestroy()
@Component({
  selector: 'project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnInit {

  constructor(
    private treeService: TreeService,
    private treeStore: TreeStore,
    private treeQuery: TreeQuery,
    private projectService: ProjectService,
    private projectQuery: ProjectQuery,
    private authQuery: AuthQuery
  ) { }

  uid$ = this.authQuery.select('uid');
  filterFavorites$ = this.treeQuery.ui.select('filterFavorites');
  project$ = this.treeQuery.selectActiveId().pipe(
    switchMap(id => this.projectQuery.selectEntity(id))
  );

  joinProject(projectId: string) {
    const { uid } = this.authQuery.getValue();
    this.projectService.addMember(projectId, uid);
  }

  ngOnInit() {
    this.treeService.sync()
      .pipe(untilDestroyed(this))
      .subscribe();

    this.treeQuery.selectActive()
      .pipe(
        filter(item => item?.type === 'project'),
        switchMap(project => this.projectService.syncDoc({ id: project.id })),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
