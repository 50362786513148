import { CroppedAnnotation, PageObject, Rotation, PageDimensions, Rect } from './types';
import { PDFNet, WebViewerInstance, Annotations, CoreControls } from '@pdftron/webviewer';
import { cloneDeep } from 'lodash-es';
import ImageBlobReduce from 'image-blob-reduce';
import { Big } from './big.extended';

// todo: can be optimized by drawing into canvas once, instead of calling clipCanvas for each annotation
export async function cropAnnotations(imageBlob: Blob, pageObject: PageObject, hideBorder?: boolean): Promise<CroppedAnnotation[]> {
  const image = await blobToImage(imageBlob);
  const xDelta = image.naturalWidth / pageObject.pageDimensions.width;
  const yDelta = image.naturalHeight / pageObject.pageDimensions.height;
  const blobArray: CroppedAnnotation[] = [];

  pageObject.callouts.forEach(annotation => {
    const rect = annotation.getRect();
    const sx = rect.x1 * xDelta;
    const sy = rect.y1 * yDelta;
    const w = rect.getWidth() * xDelta;
    const h = rect.getHeight() * yDelta;
    const annotObject = cloneDeep(annotation);

    if (hideBorder) annotObject.StrokeThickness = 0;

    const croppedImage = clipCanvas(image, annotObject, xDelta, yDelta, sx, sy, w, h, pageObject.pageDimensions.rotation, pageObject.pageMatrix);

    blobArray.push({
      id: annotation.Id,
      blob: croppedImage,
      rect: rect
    });
  });

  return blobArray;
}

export function clipCanvas(
  image: HTMLImageElement, object: Annotations.Annotation,
  xDelta: number, yDelta: number, sx: number, sy: number,
  w: number, h: number,
  rotation: Rotation,
  pageMatrix: any
): Blob {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const margin = 2;

  w += 2 * margin;
  h += 2 * margin;

  switch (rotation) {
    case 0: // no rotation
      [canvas.width, canvas.height] = [w, h];
      ctx.translate(margin - sx, margin - sy);
      break;
    case 1: // 90 deg rotation
      [canvas.width, canvas.height] = [h, w];
      ctx.translate(h + sy - margin, margin - sx);
      break;
    case 2: // 180 deg rotation
      [canvas.width, canvas.height] = [w, h];
      ctx.translate(w + sx - margin, h + sy - margin);
      break;
    case 3: // 270 deg rotation
      [canvas.width, canvas.height] = [h, w];
      ctx.translate(margin - sy, w + sx - margin);
      break;
  }

  ctx.rotate(rotation * 90 * Math.PI / 180);

  // draw annotation object on to canvas and use as clipping path
  ctx.scale(xDelta, yDelta);
  object.draw(ctx, pageMatrix);
  ctx.clip();
  ctx.scale(1 / xDelta, 1 / yDelta);

  ctx.drawImage(image, 0, 0);

  // draw annotation object again for cleaner crop border
  ctx.scale(xDelta, yDelta);
  object.draw(ctx, pageMatrix);
  ctx.scale(1 / xDelta, 1 / yDelta);

  return dataURItoBlob(canvas.toDataURL());
}

export function dataURItoBlob(dataURI: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs
  let byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  let blob = new Blob([ab], { type: mimeString });
  return blob;
}

export async function removePageAnnotations(page: PDFNet.Page): Promise<PDFNet.Page> {
  const numAnnots = await page.getNumAnnots();

  for (let i = 0; i < numAnnots; i++) {
    await page.annotRemoveByIndex(0);
  }

  return page;
}

export async function generatePageDimensions(page: PDFNet.Page): Promise<PageDimensions> {
  const [ pageBox, rotation ] = await Promise.all([
    page.getCropBox(),
    page.getRotation()
  ]);

  let width = await pageBox.width();
  let height = await pageBox.height();

  return { width, height, rotation };
}

export function hiresDpi(width: number, height: number): number {
  const maxSizeHires = 4000;
  const longestDim = Math.max(width, height);
  return Math.min(maxSizeHires / longestDim * 72, 300);
}

export async function rotateImage(blob: Blob, rotation: Rotation): Promise<Blob> {
  if (rotation === 0) return blob;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const image = await blobToImage(blob);
  const { width, height } = image;

  if (rotation === 2) {
    [canvas.width, canvas.height] = [width, height];
  } else {
    [canvas.width, canvas.height] = [height, width];
  }

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate(rotation * 90 * Math.PI / 180);

  ctx.drawImage(image, -width / 2, -height / 2);

  return canvasToBlob(canvas);

  // // save the unrotated context of the canvas so we can restore it later
  // // the alternative is to untranslate & unrotate after drawing
  // ctx.save();

  // // we’re done with the rotating so restore the unrotated context
  // ctx.restore();

}

export function blobToImage(blob: Blob): Promise<HTMLImageElement> {
  const image = document.createElement('img');
  const objectURL = window.URL.createObjectURL(blob);
  image.src = objectURL;

  return new Promise((resolve, reject) => {
    image.onload = () => {
      window.URL.revokeObjectURL(objectURL);
      resolve(image);
    };

    image.onerror = () => {
      window.URL.revokeObjectURL(objectURL);
      reject(new Error('Failed creating image instance.'));
    };
  });
}

export async function canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise(resolve => {
    canvas.toBlob(blob => resolve(blob));
  });
}

export async function createThumb(blob: Blob, maxSize: number, quality = 0.8): Promise<Blob> {
  const reducer = new ImageBlobReduce();
  reducer._create_blob = function (env) {
    return this.pica.toBlob(env.out_canvas, 'image/jpeg', quality)
      .then(function (blob) {
        env.out_blob = blob;
        return env;
      });
  };

  return reducer.toBlob(blob, { max: maxSize });
}

export async function calculatePerformance<T>(callback: () => T | Promise<T>, info?: string): Promise<T> {
  const t0 = performance.now();
  const res = await callback();
  const t1 = performance.now();

  info = info || callback.toString();
  console.log(`${info}: ${t1 - t0} ms`);
  return res;
}

export function isWithin(rect: Rect, parentRect: Rect): Boolean {
  return Boolean(
    parentRect.x1 < rect.x1 &&
    parentRect.y1 < rect.y1 &&
    parentRect.x2 > rect.x2 &&
    parentRect.x2 > rect.x2
  );
}

export function scaleToFit({ width, height, rotation }: PageDimensions, maxDimension: number) {
  const w = (rotation === 0 || rotation === 2) ? width : height;
  const h = (rotation === 0 || rotation === 2) ? height : width;

  const minSize = Math.min(
    Big(maxDimension).div(w).num(),
    Big(maxDimension).div(h).num()
  );
  const scaleMultiplier = Big(Math.round(50 * minSize)).div(50);
  return {
    scaleMultiplier: scaleMultiplier.num(),
    scaledW: scaleMultiplier.mul(w).num(),
    scaledH: scaleMultiplier.mul(h).num()
  };
}
