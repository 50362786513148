import { CoreControls, PDFNet, Annotations } from '@pdftron/webviewer';
import { UserStamp } from '@app/_state/pages';

export type Rotation = CoreControls.PageRotation;

export type PageObject = {
  pageReference: PDFNet.Page;
  pageDimensions: PageDimensions;
  pageMatrix: any;
  blob: Blob;
  xfdf: string;
  page: number;
  callouts: Annotations.MarkupAnnotation[];
};

export type   PageDimensions = {
  width: number;
  height: number;
  rotation: Rotation;
};

export type Export = {
  image: Blob;
  hires: Blob;
  thumb: Blob;
  pdf: Blob;
  xfdf: Blob;
  page: number;
  fileName: string;
  isOverwrite: boolean;
  annotationArray: CroppedAnnotation[];
  hiresAnnotations: CroppedAnnotation[];
  rotation: Rotation;
  scaleRatio: number;
};

export type Rect = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

export type CroppedAnnotation = {
  id: string;
  blob: Blob;
  rect: Rect;
};


export interface DocumentObj {
  [index: string]: any;
  // cites: { [citeId: string]: string };
  // headings: { [headingId: string]: string };
  // created: UserStamp;
  // md5: string;
  // fileName: string;
  // name: string;
  // url: string;
  // image: string;
  // thumb: string;
  // confidential: boolean;
  // hotness: HotnessValue;
  // forms: { [formId: string]: boolean };
  // tags: BaseTagMap;
  // fileType: string; // mime type
  // lastModified: number;
  // size: number;
};

export type HotnessValue = 0 | 1 | 2 | 3 | 4 | 5;

export interface PdfSaveOptions {
  action: 'uploadNew' | 'duplicate' | 'save' | 'saveAs';
  confidential: boolean;
  hotness: HotnessValue;
  // tags: BaseTag[];
  uploadOriginalDocument: boolean;
  willClose: boolean;
  originalDocument?: Blob;
  originalPage?: string;
}

export interface PageAnnotation {
  id: string;
  asterisk: boolean;
  cite: { enabled: boolean; value: string; };
  enabled: boolean;
  matchWidth: boolean;
  order: number;
  url: string;

  imageTransparent?: string;
  hires?: string;
  magnifyFrom?: string;
  index?: number; // for display, created dynamically
  page?: string; // id of source page
  rotation?: Rotation;
}

export type PageAnnotationsMap = { [annotationId: string] : PageAnnotation };

export type AssetHistoryType = { [historyItemId: string]: UserStamp };

export type RasterizePageOptions = {
  pdfdraw?: PDFNet.PDFDraw;
  pageObject: PageObject;
  scaledW: number;
  scaledH: number;
  annotations?: boolean;
  transparent?: boolean;
};

export enum MaskMode {
  reselect,
  add,
  substract
}
