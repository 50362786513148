import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewerWindow } from '@app/_common/services/viewerWindow.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Subject, ReplaySubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebViewerInstance, default as WebViewer, PDFNet } from '@pdftron/webviewer';
import { Viewer } from './viewer.class';
import { calculatePerformance } from './viewerHelpers';
import { PageUploadService } from './pageUpload.service';
import { PdfSaveOptions } from './types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpEvent, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';

type Tab = {
  name: string;
  url: string;
  blob: Blob;
  options?: {
    page: number;
    annotations: string;
  }
};

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'webviewer',
  templateUrl: './webviewer.component.html',
  styleUrls: ['./webviewer.component.scss']
})
export class WebviewerComponent {
  @ViewChild('viewer', { static: false }) viewerElement: ElementRef;

  viewer: Viewer;
  openTabs: Tab[] = [];
  activeTab: Tab;

  constructor(
    private cdr: ChangeDetectorRef,
    private viewerService: ViewerWindow,
    private pageUpload: PageUploadService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private zone: NgZone
  ) {
  }

  async ngAfterViewInit() {
    try {

      this.viewer = await Viewer.create(this.viewerElement.nativeElement);
    } catch (err) {
      console.log(err);
    }
    this.viewerService.ready();
  }

  ngOnInit() {
    this.viewerService.messages$.subscribe(async message => {
      if (message?.type === 'open') {

        let tabIndex = this.openTabs.findIndex(tab => tab.url === message.url);
        if (tabIndex === -1) {
          const response = await fetch(message.url);

          if (!response.ok) {
            const json = await response.json();

            // error handler runs outside of ngZone??
            return this.zone.run(() => {
              this._snackBar.open(json.error.message, 'Close', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top'
              });
            });
          }

          const newTab = {
            name: message.name,
            url: message.url,
            blob: await response.blob()
          };

          this.openTabs = [...this.openTabs, newTab];
          tabIndex = this.openTabs.length - 1;
        }

        await this.selectTab(tabIndex);
        this.cdr.detectChanges();
      }
    });
  }

  async selectTab(index: number) {
    if (index < 0 || this.activeTab === this.openTabs[index]) return;

    // save active page and annotations before switching tab
    if (this.activeTab) {
      const annotations = await this.viewer.instance.annotManager.exportAnnotations();
      const page = this.viewer.instance.docViewer.getCurrentPage();
      this.activeTab.options = { annotations, page };
    }

    this.activeTab = this.openTabs[index];

    this.viewer.instance.loadDocument(this.activeTab.blob, {
      extension: 'pdf'
    });

    // set options after new document is loaded
    if (this.activeTab.options?.page) {
      this.viewer.instance.docViewer.one('documentLoaded', () => {
        this.viewer.instance.docViewer.setCurrentPage(this.activeTab.options.page);
      });
    }

    // set annotations after inner annotations are loaded
    if (this.activeTab.options?.annotations) {
      this.viewer.instance.docViewer.one('annotationsLoaded', () => {
        this.viewer.instance.annotManager.importAnnotations(this.activeTab.options.annotations);
      });
    }
  }

  stopEvent($event: MouseEvent) {
    $event.stopPropagation();
  }

  onTabGroupClick($event: MouseEvent) {
    this.cdr.detectChanges();
  }

  closeTab($event: MouseEvent, tab: Tab) {
    $event.stopPropagation();

    const index = this.openTabs.indexOf(tab);
    this.openTabs.splice(index, 1);
    this.openTabs = [...this.openTabs];

    if (this.openTabs.length === 0) {
      this.activeTab = null;
      this.viewer.instance.closeDocument();
    } else if (this.activeTab === tab) {
        const newIndex = Math.max(index - 1, 0);
        this.selectTab(newIndex);
    }

    this.cdr.detectChanges();
  }

  async ocrPage($event): Promise<void> {
    try {
      const page = await this.viewer.createPageCopy({ includeAnnotations: false });
      this.uploadFile('https://ocr.dev.addhere.com/upload', page.blob).subscribe(res => {
        console.log('result', res);
        if (res.type === 4 && res instanceof HttpResponse && res.body.size) {
          this.viewer.insertPage(res.body, page.number).then(() => {
            this.viewer.instance.annotManager.importAnnotations(page.xfdf);
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  async downloadPage() {
    const doc = this.viewer.instance.docViewer.getDocument();
    const pdfDoc = await doc.getPDFDoc();
    const typedArray = await pdfDoc.saveMemoryBuffer(this.viewer.instance.PDFNet.SDFDoc.SaveOptions.e_incremental);
    const blob = new Blob([typedArray.buffer], { type: 'application/pdf' })
    this.saveBlob('test.pdf', blob);
  }

  saveBlob(fileName: string, blob: Blob) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  uploadFile(url: string, file: Blob): Observable<HttpEvent<Blob>> {

    let formData = new FormData();
    formData.append('pdf', file);

    // let params = new HttpParams();

    const options = {
      // params: params,
      // reportProgress: true,
      responseType: 'blob' as 'blob'
    };

    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req);
  }

  async savePage($event): Promise<void> {

    const pdfExport = await calculatePerformance(() => this.viewer.export());
    console.log(pdfExport);

    const saveOptions: PdfSaveOptions = {
      action: 'uploadNew',
      confidential: false,
      hotness: 5,
      // tags: BaseTag[];
      uploadOriginalDocument: false,
      willClose: false,
    }

    const page = await calculatePerformance(() => this.pageUpload.uploadBlob(pdfExport, saveOptions));
    console.log(page);

    // const ref = this.storage.ref('test/');
    // const task = ref.put(file);


    // const saveOptions: PdfSaveOptions = await this.Dialogs.openSavePageDialog($event);

    // this.WVPanel.setIsProcessing(true);

    // const pdfExport = await this.PdfService.export();

    // // if page is NOT being saved out of a document, include originalPage
    // if (!this.isDocument) saveOptions.originalPage = this.pageId;

    // saveOptions.originalDocument = this.currentPdf.file || null;

    // this.WVPanel.setOutput(pdfExport, saveOptions);
  }
}
