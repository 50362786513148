import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectTreeComponent } from './project-tree/project-tree.component';
import { ProjectDashboardComponent } from './dashboard/dashboard.component';
import { ProjectTreeInput } from './project-tree-input/project-tree-input.component';
import { TreeNodeToolsComponent } from './tree-node-tools/tree-node-tools.component';
import { ContentComponent } from './content/content.component';
import { SharedModule } from '../shared/shared.module';
import { ProjectRoutingModule } from './project-routing.module';

@NgModule({
  declarations: [
    ProjectDashboardComponent,
    ProjectTreeComponent,
    ProjectTreeInput,
    TreeNodeToolsComponent,
    ContentComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    // ProjectRoutingModule
  ]
})
export class ProjectModule { }
