<!-- <md-input-container md-no-float class="md-block" ng-class="{ 'md-input-focused': $ctrl.isFocused }">
	<label>{{ $ctrl.label }}</label>
	<input type="text" ng-model="$ctrl.ngModel" ng-show="false">
	<div
		medium-editor
		ng-model="$ctrl.ngModel"
		ng-model-options="{ debounce: 400 }"
		bind-options="$ctrl.options"
		class="md-input rich-input"
		ng-class="{ singleline: !$ctrl.multiline }"
		ng-change="$ctrl.onChange()"
	></div>
</md-input-container> -->

<!-- <mat-form-field [ngClass]="{ 'mat-focused': focused }"> -->
  <!-- <mat-label>{{ placeholder }}</mat-label> -->
  <!-- <input matInput [ngModel]="value" [hidden]=true> -->
<div #input class="rich-input mat-input-element" [ngClass]="{ 'multiline': multiline }"></div>
<mat-icon [hidden]="!focused && !voicePopupActive" class="mic-icon" (click)="startRecognition($event)">mic_none</mat-icon>
<!-- </mat-form-field> -->
