import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import firebase from 'firebase/app';
import { TreeStore, TreeState } from './tree.store';
import { TreeQuery } from './tree.query';
import { createTreeEntry, createRootTreeEntry, TreeEntry } from './tree.model';
import { CollectionConfig, CollectionService, WriteOptions, syncWithRouter } from 'akita-ng-fire';
import { ChangeManager } from '@common/helpers';
import { SlideService } from '../slide';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'projects/:id/tree' })
export class TreeService extends CollectionService<TreeState> {

  constructor(
    store: TreeStore,
    protected query: TreeQuery,
    protected slideService: SlideService,
    protected routerQuery: RouterQuery
  ) {
    super(store);
  }

  sync = syncWithRouter.bind(this, this.routerQuery);

  get currentPath() {
    const { id } = this.routerQuery.getParams<string>();
    if (!id) throw '[tree.service] Path is not valid';
    return this.getPath({ params: { id } });
  }

  async createTreeEntry(type, name: string, parentId: string) {
    const parentNode = this.query.getEntity(parentId);
    if (!parentNode) {
      throw 'Parent node not found.';
    }

    const id = this.db.createId();
    const entry = createTreeEntry({ id, name, type });
    const changes = new ChangeManager<TreeEntry>();

    changes.create(entry);
    changes.update(parentNode.id, { children: [...parentNode.children, id] });

    return this.applyChanges(changes).then(() => id);
  }

  async applyChanges(changes: ChangeManager<TreeEntry>, options: WriteOptions = {}) {
    const { write = this.batch(), ctx, params } = options;
    const addDocs = changes.get('create').map(change => {
      if (change.value.type === 'root') return createRootTreeEntry(change.value);
      return createTreeEntry(change.value);
    });
    const updateDocs = changes.get('update').map(change => ({ id: change.id, ...change.value }));
    const removeDocs = changes.get('delete').map(change => change.id);

    await Promise.all([
      this.add(addDocs, { write, ctx, params }),
      this.update(updateDocs, { write, ctx, params }),
      this.remove(removeDocs, { write, ctx, params })
    ]);

    if (!options.write) {
      return (write as firebase.firestore.WriteBatch).commit();
    }
  }

  setNodeExpanded(id: string, value: boolean) {
    this.store.ui.update(id, { expanded: value });
  }

  createRootTreeEntry(options?: WriteOptions) {
    const entry = createRootTreeEntry({ id: 'root' });
    return this.add(entry, options);
  }

  createAfter(type, name: string, nodeId: string, parentId: string): Promise<string> {
    console.log('createAfter', arguments);
    const parentNode = this.query.getEntity(parentId || 'root');

    const id = this.db.createId();
    const entry = createTreeEntry({ id, name, type });
    const changes = new ChangeManager<TreeEntry>();

    const index = parentNode.children.indexOf(nodeId);
    const children = [...parentNode.children];
    children.splice(index + 1, 0, id);

    changes.create(entry);
    changes.update(parentNode.id, { children });

    console.log(changes);

    return this.applyChanges(changes).then(() => id);
  }

  deleteNode(nodeId: string, parentId: string): Promise<void> {
    const parentNode = this.query.getEntity(parentId);
    const changes = new ChangeManager<TreeEntry>();

    const index = parentNode.children.indexOf(nodeId);
    const children = [...parentNode.children];
    children.splice(index, 1);

    changes.delete(nodeId);
    changes.update(parentNode.id, { children });

    return this.applyChanges(changes);
  }

  protected onCreate(entity: TreeEntry, options: WriteOptions) {
    // const { id } = this.routerQuery.getParams<string>();
    return this.slideService.createFormSlide(entity.id, options);
  }

  // protected onDelete(id: string, options: WriteOptions) {
  //   // todo: move project delete to server
  //   const entity = this.query.getEntity(id) || options.ctx;
  //   if (entity.type === 'project') {
  //     return this.projectService.remove(id, options);
  //   }
  // }

  async removeAll(options: WriteOptions = {}) {
    const { write = this.db.firestore.batch() } = options;
    const path = this.getPath(options);
    const snapshot = await this.db.collection(path).ref.get();
    const promises = snapshot.docs.map(doc => this.remove(doc.id, { ...options, write, ctx: doc.data() }));
    await Promise.all(promises);
    if (!options.write) {
      return (write as firebase.firestore.WriteBatch).commit();
    }
  }

}
