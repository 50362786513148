import { HostListener, Input, Component, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'fav-icon',
  template: `<mat-icon>{{ favorite ? 'star' : 'star_border' }}</mat-icon>`,
  styles: [`
    :host { cursor: pointer; display: inline-block; }
    mat-icon { color: #ffbf03; display: block; }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavIcon {
  private _isFavorite = false;

  @HostListener('click', ['$event']) onClick($event: MouseEvent) {
    $event.stopPropagation();
    this._isFavorite = !this._isFavorite;
    this.change.emit(this.favorite);
  }

  @Input() set favorite(value) { this._isFavorite = Boolean(value); }
  get favorite() { return this._isFavorite };

  @Output() change = new EventEmitter<boolean>();
}
