import { Injectable, Injector } from '@angular/core';
import { Overlay, ConnectionPositionPair, PositionStrategy, OverlayConfig } from '@angular/cdk/overlay';
import { PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { PopoverRef, PopoverContent } from './popover-ref';
import { PopoverComponent } from './popover.component';

export type PopoverParams<T> = {
  width?: string | number;
  height?: string | number;
  origin?: HTMLElement;
  content: PopoverContent;
  data?: T;
}

@Injectable({
  providedIn: 'root'
})
export class Popover {
  constructor(private overlay: Overlay, private injector: Injector) { }

  open<T>({ origin, content, data, width, height }: PopoverParams<T>): PopoverRef<T> {
    const overlayRef = this.overlay.create(this.getOverlayConfig({ origin, width, height }));
    const popoverRef = new PopoverRef<T>(overlayRef, content, data);

    const injector = this.createInjector(popoverRef, this.injector);
    overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));

    return popoverRef;
  }

  private getOverlayConfig({ origin, width, height }): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      width,
      height,
      // backdropClass: 'popover-backdrop',
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.getOverlayPosition(origin),
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });
  }

  private getOverlayPosition(origin: HTMLElement): PositionStrategy {
    let positionStrategy: PositionStrategy;

    if (origin) {
      positionStrategy = this.overlay.position()
        .flexibleConnectedTo(origin)
        .withPositions(this.getPositions())
        .withFlexibleDimensions(false)
        .withPush(false);
    } else {
      positionStrategy = this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically();
    }

    return positionStrategy;
  }

  createInjector(popoverRef: PopoverRef, injector: Injector) {
    const tokens = new WeakMap([[PopoverRef, popoverRef]]);
    return new PortalInjector(injector, tokens);
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'start',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center'
      }
    ];
    // return [
    //   {
    //     originX: 'center',
    //     originY: 'top',
    //     overlayX: 'center',
    //     overlayY: 'bottom'
    //   },
    //   {
    //     originX: 'center',
    //     originY: 'bottom',
    //     overlayX: 'center',
    //     overlayY: 'top',
    //   },
    // ]
  }

}
