import { HostListener, Input, Component, EventEmitter, Output, ChangeDetectionStrategy, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'tree-input',
  template: `<input #input [value]="value" (input)="updateValue($event)" (blur)="updateValue($event)" placeholder="Type here">`,
  styles: [`
    :host {
      display: inline-block;
      width: 50px;
    }
    input {
      overflow: hidden;
      outline: none;
      background: inherit;
      border: none;
      font-weight: 500;
      font-size: 0.9em;
      color: black;
      width: 100%;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectTreeInput implements OnInit, OnDestroy {
  private _value = '';
  private _active = false;
  private debouncer = new Subject<string>();
  private focus = new Subject();
  private subscription: Subscription;

  @ViewChild('input') inputElement: ElementRef<HTMLInputElement>;

  // @HostListener('input', ['$event']) onEnter($event: KeyboardEvent) {
  //   $event.stopPropagation();
  //   const el = $event.target as HTMLInputElement;
  //   this.change.emit(el.value);
  // }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngOnInit() {
    this.subscription = this.debouncer
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe(value => this.valueChange.emit(value));
  }

  ngAfterViewInit() {
    this.focus.subscribe(() => {
      this.inputElement.nativeElement.focus();
    });
  }

  updateValue($event: Event) {
    const el = $event.target as HTMLInputElement;
    this._value = el.value;
    this.debouncer.next(el.value);
  }

  @Input() set value(value) { this._value = value; }
  get value() { return this._value; };

  @Input() set active(value) {
    if (value && value !== this._active) {
      this.focus.next();
    }
    this._active = value;
  }

  @Output() valueChange = new EventEmitter<string>();
}
