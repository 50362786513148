import { Injectable } from '@angular/core';
import { QueryEntity, EntityUIQuery, combineQueries, HashMap } from '@datorama/akita';
import { TreeStore, TreeState, TreeUIState, TreeNodeUIState } from './tree.store';
import { TreeEntry } from './tree.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { traverseTree } from '@app/_common/helpers';

type CustomNode = {
  value: TreeEntry;
  children?: CustomNode[];
  parent?: CustomNode;
  metadata: {
    index?: number;
    expanded?: boolean;
  };
};

@Injectable({ providedIn: 'root' })
export class TreeQuery extends QueryEntity<TreeState> {

  ui: EntityUIQuery<TreeUIState>;

  constructor(
    protected store: TreeStore
  ) {
    super(store);
    this.createUIQuery();
  }

  selectTree() {
    return combineQueries([
      this.selectAll({ asObject: true }),
      this.ui.selectAll({ asObject: true }) as Observable<HashMap<TreeNodeUIState>>,
    ]).pipe(
      map(([tree, metadata]) => {
        const treeMap: Record<string, CustomNode> = {};
        const treeValues = Object.values(tree);

        treeValues.forEach(value => {
          treeMap[value.id] = treeMap[value.id] || { value, metadata: {} };
          treeMap[value.id].metadata.expanded = Boolean(metadata[value.id]?.expanded);

          treeMap[value.id].children = value.children?.map(childId => {
            treeMap[childId] = treeMap[childId] || { value: tree[childId], metadata: {} };
            treeMap[childId].parent = treeMap[value.id];
            return treeMap[childId];
          });
        });

        let index = 1;
        traverseTree(treeMap['root']?.children, node => {
          if (node.value.type === 'form') {
            node.metadata.index = index;
            index++;
          }
        });

        return treeValues.map(entry => {
          return {
            ...entry,
            _meta: {
              expanded: Boolean(metadata[entry.id]?.expanded),
              parent: treeMap[entry.id].parent?.value.id,
              index: treeMap[entry.id].metadata.index
            }
          }
        });

      }),
      tap(value => console.log('tapping projecTreeQuery selectTree:', value))
    );
  }

  getRoot() {
    return this.store.getValue().entities['root'];
  }

}

// class Tree {
//   private customMap: Record<string, CustomNode>;

//   constructor(private entries: HashMap<TreeEntry>, metadata: HashMap<any> = {}) {
//     Object.values(entries).forEach(value => {
//       this.customMap[value.id] = this.customMap[value.id] || { value, metadata: {} };
//       Object.assign(this.customMap[value.id].metadata, metadata[value.id]);

//       this.customMap[value.id].children = value.children?.map(childId => {
//         this.customMap[childId] = this.customMap[childId] || { value: entries[childId], metadata: {} };
//         this.customMap[childId].parent = this.customMap[value.id];
//         return this.customMap[childId];
//       });
//     });
//   }

//   create() {

//   }

//   createAfter() {

//   }

//   move() {

//   }

//   exdent() {

//   }

//   indent() {

//   }

//   delete() {

//   }

//   update() {

//   }

//   indent(nodeId: string): void {
//     let node = this.get(nodeId);
//     if (node.order === 0) return;
//     let parent = this.get(node.parent);
//     let parentList = parent ? parent.nodes : this.Tree.getActiveTree();
//     let previousNode = parentList[node.order - 1];
//     this.moveInTree(nodeId, previousNode.id, previousNode.nodes.length);
//   }

//   exdent(nodeId: string): void {
//     let node = this.get(nodeId);
//     let parent = this.get(node.parent);
//     if (!parent) return;
//     this.moveInTree(nodeId, parent.parent, parent.order + 1);
//   }
// }

