import { Injectable } from '@angular/core';
import { QueryEntity, EntityUIQuery, combineQueries, HashMap } from '@datorama/akita';
import { SlideStore, SlideState } from './slide.store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SlideQuery extends QueryEntity<SlideState> {

  constructor(
    protected store: SlideStore
  ) {
    super(store);
  }

}
