import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap, map, debounceTime, switchMap, distinctUntilChanged, filter } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { ContentService, ContentStore, ContentQuery, ContentEntry } from '../_state/content';
import { getDirtyValues, getBase64Hash } from '@app/_common/helpers';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { TreeQuery } from '../_state/tree';
import { SlideService, SlideQuery, SlideStore } from '../_state/slide';
import { of } from 'rxjs';
import { ViewerWindow } from '@app/_common/services/viewerWindow.service';
import { DropzoneEvent } from '@app/_common/components/dropzone.component';

@UntilDestroy()
@Component({
  selector: 'content-component',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  hashMap = new Map<string, string>();

  contentForm = new FormGroup({
    title: new FormControl(),
    subtitle: new FormControl(),
    heading: new FormControl(),
    text: new FormControl(),
    cite: new FormControl(),
    notes: new FormControl()
  });

  constructor(
    private treeQuery: TreeQuery,
    private slideService: SlideService,
    private slideQuery: SlideQuery,
    private slideStore: SlideStore,
    private contentStore: ContentStore,
    private contentQuery: ContentQuery,
    private contentService: ContentService,
    private viewer: ViewerWindow
  ) { }

  pageUrls = [
    "https://firebasestorage.googleapis.com/v0/b/project-126929279793088707.appspot.com/o/files%2F-KpyxMKiZ1j2DDJeX-gq%2Fpages%2F-Lp8Ba_Wb_QD3de-AY5e%2Fpage.pdf?alt=media&token=5056f148-9adc-49b4-aa19-3cff6bd09a57",
    "https://firebasestorage.googleapis.com/v0/b/project-126929279793088707.appspot.com/o/files%2F-KpyxMKiZ1j2DDJeX-gq%2Fpages%2F-Lp8MWvN4b5wclKlBY4g%2Fpage.pdf?alt=media&token=48ecc68e-bfff-4430-b822-5c96c9b9b0ce",
    "https://firebasestorage.googleapis.com/v0/b/project-126929279793088707.appspot.com/o/files%2F-KpyxMKiZ1j2DDJeX-gq%2Fpages%2F-Ls1zVHbhv8y-5CUFGhZ%2Fpage.pdf?alt=media&token=ecce9e37-913a-4b5a-b0c8-77a235dd7614",
    "https://firebasestorage.googleapis.com/v0/b/project-126929279793088707.appspot.com/o/files%2F-KpyxMKiZ1j2DDJeX-gq%2Fpages%2F-M2FEYbTwf4LNIyYIF9n%2Fpage.pdf?alt=media&token=de0d2159-e244-42f1-b2d9-0bdb59f14f12",
    "https://firebasestorage.googleapis.com/v0/b/project-126929279793088707.appspot.com/o/files%2F-LeqzUQ1jydDD_mX0cQa%2Fdocuments%2FOTc3NTczZTVhYjA2NWJkYjQ2NzI4NDJlNzc4ZTlhNTI%3D%2Ffile?alt=media&token=4a96a921-55fd-4d76-afcc-bb6a08d0dfcb"
  ];

  ngOnInit() {
    this.treeQuery.selectActiveId()
      .pipe(
        untilDestroyed(this),
        filter(formId => !!formId),
        distinctUntilChanged(),
        switchMap(formId => {
          // this.slideStore.reset();
          // this.contentStore.reset();
          const queryFn = ref => ref.where('formId', '==', formId);
          return this.slideService.syncCollection(queryFn, { reset: true });
        })
      )
      .subscribe();

    this.slideQuery.selectAll()
      .pipe(untilDestroyed(this))
      .subscribe(entries => {
        const entry = entries.find(e => e.selected === true);
        if (entry) {
          this.slideStore.setActive(entry.id);
        } else {
          const activeId = this.slideQuery.getActiveId();
          this.slideStore.removeActive(activeId);
        }
      });


    this.slideQuery.selectActiveId()
      .pipe(
        untilDestroyed(this),
        filter(slideId => !!slideId),
        distinctUntilChanged(),
        switchMap(slideId => {
          console.log('changing slideId', slideId);
          return this.contentService.syncCollection({ reset: true });
        })
      )
      .subscribe(res => {
        console.log('contentService', res);
      });

    this.contentQuery.selectContentForm().subscribe(form => {
      console.log('form', form);
      Object.keys(this.contentForm.controls).forEach(key => {
        this.contentForm.controls[key].patchValue(form[key] || null);
      });
    });

    this.contentForm.valueChanges.pipe(
      debounceTime(400),
      map(() => getDirtyValues(this.contentForm))
    ).subscribe(changes => {
      console.log('changes', changes);
      const contents = this.contentQuery.getAll();
      Object.keys(changes).forEach(key => {
        const entry = contents.find(entry => entry.type === key);
        if (entry) {
          this.contentService.update(entry.id, { metadata : { text: changes[key] } });
        } else {
          this.contentService.add({
            type: key as any,
            metadata : { text: changes[key] }
          });
        }
      });
    })
  }

  inputChange($event) {
    console.log('inputChange', $event);
  }

  openPage(url, name) {
    this.viewer.open(url, name);
  }

  async openFile($event: DropzoneEvent) {
    if ($event.files && $event.files[0]) {
      let hash = await getBase64Hash($event.files[0]);
      let url: string;

      if (this.hashMap.has(hash)) {
        url = this.hashMap.get(hash);
      } else {
        url = URL.createObjectURL($event.files[0]);
        this.hashMap.set(hash, url);
      }

      let name = $event.files[0].name;
      this.viewer.open(url, name);
    }
  }

}
