import MagicWand from 'magic-wand-tool';

type ImageInfo = {
  width: number;
  height: number;
  context: CanvasRenderingContext2D;
  data: ImageData;
};

type Mask = {
  data: Uint8Array;
  width: number;
  height: number;
  bounds: any;
}

export class CanvasFill {

  imageInfo: ImageInfo;

  currentThreshold = 15;
  blurRadius = 5;
  mask: Mask = null;
  oldMask: Mask = null;
  addMode = false;
  cacheInd = null;
  hatchLength = 4;
  hatchOffset = 0;

  simplifyTolerant = 0;
  simplifyCount = 30;

  constructor(canvas: HTMLCanvasElement, borderCanvas: HTMLCanvasElement) {
    const { width, height } = canvas;
    const ctx = borderCanvas.getContext('2d');

    this.imageInfo = {
        width,
        height,
        context: ctx,
        data: null
    };


    const tempCtx = document.createElement('canvas').getContext('2d');
    tempCtx.canvas.width = width;
    tempCtx.canvas.height = height;
    tempCtx.drawImage(canvas, 0, 0);
    this.imageInfo.data = tempCtx.getImageData(0, 0, width, height);
  }

  drawMask(x: number, y: number) {
    if (!this.imageInfo) return;

    const image = {
        data: this.imageInfo.data.data,
        width: this.imageInfo.width,
        height: this.imageInfo.height,
        bytes: 4
    };

    if (this.addMode) {
    	this.oldMask = this.mask;
    } else {
      this.oldMask = null;
    }

    let old = this.oldMask ? this.oldMask.data : null;

    this.mask = MagicWand.floodFill(image, Math.round(x), Math.round(y), this.currentThreshold, old, true);
    if (this.mask) this.mask = MagicWand.gaussBlurOnlyBorder(this.mask, this.blurRadius, old);

    if (this.addMode && this.oldMask) {
    	this.mask = this.mask ? this.concatMasks(this.mask, this.oldMask) : this.oldMask;
    }

    this.drawBorder();
  }

  private drawBorder(noBorder = false) {
    if (!this.mask) return;

    var x,y,i,j,k,
        w = this.imageInfo.width,
        h = this.imageInfo.height,
        ctx = this.imageInfo.context,
        imgData = ctx.createImageData(w, h),
        res = imgData.data;

    if (!noBorder) this.cacheInd = MagicWand.getBorderIndices(this.mask);

    ctx.clearRect(0, 0, w, h);

    var len = this.cacheInd.length;
    for (j = 0; j < len; j++) {
        i = this.cacheInd[j];
        x = i % w; // calc x by index
        y = (i - x) / w; // calc y by index
        k = (y * w + x) * 4;
        if ((x + y + this.hatchOffset) % (this.hatchLength * 2) < this.hatchLength) { // detect hatch color
            res[k + 3] = 255; // black, change only alpha
        } else {
            res[k] = 255; // white
            res[k + 1] = 255;
            res[k + 2] = 255;
            res[k + 3] = 255;
        }
    }

    ctx.putImageData(imgData, 0, 0);
  }

  private concatMasks(mask, old) {
    let data1 = old.data,
      data2 = mask.data,
      w1 = old.width,
      w2 = mask.width,
      b1 = old.bounds,
      b2 = mask.bounds,
      b = { // bounds for new mask
        minX: Math.min(b1.minX, b2.minX),
        minY: Math.min(b1.minY, b2.minY),
        maxX: Math.max(b1.maxX, b2.maxX),
        maxY: Math.max(b1.maxY, b2.maxY)
      },
      w = old.width, // size for new mask
      h = old.height,
      i, j, k, k1, k2, len;

    let result = new Uint8Array(w * h);

    // copy all old mask
    len = b1.maxX - b1.minX + 1;
    i = b1.minY * w + b1.minX;
    k1 = b1.minY * w1 + b1.minX;
    k2 = b1.maxY * w1 + b1.minX + 1;
    // walk through rows (Y)
    for (k = k1; k < k2; k += w1) {
      result.set(data1.subarray(k, k + len), i); // copy row
      i += w;
    }

    // copy new mask (only "black" pixels)
    len = b2.maxX - b2.minX + 1;
    i = b2.minY * w + b2.minX;
    k1 = b2.minY * w2 + b2.minX;
    k2 = b2.maxY * w2 + b2.minX + 1;
    // walk through rows (Y)
    for (k = k1; k < k2; k += w2) {
      // walk through cols (X)
      for (j = 0; j < len; j++) {
        if (data2[k + j] === 1) result[i + j] = 1;
      }
      i += w;
    }

    return {
      data: result,
      width: w,
      height: h,
      bounds: b
    };
  }

  hatchTick() {
    this.hatchOffset = (this.hatchOffset + 1) % (this.hatchLength * 2);
    this.drawBorder(true);
  }

  trace() {
    var cs = MagicWand.traceContours(this.mask);
    cs = MagicWand.simplifyContours(cs, this.simplifyTolerant, this.simplifyCount);

    this.mask = null;

    // draw contours
    var ctx = this.imageInfo.context;
    ctx.clearRect(0, 0, this.imageInfo.width, this.imageInfo.height);

    return cs;
  }
}
