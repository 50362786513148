import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UserOrganizationsStore, UserOrganizationsState } from './organizations.store';

@Injectable({ providedIn: 'root' })
export class UserOrganizationsQuery extends QueryEntity<UserOrganizationsState> {

  constructor(protected store: UserOrganizationsStore) {
    super(store);
  }

}
