<app-tree
  [items]="$any(projectTree$)"
  (itemChange)="treeChanges($event)"
  (nodeSelect)="onSelect($event)"
  (nodeToggle)="setExpanded($event)"
  [selectedNodeId]="activeNodeId"
>
  <ng-template let-node pTemplate="folder">
    <div
      *ngIf="node.data.type !== 'folder'"
      [dragHandle]="node"

      [ngClass]="{
        'number-badge type-icon': true,
        'group-badge': node.data.type === 'group'
      }"
    >{{ node.data._meta.index }}</div>

    <mat-icon
      *ngIf="node.data.type === 'folder'"
      [dragHandle]="node"
      class="type-icon folder-icon"
    >folder</mat-icon>

    <tree-input [active]="activeNodeId === node.data.id" [value]="node.data.name" (valueChange)="updateNodeValue(node, $event)" (keyup.enter)="createNode(node)"></tree-input>

    <mat-icon (click)="openTools(node, $event)" class="node-actions" matTooltip="Show actions" matTooltipShowDelay="1000">more_horiz</mat-icon>
  </ng-template>
</app-tree>
