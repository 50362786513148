import { Export, PdfSaveOptions, PageAnnotation, DocumentObj, CroppedAnnotation } from './types';
import { PageObj, PageAnnotationsMap, AssetHistoryType } from '@app/_state/pages';
import { AuthQuery } from '@app/auth';
import { ProjectQuery } from '@app/_state/project';
import { Injectable } from '@angular/core';
import { getBase64Hash } from '@app/_common/helpers';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { isWithin } from '@app/webviewer/viewerHelpers';
import { StorageService } from '@app/_common/services/storage.service';


@Injectable({
  providedIn: 'root'
})
export class PageUploadService {

  constructor(
    private authQuery: AuthQuery,
    private projectQuery: ProjectQuery,
    private db: AngularFirestore,
    private storage: StorageService
  ) {

  }

  async uploadBlob(data: Export, options: PdfSaveOptions): Promise<PageObj> {
    const bulk = this.storage.bulk();

    const timeStamp = this.authQuery.getStamp();
    const projectId = this.projectQuery.getActiveId();

    // const oldPage = await this.Pages.get(options.originalPage) || <PageObj>{};
    const oldPage = <PageObj>{};
    const id = (options.action === 'save') ? oldPage.id : this.db.createId();

    // const currentPdf = this.WVPanel.getCurrent();
    // const { name: docName, md5: docMd5 } = currentPdf.document && currentPdf.document.parent;
    const docName = 'test doc';
    const docMd5 = null;

    let slides = {};
    if (options.action === 'save') slides = oldPage.slides || {};
    // if (options.action === 'saveAs') slides = { [this.ActiveSlide.get('id')]: true };

    const page = {
      id,
      slides,
      name: '',
      confidential: options.confidential || false,
      hotness: options.hotness,
      rotation: data.rotation,
      scaleRatio: data.scaleRatio,
      created: oldPage.created || timeStamp,
      edited: timeStamp,
      history: <AssetHistoryType>{},
      annotations: <PageAnnotationsMap>{},
      document: {
        name: docName || null,
        md5: docMd5 || null
      }
    } as PageObj;

    // add History items for created/edited and confidential
    // keep old history when NOT 'Add As New'
    // if confidential changes, add 2nd history item for that
    let confidential = {};
    let createdEditedValue = 'created';

    if (['save', 'saveAs'].includes(options.action)) { // NOT 'Add As New'
      page.history = oldPage.history || {};
      createdEditedValue = 'edited';

      if (page.confidential !== oldPage.confidential) {
        let confidentialValue = page.confidential ? 'confidential' : 'non-confidential';
        confidential = { [this.db.createId()]: this.authQuery.getStamp(confidentialValue) };
      }
    }

    let createdEdited = { [this.db.createId()]: this.authQuery.getStamp(createdEditedValue) };
    Object.assign(page.history, createdEdited, confidential);

    // if another page was saved, after open original document action
    if (data.isOverwrite) {
      page.page = data.page;
    } else {
      page.page = oldPage.page || data.page;
    }

    // upload files to storage
    const metadata = { customMetadata: { 'confidential': page.confidential.toString() } };
    const pngContentType = { contentType: 'image/png' };
    const storagePath = `files/${projectId}/pages/${id}`;

    bulk.add(null, 'image', `${storagePath}/image`, data.image);
    bulk.add(null, 'hires', `${storagePath}/hires`, data.hires, pngContentType);
    bulk.add(null, 'thumb', `${storagePath}/thumb`, data.thumb);
    bulk.add(null, 'xfdf', `${storagePath}/annotations.xfdf`, data.xfdf);
    bulk.add(null, 'url', `${storagePath}/page.pdf`, data.pdf, metadata);

    data.annotationArray.forEach(annotation => {
      bulk.add('annotations', annotation.id, `${storagePath}/annotations/${annotation.id}/image`, annotation.blob, pngContentType);
    });

    data.hiresAnnotations.map(annotation => {
      bulk.add('annotations.hires', annotation.id, `${storagePath}/annotations/${annotation.id}/hires`, annotation.blob, pngContentType);
    });

    Object.assign(page, await bulk.upload());


    // page.image = await this.storage.upload(`${storagePath}/image`, data.image);
    // page.hires = await this.storage.upload(`${storagePath}/hires`, data.hires, pngContentType);
    // page.thumb = await this.storage.upload(`${storagePath}/thumb`, data.thumb);
    // page.xfdf = await this.storage.upload(`${storagePath}/annotations.xfdf`, data.xfdf);
    // page.url = await this.storage.upload(`${storagePath}/page.pdf`, data.pdf, metadata);


    // // handle annotations images
    // const uploadAnnotations = data.annotationArray.map(annotation => {
    //   return this.storage.upload(`${storagePath}/annotations/${annotation.id}/image`, annotation.blob, pngContentType);
    // });
    // const annotationUrls = await Promise.all(uploadAnnotations);
    // annotationUrls.forEach((url, order) => {
    //   const annotation = data.annotationArray[order];
    //   const magnifyFrom = this.getMagnifyParent(annotation, data.annotationArray);
    //   page.annotations[annotation.id] = { url, order, magnifyFrom } as PageAnnotation;
    // });

    // // handle hi-res annotation images
    // const uploadHiresAnnotations = data.hiresAnnotations.map(annotation => {
    //   return this.storage.upload(`${storagePath}/annotations/${annotation.id}/hires`, annotation.blob, pngContentType);
    // });
    // const hiresAnnotationUrls = await Promise.all(uploadHiresAnnotations);
    // hiresAnnotationUrls.forEach((url, index) => {
    //   const annotationId = data.hiresAnnotations[index].id;
    //   page.annotations[annotationId].hires = url;
    // });

    // delete removed callouts from storage if save/overwrite everywhere
    if (options.action === 'save') {
      const currentAnnotIds = data.annotationArray.map(item => item.id);
      const removedAnnotIds = Object.keys(oldPage.annotations || {}).filter(oldAnnotId => !currentAnnotIds.includes(oldAnnotId));
      const deleteCallouts = removedAnnotIds.map(annotId => this.deleteAnnotationFromStorage(id, annotId));
      await Promise.all(deleteCallouts);
    }

    // handle parent document
    if (options.originalDocument) {
      let hash = await getBase64Hash(options.originalDocument);
      page.document = { md5: hash, name: data.fileName };
    }

    // if (options.uploadOriginalDocument && options.originalDocument) {
    //   let doc = await this.Documents.get(page.document.md5);
    //   if (!doc) doc = <DocumentObj>{};

    //   // if document doesn't exist upload and create a document
    //   if (!doc.created) {
    //     const docStoragePath = `files/${projectId}/documents/${page.document.md5}`;
    //     const { image, thumb } = await this.PdfService.getCoverPageImages();

    //     const [ fileUrl, imageUrl, thumbUrl ] = await Promise.all([
    //       this.storage.upload(`${docStoragePath}/file`, options.originalDocument),
    //       this.storage.upload(`${docStoragePath}/image`, image),
    //       this.storage.upload(`${docStoragePath}/thumb`, thumb)
    //     ]);

    //     Object.assign(doc, {
    //       md5: page.document.md5,
    //       name: data.fileName,
    //       url: fileUrl,
    //       image: imageUrl,
    //       thumb: thumbUrl,
    //       created: timeStamp
    //     });

    //     await this.Documents.ref(projectId, doc.md5).set(doc);
    //   }
    // }


    // await this.Pages.ref(projectId, id).set(page);
    return page;
  }

  /*
   * If rect of annotation is within the bounds of another annotation's rect, return the id of the parent annotation
   */
  private getMagnifyParent(annotation: CroppedAnnotation, annotationArray: CroppedAnnotation[]): string {
    const selectedRect = annotation.rect;
    let parentId = null;

    annotationArray.forEach(({ rect, id }) => {
      if (isWithin(selectedRect, rect)) parentId = id;
    });

    return parentId;
  }

  private async deleteAnnotationFromStorage(pageId: string, annotId: string) {
    const projectId = this.projectQuery.getActiveId();

    const storagePath = `files/${projectId}/pages/${pageId}/annotations/${annotId}`;
    return Promise.all([
      this.storage.delete(`${storagePath}/image`),
      this.storage.delete(`${storagePath}/hires`),
      this.storage.delete(`${storagePath}/transparentImage`),
    ]);
  }
}
