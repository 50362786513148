import { Injectable, ElementRef, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export type DropzoneCallback = (err: Error, files: FileList) => void;

export type DropzoneObject = {
  dropzone: ElementRef,
  fileType: string[];
  callback: DropzoneCallback;
};

@Injectable({
  providedIn: 'root'
})
export class DropzoneService {

  private areaCount = 0;
  private dropzoneMap: Map<HTMLElement, DropzoneObject> = new Map();
  private unlistenArray: Array<() => void>;
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  add(dropzoneObject: DropzoneObject) {
    if (this.dropzoneMap.size === 0) this.startListeners();
    this.dropzoneMap.set(dropzoneObject.dropzone.nativeElement, dropzoneObject);
  }

  remove(dropzone: ElementRef) {
    this.dropzoneMap.delete(dropzone.nativeElement);
    if (this.dropzoneMap.size === 0) this.stopListeners();
  }

  private startListeners() {
    this.areaCount = 0;

    this.unlistenArray = [
      this.renderer.listen(this.document.body, 'dragleave', this.dragleaveHandler.bind(this)),
      this.renderer.listen(this.document.body, 'dragenter', this.dragenterHandler.bind(this)),
      this.renderer.listen(this.document.body, 'drop', this.dropHandler.bind(this)),
      this.renderer.listen(this.document.body, 'dragstart', this.dragstartHandler.bind(this)),
      this.renderer.listen(this.document.body, 'dragover', this.isValidTarget.bind(this)),
      this.renderer.listen(this.document.body, 'drop', this.isValidTarget.bind(this))
    ]
  }

  private stopListeners() {
    this.unlistenArray.forEach(fn => fn());
  }

  private dragleaveHandler(event) {
    this.areaCount--;
    if (this.areaCount === 0) {
      this.renderer.removeClass(this.document.body, 'is-dragover');
    }

    if (this.dropzoneMap.has(event.target)) {
      this.renderer.removeClass(event.target, 'is-dragover');
    }
  }

  private dragenterHandler(event) {
    if (this.areaCount === 0) {
      this.renderer.addClass(this.document.body, 'is-dragover');
    }
    this.areaCount++;

    if (this.dropzoneMap.has(event.target)) {
      this.renderer.addClass(event.target, 'is-dragover');
    }
  }

  private dropHandler(event: DragEvent) {
    this.areaCount = 0;
    this.renderer.removeClass(this.document.body, 'is-dragover');

    if (this.dropzoneMap.has(event.target as HTMLElement)) {
      event.preventDefault();

      console.log('dropHandler', event);

      let zoneObject = this.dropzoneMap.get(event.target as HTMLElement);
      this.renderer.removeClass(event.target, 'is-dragover');

      let droppedFiles = event.dataTransfer.files;

      let error = null;
      Array.from(droppedFiles || []).forEach(file => {
        const types = zoneObject.fileType;
        if (types && types.length && !types.includes(file.type)) {
          error = new Error('Wrong file type.');
        }
      });

      zoneObject.callback(error, droppedFiles);
    }
  }

  private dragstartHandler(event) {
    return event.target.nodeName !== 'IMG';
  }

  private isValidTarget(event: DragEvent) {
    return (event.target as HTMLElement).nodeName !== 'INPUT' && (event.target as HTMLElement).getAttribute('type') === 'file';
  }

}
