import { Component, OnInit, ViewChild, Renderer2, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopoverRef } from '@app/popover/popover-ref';
import { SpeechRecognizerService, SpeechNotification, SpeechError } from '@app/_common/speech-recognizer.service';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { timeoutWith, tap, timeout, catchError, skip } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'speech-dialog',
  templateUrl: './speech-dialog.component.html',
  styleUrls: ['./speech-dialog.component.scss'],
  providers: [
    SpeechRecognizerService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpeechDialogComponent {

  @ViewChild('interim') inputElement: ElementRef<HTMLDivElement>;

  constructor(
    private popoverRef: PopoverRef,
    private speechRecognizer: SpeechRecognizerService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    // this.node = this.popoverRef.data.node;
  }

  interimTranscript$ = new BehaviorSubject<string>(null);
  notification: string;
  finalTranscript = '';
  interimTranscript = '';
  recognizing = false;

  toggleRecognition($event) {
    this.recognizing = !this.recognizing;

    if (!this.recognizing) {
      this.speechRecognizer.stop();
      return;
    }

    this.speechRecognizer.start(event.timeStamp);
  }

  ngOnInit() {
    this.speechRecognizer.initialize('en-US');
    this.initRecognition();
    this.notification = null;

    this.toggleRecognition({ timeStamp: new Date() });
  }

  private initRecognition() {
    this.speechRecognizer.onStart()
      .subscribe(data => {
        this.recognizing = true;
        this.notification = 'I\'m listening...';
        this.cdr.detectChanges();
      });

    this.speechRecognizer.onEnd()
      .subscribe(data => {
        this.recognizing = false;
        this.notification = null;
        this.cdr.detectChanges();

        this.popoverRef.close(this.finalTranscript);
      });

    this.speechRecognizer.onResult()
      .pipe(
        timeout(3000),
        catchError((error, value) => of({ error: error.message }))
      )
      .subscribe((data: SpeechNotification) => {
        console.log(data);
        if (data.error) {
          return this.speechRecognizer.stop();
        }
        const message = data.content.trim();


        if (data.info === 'interim_transcript' && message.length > 0) {
          // this.interimTranscript = `${this.interimTranscript}\n${message}`;
          this.interimTranscript = message;
        }

        if (data.info === 'final_transcript' && message.length > 0) {
          this.interimTranscript = null;
          this.finalTranscript = `${this.finalTranscript} ${message}`;
          // this.actionContext.processMessage(message, this.currentLanguage);
          // this.actionContext.runAction(message, this.currentLanguage);
        }

        this.cdr.detectChanges();
      });

    this.speechRecognizer.onError()
      .subscribe(data => {
        switch (data.error) {
          case SpeechError.BLOCKED:
          case SpeechError.NOT_ALLOWED:
            this.notification = `Your browser is not authorized to access your microphone. Verify that your browser has access to your microphone and try again.`;
            break;
          case SpeechError.NO_SPEECH:
            this.notification = `No speech has been detected. Please try again.`;
            break;
          case SpeechError.NO_MICROPHONE:
            this.notification = `Microphone is not available. Plese verify the connection of your microphone and try again.`;
            break;
          default:
            this.notification = null;
            break;
        }
        this.recognizing = false;
        this.cdr.detectChanges();
      });
  }

}

